// CallbackScreen.js

import React, { useEffect,useState } from 'react';
import configFile from '../auth.config';
import { View, StyleSheet, Text, ActivityIndicator, Image, Platform,Dimensions, Pressable,Linking } from "react-native"
import { authStore } from "../utils/AuthStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import Colors from "../utils/Colors"
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const CallbackScreen = ({ navigation,route }) => {
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState(false)
  useEffect(async () => {
    const { redirectUri } = configFile.oktaAuth;
   
    // Parse the id_token from the route params or any other suitable method in your Expo environment
    const code = route.params.code;

    if (code) {
      configFile.oktaAuth.token.parseFromUrl()
      .then(async (tokenResponse) => {
        let createAccount = await AsyncStorage.getItem("createAccount")
        // Access token and other token details
        const accessToken = tokenResponse.tokens.accessToken;
         // You can also get the ID token and refresh token if needed
        const token = {access_token:accessToken.accessToken}
        console.log('Access Token:', accessToken);
        if(createAccount === "true"){
          console.log('signinSuccess:' + authStore.isCreateAccount)
          await authStore.signInSuccessCreateAccount('', '', token,{ email:'', password:'', allowEmailUpdates:authStore.allowEmailUpdates, allowAppNotifications:authStore.allowAppNotifications })
          .then(async (res) => {
            if (!res.ok && !res.data?.errorMessage?.includes("is already taken")) {
              console.log('error')
              setLoading(false)
              setErrorMsg(true)
      
            } else {
              await AsyncStorage.removeItem("createAccount")
              navigation.navigate('TabStack', {
                screen: 'AccountStack',
                params: { screen: 'Account' },
              });
            }
          })
        }else{
          
           authStore.signInSuccess('', '', token)
           setLoading(false)
           navigation.navigate('TabStack', {
            screen: 'AccountStack',
            params: { screen: 'Account' },
          });
        }
      })
      .catch((error) => {
        console.error('Error parsing tokens:', error);
      });
    } else {
      // Handle authentication failure
      console.error('Authentication failed');
    }
  }, [route]);

  return (
    <View style={styles.launchContainer}>
      {loading && <ActivityIndicator style={styles.spinner} size="small" color={Colors.yellow} />}
      {errorMsg && <><Text style={styles.title}>There was an error authenticating your account. For assistance, please email <Pressable 
					onPress={() => {

						Linking.openURL(
							"mailto:TFD.Support@support.parker.com?subject=FittingFinder Login Error"
						)

					}}
				><u>TFD.Support@support.parker.com</u></Pressable>. </Text></>}
    </View>
  );
};

export default CallbackScreen;
const styles = StyleSheet.create({
	launchContainer: {
		flex: 1,
		backgroundColor: Colors.backgroundGrey,
		width: "100%",
		height: "100%",
		padding: 30,
    width: maxWidth,
		alignSelf: "center",
	},
	spinner: {
		marginTop: 40,
		marginBottom: 60,
	},
	logo: {
		alignSelf: "center",
		marginTop: 10,
	},
	image: {
		alignSelf: "center",
		marginTop: 50,
		marginLeft: 25,
		marginBottom: 20,
	},
	title: {
		fontWeight: "bold",
		fontSize: 18,
		textAlign: "center",
		lineHeight: 22,
	},
	subtitle: {
		fontWeight: "500",
		fontSize: 18,
		textAlign: "center",
		lineHeight: 28,
		marginTop: 20,
		paddingHorizontal: 20,
	},
})
