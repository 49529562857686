import { Linking, Platform } from 'react-native';
import { OktaAuth } from '@okta/okta-auth-js';

  
  //Local/QA testing
  // export default {
  //   oidc: {
  //     clientId: '0oa1rvtgadlBcZOB30h8',  //'0oagg54yknhmZgRnS5d7',//
  //     redirectUri: 'com.parker.fittingfinder:/callback',
  //     endSessionRedirectUri: 'com.parker.fittingfinder:/logout',
  //     discoveryUri: 'https://loginqa.parker.com/oauth2/default',//'https://dev-58483008.okta.com',
  //     scopes: ['openid', 'profile', 'offline_access'],
  //     requireHardwareBackedKeyStore: false,
  //   },
  //   oktaAuth: new OktaAuth({
  //     issuer: 'https://loginqa.parker.com/oauth2/default',
  //     clientId: '0oa1tmpzxbewqoOjS0h8',
  //     redirectUri: '/login/callback',
  //   })
  // };

////QA
// export default {
//   oidc: {
//     clientId: '0oa1rvtgadlBcZOB30h8',  //'0oagg54yknhmZgRnS5d7',//
//     redirectUri: 'com.parker.fittingfinder:/callback',
//     endSessionRedirectUri: 'com.parker.fittingfinder:/logout',
//     discoveryUri: 'https://loginqa.parker.com/oauth2/default',//'https://dev-58483008.okta.com',
//     scopes: ['openid', 'profile', 'offline_access'],
//     requireHardwareBackedKeyStore: false,
//   },
//   oktaAuth: new OktaAuth({
//     issuer: 'https://loginqa.parker.com/oauth2/default',
//     clientId: '0oa1tmpzxbewqoOjS0h8',
//     redirectUri: '/login/callback',
//   })
// };


////Production
  export default {
    oidc: {
      clientId: '0oaw0j1ahtqWcjGxq2p7',  //'0oagg54yknhmZgRnS5d7',//
      redirectUri: 'com.parker.fittingfinder:/callback',
      endSessionRedirectUri: 'com.parker.fittingfinder:/logout',
      discoveryUri: 'https://login.parker.com/oauth2/default',//'https://dev-58483008.okta.com',
      scopes: ['openid', 'profile', 'offline_access'],
      requireHardwareBackedKeyStore: false,
    },
    oktaAuth: new OktaAuth({
      issuer: 'https://login.parker.com/oauth2/default',
      clientId: '0oau8snzbdU6y93wD2p7',
      redirectUri: '/login/callback',
    })
  };

  
  