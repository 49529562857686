const Colors = {
	transparent: "rgba(0,0,0,0)",
	semiTransparent: "#000000B3",
	overlayBackground: "#0000008C",
	white: "#ffffff",
	black: "#1A1818",
	darkGrey: "#1C2B39",
	mediumGrey: "#838181",
	grey: "#CCCCCC",
	backgroundGrey: "#F4F5F6",
	lightGrey: "#F6F6F6",
	titleGrey: "#656565",
	yellow: "#FDB913",
	primaryBlue: "#55C6E8",
	primaryGreen: "#87CB9F",
	red: "#FF4136",
}

export default Colors
