import React from "react"
import { StyleSheet, Text, Dimensions, View, Image, Platform,ScrollView } from "react-native"
import { useWindowDimensions } from "react-native"
import Constants from "expo-constants"
import Colors from "../utils/Colors"
import { navStore } from "../utils/NavStore"
//import { Pressable, StyleSheet, Text, , Image, Platform, Dimensions, Linking } from "react-native"
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
// const maxButtonWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 400) : Dimensions.get("window").width
const About = ({ navigation }) => {
	navStore.navigation = navigation
	const { width } = useWindowDimensions()
	return (
		<View style={styles.mainContainer}>
			 <ScrollView>
			<View style={{ alignItems: 'flex-start' }}>
				<Text style={{ fontWeight: '800', fontSize: 16 }}>Parker FittingFinder™ App</Text>
				<Text style={{ marginBottom: 30 }}>V{Constants.manifest.version} ({(Platform.OS == "ios" ? Constants.manifest.ios.buildNumber : Platform.OS == "android" ? Constants.manifest.android.versionCode : Constants.manifest.buildNumber)})</Text>
			</View>
			<View style={{ alignItems: 'flex-end', position: 'absolute', right: 30, top: 10 }}>
				<Image style={styles.logo} source={require("../assets/icon.png")} />
			</View>
			<Text >Parker’s <Text style={styles.boldText}>FittingFinder™</Text> enables users to identify and configure a
				tube fitting or adapter for use in hydraulic and general industrial fluid systems.  These
				Products are manufactured by Parker’s Tube Fittings Division, the world's leader in industrial tube fittings and adapters.{"\n"}{"\n"}
				The Build Fitting tool uses a sequence of visually aided steps to configure and/or identify a fitting.  By selecting key attributes of fitting 
				such as shape, end type(s), thread size, sealing method, and material – a part number with detailed specification attributes and drawing is provided{"\n"}{"\n"}
				Once configured, email or download links to part specification and common 2D/3D CAD Model formats.{"\n"}{"\n"}
				Use the Search feature to look up a known Parker or competitor part number or series. {"\n"}{"\n"}
				Use the Where to Buy tool to locate and contact authorized Parker distributors for product availability 
				and pricing. Use Map It to instantly navigate to distributor location.  Save Favorite distributors locations 
				with user added contact details.{"\n"}{"\n"}
				Finally, Creating an Account enables users to take full advantage of the <Text style={styles.boldText}>FittingFinder™</Text> app, including: Face ID enabled mobile log-in, 
				in-app notifications, and saving parts for future requirements.  Add user generated notes to saved parts future use. 



			</Text>
			</ScrollView>
		</View>
	)
}

export default About

const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		flexGrow: 1,
		// paddingHorizontal: 30,
		padding: 30,
		// paddingBottom: 20,
		backgroundColor: Colors.backgroundGrey,
		width: maxWidth,
		alignSelf: "center"
	},
	boldText: {
		fontWeight: 'bold',
	},
	logo: {
		// alignSelf: "center",
		textAlign: "right",
		width: 60,
		height: 60,

	},
})
