import React, { useEffect, useState, useRef } from "react"
import { StyleSheet, Text, Pressable, View, Linking, Dimensions, Image, TextInput,Platform } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import Icon from "react-native-vector-icons/Feather"
import SimpleIcon from "react-native-vector-icons/SimpleLineIcons"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {analyticsStore} from "../utils/AnalyticsStore"
import * as Animatable from "react-native-animatable"
import { authStore } from "../utils/AuthStore"
import { API } from "../utils/API"
import Colors from "../utils/Colors"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { TouchableOpacity } from "react-native-gesture-handler"
import { Clipboard } from "react-native"
import PhoneNumber from 'libphonenumber-js';
import { Button, Popup } from "../components/Index"
import { navStore } from "../utils/NavStore"
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const DistributorDetails = ({ navigation, route }) => {
	navStore.navigation = navigation
	const [isFavorite, setIsFavorite] = useState(route.params?.item.id ? true : false)
	const [distributor, setDistributor] = useState(route.params?.item)
	const [notes, setNotes] = useState("")
	const refContainer = useRef(null)
	const [changesSaved, setChangesSaved] = useState(false)
	//alert(number.formatNational())
	const location_name = distributor.name ? distributor.name : distributor.Name
	console.log(JSON.stringify(distributor))
	// React.useEffect(
	// 	() =>
	// 	  navigation.addListener('beforeRemove', (e) => {
	// 		if(isFavorite){
	// 			e.preventDefault();
	// 			let data = {
	// 				...distributor,
	// 				notes,
	// 			}
	// 			if (authStore.authenticated) {
	// 				API.updateFavoriteDistributor(authStore.accountInfo.id, data).then((res) => {
	// 					//if (res.ok) setDistributor(res.data.model)
	// 					AsyncStorage.getItem("favorites").then((favoritesData) => {
	// 						if (favoritesData) {
	// 							const distributorNumber = distributor.distributorNumber
	// 								? distributor.distributorNumber
	// 								: distributor.DistributorNo[0]
	// 							const favorites = JSON.parse(favoritesData)
	// 							const favObj = favorites.find((d) => d.distributorNumber === distributorNumber)
	// 							if (favObj) {
	// 								favObj.notes = notes
	// 								favObj.synced = true
	// 							}
	// 							AsyncStorage.setItem("favorites", JSON.stringify(favorites))
	// 							navigation.dispatch(e.data.action)
	// 						} else {
	// 							AsyncStorage.setItem("favorites", JSON.stringify([data]))
	// 							navigation.dispatch(e.data.action)
	// 						}
	// 					})
	// 				})
	// 			} else {
	// 				AsyncStorage.getItem("favorites").then((favoritesData) => {
	// 					if (favoritesData) {
	// 						const distributorNumber = distributor.distributorNumber
	// 							? distributor.distributorNumber
	// 							: distributor.DistributorNo[0]
	// 						const favorites = JSON.parse(favoritesData)
	// 						const favObj = favorites.find((d) => d.distributorNumber === distributorNumber)
	// 						if (favObj) {
	// 							favObj.notes = notes
	// 							favObj.synced = true
	// 						}
	// 						AsyncStorage.setItem("favorites", JSON.stringify(favorites))
	// 						navigation.dispatch(e.data.action)
	// 					} else {
	// 						AsyncStorage.setItem("favorites", JSON.stringify([data]))
	// 						navigation.dispatch(e.data.action)
	// 					}
	// 				})
	// 			}
	// 		}
	// 	  }),
	// 	[navigation]
	//   );
	const toggleSave = () => {
		if (isFavorite) refContainer.current.fadeOut()

		if (!isFavorite) {
			console.log("Saving")
			// save
			let data = {
				distributorNumber: distributor.distributorNumber ? distributor.distributorNumber : distributor.DistributorNo[0],
				name: distributor.name ? distributor.name : distributor.Name,
				address: distributor.address ? distributor.address : distributor.Address1,
				city: distributor.city ? distributor.city : distributor.City,
				state: distributor.state ? distributor.state : distributor.State,
				zip: distributor.zip ? distributor.zip : distributor.Zip,
				phone: distributor.phone ? distributor.phone : distributor.Phone,
				fax: distributor.fax ? distributor.fax : distributor.Fax,
				email: distributor.email ? distributor.email : distributor.Email,
				distance:distributor.distance?distributor.distance:distributor.Distance,
				distanceMarker:distributor.distanceUOM?distributor.distanceUOM:distributor.DistanceUOM,
				notes,
			}
			if (authStore.authenticated)
				API.createFavoriteDistributor(authStore.accountInfo.id, data).then((res) => {
					//if (res.ok) setDistributor(res.data.model)
				})
			else {
				data.synced = false
			}
			AsyncStorage.getItem("favorites").then((favoritesData) => {
				if (favoritesData) {
					const favorites = JSON.parse(favoritesData)

					favorites.push(data)
					AsyncStorage.setItem("favorites", JSON.stringify(favorites))
				} else {
					AsyncStorage.setItem("favorites", JSON.stringify([data]))
				}
			})


			analyticsStore.logEvent("location_favorite",{location_name:location_name})
		} else {
			if (authStore.authenticated) API.deleteFavoriteDistributor(authStore.accountInfo.id, { id: distributor.id })

			AsyncStorage.getItem("favorites").then((favoritesData) => {
				if (favoritesData) {
					let distNo =  (!distributor.DistributorNo?distributor.distributorNumber:distributor.DistributorNo[0]);
					let deleted  = JSON.parse(favoritesData).filter(
						(d) => d.distributorNumber === distNo
					)
					let favorites = JSON.parse(favoritesData).filter(
						(d) => d.distributorNumber !== distNo
					)
					AsyncStorage.setItem("favorites", JSON.stringify(favorites))
					if(!authStore.authenticated){
						AsyncStorage.getItem("deletedFavorites").then((deletedFavorites) => {
							let data =  (deletedFavorites===null || deletedFavorites===''?[]:JSON.parse(deletedFavorites))
							data.push(deleted);
							AsyncStorage.setItem("deletedFavorites", JSON.stringify(data))
						})
					}
				}
			})

		}

		setIsFavorite(!isFavorite)
	}

	useEffect(() => {
		AsyncStorage.getItem("favorites").then((favoritesData) => {
			if (favoritesData) {
				const data = JSON.parse(favoritesData)
				const favObj = data.find((d) => d.distributorNumber === distributor.distributorNumber)
				setIsFavorite(favObj)
				setNotes(favObj.notes)
			}

			const location_name = distributor.name ? distributor.name : distributor.Name
			analyticsStore.logEvent("location_viewdetails",{location_name:location_name})
		})
		if (distributor.notes) setNotes(distributor.notes)
	}, [distributor])

	useEffect(() => {
		if(notes.length > 3){
			endEditing();
		}
	}, [notes])

	const endEditing = () => {
		let data = {
			...distributor,
			notes,
		}
		if (authStore.authenticated) {
			API.updateFavoriteDistributor(authStore.accountInfo.id, data).then((res) => {
				//if (res.ok) setDistributor(res.data.model)
			})
		} 
		AsyncStorage.getItem("favorites").then((favoritesData) => {
			if (favoritesData) {
				const distributorNumber = distributor.distributorNumber
					? distributor.distributorNumber
					: distributor.DistributorNo[0]
				const favorites = JSON.parse(favoritesData)
				const favObj = favorites.find((d) => d.distributorNumber === distributorNumber)
				if (favObj) {
					favObj.notes = notes
					favObj.synced = true
				}
				AsyncStorage.setItem("favorites", JSON.stringify(favorites))
			} else {
				AsyncStorage.setItem("favorites", JSON.stringify([data]))
			}
		})
	}
	const formatPhoneNumber = (number) => {
		console.log(number)
		try {
			if (typeof number === "string") {
				return PhoneNumber(number, 'US').formatNational()
			}
			return PhoneNumber(number[0], 'US').formatNational()
		} catch (err) {
			return ''
		}




	}
	return (
		<KeyboardAwareScrollView style={styles.mainContainer}>

			<Text style={styles.distributorTitle}>{distributor.name ? distributor.name : distributor.Name}</Text>
			<View style={styles.actionRow}>
				<Pressable
					style={styles.actionButton}
					onPress={async () => {
						console.log('map press event')
						const scheme = Platform.select({ ios: "maps:0,0?q=", android: "geo:0,0?q=" })
						const addr = distributor.address
							? `${distributor.address},${distributor.city},${distributor.state} ${distributor.zip}`
							: `${distributor.Address1},${distributor.City},${distributor.State} ${distributor.Zip}`

						//await Linking.openURL(`${scheme}${addr}`)
						const destination = encodeURIComponent(`${addr}`);  
						const provider = Platform.OS === 'ios' ? 'apple' : 'google'
						const link = `http://maps.${provider}.com/?daddr=${destination}`;
					
						try {
							const supported = await Linking.canOpenURL(link);
					
							if (supported) Linking.openURL(link);
						} catch (error) {
							console.log(error);
						}
						analyticsStore.logEvent("location_mapit",{location_name:location_name})
					}}
				>
					<Icon name="map" size={18} color={Colors.yellow} />
					<Text style={styles.actionText}>MAP IT</Text>
				</Pressable>
				<View style={{ width: 30 }} />
				{(Platform.OS==="ios" || Platform.OS === "android") &&(
					<Pressable
					style={styles.actionButton}
					onPress={() => {
						const phone = distributor.phone ? distributor.phone : distributor.Phone
						Linking.openURL(`tel:${phone}`)
						analyticsStore.logEvent("location_call",{location_name:location_name})
					}}
				>
					<SimpleIcon name="phone" size={18} color={Colors.yellow} />
					<Text style={styles.actionText}>CALL</Text>
				</Pressable>
				)}
				
			</View>
			{changesSaved && (
				<View style={styles.alertContainer}>
					<Image style={styles.logo} source={require("../assets/icn_check.png")} />
					<Text style={styles.alertText}>{distributor.name ? distributor.name : distributor.Name} address copied</Text>
				</View>
			)}
			<TouchableOpacity onPress={() => {
				const addr = distributor.address
					? `${distributor.address},${distributor.city},${distributor.state} ${distributor.zip}`
					: `${distributor.Address1},${distributor.City},${distributor.State} ${distributor.Zip}`
				Clipboard.setString(addr)
				setChangesSaved(true)
			}}>
				<Text style={styles.distributorBody}>{distributor.address ? distributor.address : distributor.Address1}</Text>
				{distributor.address2?.trim() ||
					((distributor.Address2) && (
						<Text style={styles.distributorBody}>
							{distributor.address2 ? distributor.address2 : distributor.Address2}
						</Text>
					))}
				<Text style={styles.distributorBody}>
					{distributor.city
						? `${distributor.city}, ${distributor.state} ${distributor.zip}`
						: `${distributor.City}, ${distributor.State} ${distributor.Zip}`}
				</Text>
			</TouchableOpacity>
			{(distributor.phone || (distributor.Phone)) && (
				<Text style={styles.distributorBody}>{`Phone: ${distributor.phone ? formatPhoneNumber(distributor.phone) : formatPhoneNumber(distributor.Phone)
					}`}
				</Text>
			)}
			{(distributor.distance || (distributor.Distance)) && (
			<Text style={styles.distributorBody}>{`Distance: ${distributor.distance ? Math.ceil(distributor.distance) : Math.ceil(distributor.Distance) 
					} miles`}
				</Text>
			)}
			{/* {(distributor.distance?.trim().length > 1 || (distributor.Distance && distributor.Distance.every(item => item.trim().length > 1))) && (
				<Text style={styles.distributorBody}>{`Distance: ${distributor.distance ?distributor.distance: distributor.Distance} ${distributor.distanceMarker ?distributor.distanceMarker: distributor.DistanceUOM}`}</Text>
			)} */}
			<Pressable onPress={() => toggleSave()} style={styles.favoriteButton}>
				{!isFavorite && <SimpleIcon name="star" size={18} color={Colors.yellow} />}
				{isFavorite && <Icon name="x" size={18} color={Colors.yellow} />}
				<Text style={styles.favoriteText}>{isFavorite ? "REMOVE FAVORITE" : "FAVORITE"}</Text>
			</Pressable>

			{isFavorite && (
				<Animatable.View useNativeDriver={true} animation="fadeIn" duration={300} ref={refContainer}>
					<TextInput
						style={[styles.input, { height: 150, paddingTop: 20 }]}
						placeholder="Add notes here..."
						onChangeText={setNotes}
						onEndEditing={endEditing}
						value={notes}
						underlineColorAndroid={Colors.transparent}
						multiline
						autoCorrect={false}
					/>
				</Animatable.View>
			)}

		</KeyboardAwareScrollView>
	)
}

export default DistributorDetails

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		paddingHorizontal: 30,
		paddingTop: 20,
		backgroundColor: Colors.backgroundGrey,
		width: maxWidth,
		alignSelf: "center",
	},
	actionRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: 20,
	},
	actionButton: {
		backgroundColor: Colors.darkGrey,
		flex: 1,
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 5,
	},
	actionText: {
		color: Colors.white,
		marginLeft: 10,
		fontWeight: "500",
	},
	alertContainer: {
		backgroundColor: "#E6EEDD",
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#92CB51",
		borderWidth: 1,
	},
	alertText: {
		color: "#729E3E",
		fontWeight: "bold",
		marginLeft: 10,
	},
	distributorTitle: {
		fontWeight: "bold",
		fontSize: 28,
	},
	distributorBody: {
		fontSize: 20,
		lineHeight: 30,
		fontWeight: "500",
	},
	favoriteButton: {
		backgroundColor: Colors.darkGrey,
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 5,
		width: 220,
		marginTop: 20,
	},
	favoriteIcon: {
		backgroundColor: "#E0E1E2",
		width: 44,
		height: 44,
		borderRadius: 22,
	},
	favoriteText: {
		color: Colors.white,
		marginLeft: 10,
		fontWeight: "500",
	},
	input: {
		marginTop: 20,
		color: Colors.darkGrey,
		backgroundColor: "#E1E2E3",
		padding: 15,
		borderRadius: 5,
		marginBottom: 20,
	},
})
