import React, { useState, useEffect } from "react"
import { StyleSheet, Text, Platform, Dimensions } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { TextInput } from "react-native-paper"
import { Button } from "../components/Index"
import { authStore } from "../utils/AuthStore"
import colors from "../utils/Colors"
import { API } from "../utils/API"

const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const maxButtonWidth =
	Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 400) : Dimensions.get("window").width

const ForgotPassword = ({ navigation }) => {
	const [email, setEmail] = useState("")
	const [requestSent, setRequestSent] = useState(false)
	const [loading, setLoading] = useState(false)

	const submit = () => {
		if (!loading) {
			setLoading(true)
			API.forgotPassword({ host:'', email }).then((res) => {
				if (res.ok) setRequestSent(true)
			})
		}
	}

	return (
		<SafeAreaView style={styles.mainContainer}>
			{!requestSent && (
				<>
					<Text style={styles.instructions}>
						Please enter the email associated to the account you're trying to access.
					</Text>
					<TextInput
						label="Email"
						value={email}
						onChangeText={(text) => setEmail(text.toLowerCase().trim())}
						textContentType="emailAddress"
						autoCompleteType="email"
						autoCapitalize="none"
						onSubmitEditing={submit}
						clearTextOnFocus
						blurOnSubmit
						style={styles.textField}
					/>
					<Button title="Send" disabled={!email} onPress={() => submit()} />
				</>
			)}
			{requestSent && (
				<>
					<Text style={styles.instructions}>An email has been sent with instructions on resetting your password.</Text>

					<Button title="Back to Login" buttonType="clear" onPress={() => navigation.goBack()} />
				</>
			)}
		</SafeAreaView>
	)
}

export default ForgotPassword

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		paddingHorizontal: 30,
		backgroundColor: colors.backgroundGrey,
		width: maxWidth,
		alignSelf: "center",
	},
	textField: {
		backgroundColor: colors.backgroundGrey,
		fontWeight: "bold",
		marginBottom: 30,
	},
	instructions: {
		textAlign: "center",
		fontSize: 18,
		fontWeight: "600",
		marginHorizontal: 20,
		marginBottom: 40,
	},
})
