import React, { useState, useEffect } from "react"
import { StyleSheet, Alert, View, Text, Pressable, Image, Platform, Dimensions } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { TextInput } from "react-native-paper"
import { Button,Popup } from "../components/Index"
import { authStore } from "../utils/AuthStore"
import colors from "../utils/Colors"
import * as LocalAuthentication from "expo-local-authentication"
import * as SecureStore from "expo-secure-store"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { navStore } from "../utils/NavStore"

const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const maxButtonWidth =
	Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 400) : Dimensions.get("window").width

const Login = ({ navigation }) => {
	navStore.navigation = navigation
	const [email, setEmail] = useState("") //admin@parker.com
	const [password, setPassword] = useState("") //acc3lla
	const [disabled, setDisabled] = useState(false)
	const [faceID, setFaceID] = useState(true)
	const [faceIDSuccess, setFaceIDSuccess] = useState(false)
	const [modalVisible, setModalVisible] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	useEffect(() => {
		setDisabled(!email || !password)
	}, [email, password])

	useEffect(() => {
		if (faceIDSuccess) submit()
	}, [faceIDSuccess])

	useEffect(() => {
		async function fetchData() {
			const savedEmail = await getSecureValueFor("email")
			const savedPassword = await getSecureValueFor("password")

			AsyncStorage.getItem("faceID").then((faceIDStr) => {
				console.log(faceIDStr)
				let faceIDObj = JSON.parse(faceIDStr)
				if (faceIDObj.faceID && savedEmail && savedPassword) {
					checkFaceID(savedEmail, savedPassword)
				}
			})
		}
		if (Platform.OS !== "web") fetchData()
	}, [])

	const submit = () => {
		AsyncStorage.setItem("faceID", JSON.stringify({ faceID }))

		authStore.login(email, password).then((res) => {
			if (res.ok) navigation.navigate("Account")
			else {
				if (Platform.OS === "web") {
					setAlertMessage("Username or password incorrect");
					setModalVisible(true);
				} else
					Alert.alert("Error", "Username or password incorrect")
			}
		})
	}

	const getSecureValueFor = async (key) => {
		return await SecureStore.getItemAsync(key)
	}

	const checkFaceID = async (savedEmail, savedPassword) => {
		try {
			// Checking if device is compatible
			const isCompatible = await LocalAuthentication.hasHardwareAsync()

			if (!isCompatible) {
				throw new Error("Your device isn't compatible.")
			}

			// Checking if device has biometrics records
			const isEnrolled = await LocalAuthentication.isEnrolledAsync()

			if (!isEnrolled) {
				throw new Error("No Faces / Fingers found.")
			}

			// Authenticate user
			const result = await LocalAuthentication.authenticateAsync({ disableDeviceFallback: false })

			console.log(result)
			if (result.success) {
				setEmail(savedEmail)
				setPassword(savedPassword)
				setFaceIDSuccess(true)
				authStore.syncLocalData(authStore.accountInfo.id)
			}
		} catch (error) {
			// Error
			console.log("error", error)
		}
	}

	return (
		<SafeAreaView style={styles.mainContainer}>
			<TextInput
				label="Email"
				value={email}
				onChangeText={(text) => setEmail(text.toLowerCase().trim())}
				textContentType="email"
				autoCompleteType="email"
				autoCapitalize="none"
				style={styles.textField}
			/>
			<TextInput
				label="Password"
				value={password}
				onChangeText={(text) => setPassword(text.trim())}
				secureTextEntry
				textContentType="password"
				autoCompleteType="password"
				onSubmitEditing={submit}
				clearTextOnFocus
				blurOnSubmit
				style={styles.textField}
			/>
			{Platform.OS !== "web" && (
				<Pressable style={styles.toggleContainer} onPress={() => setFaceID(!faceID)}>
					<Image
						style={styles.image}
						source={faceID ? require("../assets/icn_option_selected.png") : require("../assets/icn_option.png")}
					/>
					<Text style={styles.title}>Setup Face ID</Text>
				</Pressable>
			)}

			<Button
				style={{ width: maxButtonWidth, alignSelf: "center" }}
				title="Log In"
				disabled={disabled}
				onPress={() => submit()}
			/>
			<Button title="Forgot Password?" buttonType="clear" onPress={() => navigation.navigate("ForgotPassword")} />
			<View style={styles.orContainer}>
				<View style={styles.line} />
				<Text style={styles.or}>OR</Text>
				<View style={styles.line} />
			</View>
			<Button
				title="Continue As Guest"
				buttonType="outline"
				style={{ width: maxButtonWidth, alignSelf: "center" }}
				onPress={() => {
					navigation.goBack()
					navigation.navigate("FinderStack")
				}}
			/>
			{modalVisible && (
				<Popup
					title=""
					body="Username or password incorrect"
					closeAction={() => setModalVisible(false)}
					// cancelAction={() => setShowConfirmDelete(false)}
					closeButtonTitle="Ok"
					// cancelButtonTitle="Cancel"
				/>
			)}
			
		</SafeAreaView>

	)
}

export default Login

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		paddingHorizontal: 30,
		backgroundColor: colors.backgroundGrey,
		width: maxWidth,
		alignSelf: "center",
	},
	textField: {
		backgroundColor: colors.backgroundGrey,
		fontWeight: "bold",
		marginBottom: 20,
	},
	toggleContainer: {
		flexDirection: "row",
		marginBottom: 50,
		alignItems: "center",
	},
	image: {
		marginRight: 10,
	},
	orContainer: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginVertical: 20,
	},
	or: {
		fontWeight: "bold",
		fontSize: 18,
	},
	line: {
		backgroundColor: colors.black,
		height: 1,
		width: 75,
		marginHorizontal: 10,
	},
})
