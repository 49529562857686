import React from "react"
import { Image, Pressable, StyleSheet, Text, View, Platform, Dimensions } from "react-native"
import Colors from "../../utils/Colors"
import { Button } from "../../components/Index"
import { toJS } from "mobx"
import { partStore } from "../../utils/PartStore"

const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const Tool1 = ({ navigation }) => {
	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
				<Pressable onPress={() => navigation.navigate("TabStack")} style={{ marginRight: 10 }}>
					<Image source={require("../../assets/icn_close.png")} />
				</Pressable>
			),
		})
	}, [navigation])

	const endType = partStore.part[`end${partStore.selectedEndNumber}`]?.endType

	return (
		<View style={styles.mainContainer}>
			<View style={{ flex: 1 }}>
				<Text style={styles.step}>Step 1 of 3 </Text>
				<Text style={styles.title}>Verify if parallel or tapered</Text>
				<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
					<Text style={styles.visual}>Visual Inspection</Text>
					<Text style={styles.visual}>{"Compare with\nknown parallel"}</Text>
				</View>
				<Image
					style={{ width: "100%", height: 150 }}
					resizeMode="contain"
					source={require("../../assets/parallel_or_tapered.png")}
				/>
				<View style={styles.row}>
					<Text style={{ fontSize: 12 }}>Parallel</Text>
					<Text style={{ fontSize: 12 }}>Tapered</Text>

					<Text style={{ fontSize: 12 }}>Parallel</Text>
					<Text style={{ fontSize: 12 }}>Tapered</Text>
				</View>
				<Text style={styles.subtitle}>
					The end type you have selected is {endType?.isTapererd ? "tapered" : "parallel"}.
				</Text>
				<Text style={styles.body}>If this is not correct, close this window to select a different type.</Text>
			</View>
			<Button style={{ marginBottom: 20 }} title="Next" theme="yellow" onPress={() => navigation.navigate("Tool2")} />
		</View>
	)
}

export default Tool1

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		padding: 20,
		width: maxWidth,
		alignSelf: "center",
	},
	visual: {
		fontSize: 12,
		textAlignVertical: "bottom",
	},
	title: {
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: 24,
		marginBottom: 20,
	},
	subtitle: {
		fontSize: 18,
		fontWeight: "600",
		lineHeight: 24,
	},
	body: {
		fontSize: 18,
		lineHeight: 24,
		marginVertical: 20,
	},
	step: {
		fontSize: 18,
		fontWeight: "600",
		marginBottom: 7,
	},
	row: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 20,
	},
})
