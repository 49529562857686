import React, { useEffect, useState } from "react"
import { Pressable, StyleSheet, Text, View, Image, Platform, Dimensions, Linking } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { authStore } from "../utils/AuthStore"
import { observer } from "mobx-react-lite"
import { Button, Popup } from "../components/Index"
import Colors from "../utils/Colors"
import Constants from "expo-constants"
import { signIn, signOut } from '@okta/okta-react-native';
import AsyncStorage from "@react-native-async-storage/async-storage"
import * as LocalAuthentication from "expo-local-authentication"
import * as SecureStore from "expo-secure-store"
import { navStore } from "../utils/NavStore"
import oktaAuth from '../auth.config';
import wrenchIcon from '../assets/icn-assembly.png'
import catalogIcon from '../assets/icn-catalog.png'
import contactIcon from '../assets/icn-contact.png'
import aboutIcon from '../assets/icn-about.png'
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const maxButtonWidth =
	Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 400) : Dimensions.get("window").width

const Resources = ({ navigation, route }) => {
	navStore.navigation = navigation
	const navigate = (screen) => {
		navigation.navigate(screen)
		navigation.setParams({ accountCreated: false, accountDeleted: false })

	}

	return (
		<View style={styles.mainContainer}>
			<View style={styles.actionRow}>
				<Pressable style={styles.actionButton}
					onPress={() => {
						Linking.openURL(
							"https://www.parker.com/us/en/divisions/tube-fittings-division/resources/assembly-and-installation-faqs.html#reassembly"
						)
					}}
				>

					<Image style={{ height: 20, width: 20 }} source={wrenchIcon} />

					<Text style={styles.actionText}>Assembly & Installation</Text>
				</Pressable>
			</View>
			<View style={styles.actionRow}>
				<Pressable style={styles.actionButton}
					onPress={() => {

						Linking.openURL(
							"https://www.parker.com/content/dam/Parker-com/Literature/Tube-Fittings-Division/4300_Catalog_Cover.pdf"
						)

					}}
				>
					<Image style={{ height: 20, width: 20 }} source={catalogIcon} />
					<Text style={styles.actionText}>Tube Fittings Division Catalog 4300</Text>
				</Pressable>
			</View>
			<View style={styles.actionRow}>
				<Pressable style={styles.actionButton} onPress={() => navigate("Contact")}>
					<Image style={{ height: 20, width: 20 }} source={contactIcon} />
					<Text style={styles.actionText}>Contact Us</Text>
				</Pressable>
			</View>
			<View style={styles.actionRow}>
				<Pressable style={[styles.actionButton, { flexDirection: 'row', alignItems: 'center',  }]} onPress={() => navigate("About")}>
					<Image style={{ height: 20, width: 20 }} source={aboutIcon} />
					<Text style={styles.actionText}>About FittingFinder</Text>
				</Pressable>
			</View>


		</View>
	)
}

export default observer(Resources)

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: Platform.OS === "web" ? 0 : 1,
		paddingHorizontal: 30,
		paddingBottom: 20,
		backgroundColor: Platform.OS !== "web" ? Colors.backgroundGrey : Colors.transparent,
		width: maxWidth,
		alignSelf: "center",
	}, actionButton: {
		backgroundColor: Colors.darkGrey,
		flex: 1,
		padding: 10,
		flexDirection: "row",
		
		alignItems: "center", // Center vertically
		borderRadius: 5,
	}, actionRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: 20,
		marginBottom: 20,
	}, actionText: {
		color: Colors.white,
		marginLeft: 10,
		fontWeight: "500",
		textAlign: "center", // Center text horizontally
		flex:1
	},
	menuItem: {
		fontWeight: "600",
		fontSize: 22,
		marginVertical: 15,
	},
	createdContainer: {
		backgroundColor: "#E6EEDD",
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#92CB51",
		borderWidth: 1,
	},
	createdText: {
		color: "#729E3E",
		fontWeight: "bold",
		marginLeft: 10,
	},
	info: {
		textAlign: "center",
		marginBottom: 10,
	},
})
