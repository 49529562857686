import React, { useState, useEffect, useRef } from "react"
import { StyleSheet, Text, View, Image, Pressable, Platform, Linking, TouchableOpacity } from "react-native"
import Colors from "../utils/Colors"
import Icon from "react-native-vector-icons/Ionicons"
import { partStore } from "../utils/PartStore"
import { navStore } from '../utils/NavStore';
const WebNavBar = () => {
    const [currentScreen, setCurrentScreen] = useState("FittingSelection")
  
    const navigate = (stack,screen) =>{
       if(screen==="FittingSelection"){
        partStore.getShapes(1)
       } 
       navStore.navigate(stack,screen)
       setCurrentScreen(screen)
    }

    return (
        
        <View style={styles.navigationBar}>
            <View style={styles.menuBar}>
                <View style={styles.logoContainer}>
                    <Image style={styles.logo} resizeMode="contain" source={require("../assets/logo-parker-ff-2.png")} />
                </View>
                <View style={styles.navItemsContainer}>
                <View style={styles.spacer} /> {/* Flexible spacer */}
                    <TouchableOpacity onPress={() => navigate('FinderStack','FittingSelection')}>
                        <Text style={[styles.navItem,currentScreen === "FittingSelection"?styles.navSelectedItem:null]}>Build Fitting</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigate('FinderStack','FittingSearch')} style={styles.searchItemContainer}>
                        {/* <Icon style={[styles.navSearchItem,currentScreen === "FittingSearch"?{color:Colors.black, marginRight:5}:{marginRight:5}]} name="ios-search-outline" color={Colors.white} /> */}
                        <Text style={[styles.navItem,currentScreen === "FittingSearch"?styles.navSelectedItem:null]}>Search Part</Text>
                    </TouchableOpacity>
                    
                    <TouchableOpacity onPress={() => navigate('WhereToBuyStack','Where to Buy')}>
                        <Text style={[styles.navItem,currentScreen === "Where to Buy"?styles.navSelectedItem:null]}>Where to Buy</Text>
                    </TouchableOpacity>
                    
                    <TouchableOpacity onPress={() => navigate('ResourceStack','Resources')} style={styles.searchItemContainer}>
                        {/* <Icon style={[styles.navSearchItem,currentScreen === "FittingSearch"?{color:Colors.black, marginRight:5}:{marginRight:5}]} name="ios-search-outline" color={Colors.white} /> */}
                        <Text style={[styles.navItem,currentScreen === "Resources"?styles.navSelectedItem:null]}>Resources</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigate('AccountStack','Account')}>
                        <Text style={[styles.navItem,currentScreen === "Account"?styles.navSelectedItem:null]}>Account</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
        
    );
};


export default WebNavBar;
const styles = StyleSheet.create({
    navigationBar: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 75,
        backgroundColor: 'white',
        width: '100%',

        justifyContent: 'center', // Horizontally center its children
        alignItems: 'center',
    },
    menuBar: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 75,
        backgroundColor: 'white',
        width: 1000,
        //alignSelf: 'center', // Horizontally center the menuBar within its parent

    },
    logoContainer: {
        flex: 1, // Take up 1/3 of the available space

    },
    logo: {
        width: 282,
        height: 46,

    },
    searchItemContainer: {
        flexDirection: 'row', // Arrange children in a row
        alignItems: 'center', // Vertically center the children
    },
    navItemsContainer: {
        flex: 5, // Take up 2/3 of the available space
        flexDirection: 'row',
        alignItems: 'center',
        //   justifyContent: 'space-around',
        position: 'relative', // Use absolute positioning
        paddingTop:8,
    },
    navItem: {
        color: 'black',
        fontSize: 16,
        fontWeight: 600,
        marginRight: 50,
        paddingBottom: 4, // Add padding between text and underline

    },
    navSelectedItem: {
        color: 'black', /* Set the text color to black */
        borderBottomWidth: 2, // Adjust the value to make the underline thicker
        borderBottomColor: Colors.yellow, // Set the underline color to yellow
        
       },
    navSearchItem: {
        color: 'black',
        fontSize: 24,
        fontWeight: 600,
        

    },
    absoluteRight: {
        position: 'absolute',
        right: 0,
    },
    spacer: {
        flex: 1, // Create a flexible spacer to push items to the right
    },
});