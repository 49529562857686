import React, { useState } from "react"
import { Image, Pressable, StyleSheet, View, FlatList, Platform, Dimensions } from "react-native"
import Colors from "../../utils/Colors"
import { Surface, Text } from "react-native-paper"
import { Button, Popup } from "../../components/Index"
import { toJS } from "mobx"
import { partStore } from "../../utils/PartStore"

const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const Tool3 = ({ navigation, route }) => {
	//const step2Selection = route.params.selectedItem
	const [selectedItem, setSelectedItem] = useState()
	const [showConfirmNoMatch, setShowConfirmNoMatch] = useState(false)

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
				<Pressable onPress={() => navigation.navigate("TabStack")} style={{ marginRight: 10 }}>
					<Image source={require("../../assets/icn_close.png")} />
				</Pressable>
			),
		})
	}, [navigation])

	console.log(toJS(partStore.threadIdentification))
	console.log(toJS(route.params.selectedItem))

	const endType = route.params.selectedItem?.endTypeSizes[0]?.endType
	let column1 = endType.isPipe ? "PROFILE" : "TUBE SIZE"
	let column2 = endType.isPipe || endType.isTube ? "THREAD OD" : ""
	if (!endType.isPipe && !endType.isTube) {
		column1 = "THREAD OD"
		column2 = ""
	}

	let selectedThreadSize = "Select a Size"
	if (selectedItem) {
		console.log(toJS(selectedItem))
		selectedThreadSize = selectedItem.thread ? `${selectedItem.thread} Profile ` : ""
		selectedThreadSize += selectedItem.outsideDiameterInches ? `${selectedItem.outsideDiameterInches} OD ` : ""
		selectedThreadSize += selectedItem.outsideDiameterMillimeters
			? `${selectedItem.outsideDiameterMillimeters} OD `
			: ""
		selectedThreadSize += selectedItem.dash ? `-${selectedItem.dash} Dash` : ""
	}

	return (
		<View style={styles.mainContainer}>
			<View>
				<Text style={styles.step}>Step 3 of 3 </Text>
				<Text style={styles.title}>Determine Thread Size</Text>
			</View>
			<Image
				style={{ width: "100%", height: 150 }}
				resizeMode="contain"
				source={require("../../assets/determine_thread_size.png")}
			/>
			<Surface style={styles.listContainer}>
				<View style={{ flexDirection: "row", marginLeft: 15, marginTop: 10 }}>
					<Text style={{ fontSize: 12, width: 150 }}>{column1}</Text>
					<Text style={{ fontSize: 12 }}>{column2}</Text>
				</View>
				<FlatList
					data={[...route.params.selectedItem.endTypeSizes, "No Match"]}
					keyExtractor={(item) => item.outsideDiameterInches}
					renderItem={({ item }) => {
						let label1 = "No Match"
						let label2 = ""
						if (item !== "No Match") {
							let threadComponents = item.thread.includes("X") ? item.thread.split("X") : item.thread.split("-")
							if (item.endType.isPipe) {
								label1 = threadComponents[0]
								label2 = item.threadOutsideDiameter
							} else if (item.endType.isTube) {
								label1 = item.outsideDiameterInches ? item.outsideDiameterInches : item.outsideDiameterMillimeters
								label2 = threadComponents[0]
							} else {
								label1 = threadComponents[0]
							}
						}
						return (
							<Pressable onPress={() => setSelectedItem(item)} style={styles.row}>
								<View style={{ flexDirection: "row" }}>
									<Text style={[styles.itemTitle, { width: 150 }]}>{label1}</Text>
									<Text style={styles.itemTitle}>{label2}</Text>
								</View>
								{selectedItem?.id === item?.id && <Image source={require("../../assets/icn_selected.png")} />}
							</Pressable>
						)
					}}
				/>
				<Text style={{ margin: 10, fontWeight: "bold" }}>{`Thread Size: ${selectedThreadSize}`}</Text>
			</Surface>
			<Button
				style={{ marginBottom: 20 }}
				disabled={!selectedItem}
				title="Next"
				theme="yellow"
				onPress={() => {
					if (selectedItem === "No Match") {
						setShowConfirmNoMatch(true)
					} else {
						partStore.setPartObj("endSize", selectedItem)
						if (partStore.allEndsFilledOut()) {
							partStore.getMaterials().then(() => {
								let data = partStore.materials.slice()
								navigation.navigate("TabStack")
								if (data.length === 1) {
									partStore.setPartObj("material", data[0])
									partStore
										.getProducts()
										.then(() => navigation.push("FittingDetails", { fittingScreen: partStore.nextScreen[screen] }))
								} else navigation.push("FittingSelection", { fittingScreen: "material" })
							})
						} else {
							navigation.navigate("TabStack")
							navigation.navigate("FittingOverview", { fittingScreen: partStore.nextScreen["endSize"] })
						}
					}
				}}
			/>
			{showConfirmNoMatch && (
				<Popup
					title="No Match"
					body="Select Ok to choose a new end."
					closeAction={() => navigation.navigate("TabStack")}
					cancelAction={() => setShowConfirmNoMatch(false)}
					closeButtonTitle="Ok"
					cancelButtonTitle="Cancel"
				/>
			)}
		</View>
	)
}

export default Tool3

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		padding: 20,
		width: maxWidth,
		alignSelf: "center",
	},
	title: {
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: 24,
		marginBottom: 20,
	},
	subtitle: {
		fontSize: 18,
		fontWeight: "600",
		lineHeight: 24,
	},
	body: {
		fontSize: 18,
		lineHeight: 24,
		marginVertical: 20,
	},
	step: {
		fontSize: 18,
		fontWeight: "600",
		marginBottom: 7,
	},
	row: {
		borderBottomColor: Colors.grey,
		borderBottomWidth: 1,
		justifyContent: "space-between",
		alignItems: "center",
		marginLeft: 20,
		paddingVertical: 17,
		flexDirection: "row",
		paddingRight: 15,
	},
	itemTitle: {
		fontSize: 18,
		fontWeight: "600",
	},
	listContainer: {
		flex: 1,
		backgroundColor: Colors.white,
		marginBottom: 20,
	},
})
