import { Platform } from 'react-native';
const getBaseUrl = () => {
    if (Platform.OS === 'web') {
        
        const protocol = window.location.protocol;
        const host = window.location.hostname;
        // Use window.location to construct the base URL dynamically
       
        return `${protocol}//${host}`;
    } else {
        // Return a fixed URL for non-web platforms
        return 'https://parkerfittingfinder.com';
    }
};
//Local
//EXPO_PUBLIC_API_URL=http://localhost:5000/api
//const API_URL = 'http://localhost:5000';

//AccellaTest
//const API_URL = 'http://ec2-34-230-76-113.compute-1.amazonaws.com';
//const WEB_URL = 'http://ec2-34-230-76-113.compute-1.amazonaws.com';
//const WEB_URL =  'https://test.parkerfittingfinder.com';
//const IMGSRV_URL = `https://test.parkerfittingfinder.com/backend`;//'http://ec2-34-230-76-113.compute-1.amazonaws.com/api'

//const WEB_URL = getBaseUrl();// 'https://test.parkerfittingfinder.com/';
//const IMGSRV_URL = `${getBaseUrl()}/backend`;//'http://ec2-34-230-76-113.compute-1.amazonaws.com/api'

//Parker Test
const API_BASE_URL = "https://api.parker.com/prod"
//const API_URL = 'https://apidev.parker.com/fitting-finder-api-dev';
//const GATEWAY_KEY='1965d46f36d34f25b5b2ad154dd7d99d'
//const WEB_URL =  'https://test.parkerfittingfinder.com';
//const IMGSRV_URL = `https://test.parkerfittingfinder.com/backend`;//'http://ec2-34-230-76-113.compute-1.amazonaws.com/api'


//Parker Prod
////const API_BASE_URL = "https://api.parker.com/prod"
const API_URL = 'https://api.parker.com/fitting-finder-api';
const GATEWAY_KEY='51f0e9ba9d9343ea991aebd58302bc34'
const WEB_URL =  'https://parkerfittingfinder.com';
const IMGSRV_URL = `https://parkerfittingfinder.com/backend`;//'http://ec2-34-230-76-113.compute-1.amazonaws.com/api'
export {API_URL, WEB_URL,IMGSRV_URL, GATEWAY_KEY,API_BASE_URL};