import React, { useState } from "react"
import { StyleSheet, Text, View, Platform, Dimensions, Image } from "react-native"
import Colors from "../utils/Colors"
import { partStore } from "../utils/PartStore"
import {API_URL, WEB_URL} from '../utils/Configuration';
const maxWidth =
	Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 558) : Dimensions.get("window").width - 40

const FittingImage = (props) => {
	let end1Path = partStore.part[`end1`]?.endType?.image?.path
	let end2Path = partStore.part[`end2`]?.endType?.image?.path
	let end3Path = partStore.part[`end3`]?.endType?.image?.path
	let end4Path = partStore.part[`end4`]?.endType?.image?.path

	const [end1AspectRatio, setEnd1AspectRatio] = useState(1)
	const [end2AspectRatio, setEnd2AspectRatio] = useState(1)
	const [end3AspectRatio, setEnd3AspectRatio] = useState(1)
	const [end4AspectRatio, setEnd4AspectRatio] = useState(1)

	let end1Position = { left: 0, top: 0, rotate: "0deg", scale: 1 }
	let end2Position = { left: 0, top: 0, rotate: "0deg", scale: 1 }
	let end3Position = { left: 0, top: 0, rotate: "0deg", scale: 1 }
	let end4Position = { left: 0, top: 0, rotate: "0deg", scale: 1 }

	let end1Label = { left: 0, top: 0 }
	let end2Label = { left: 0, top: 0 }
	let end3Label = { left: 0, top: 0 }
	let end4Label = { left: 0, top: 0 }

	let topPadding = 60

	if (partStore.part?.shape?.name === "Straight" || partStore.part?.shape?.name === "Check Valve") {
		//console.log(end1AspectRatio < 1 ? maxWidth / 2 - 120 + (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 - 120)
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 - 120 + (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 - 120,
			top: 50 + topPadding,
			rotate: "90deg",
			scale: 1,
			bgLeft: maxWidth / 2 - 100,
			bgTop: 60 + topPadding,
		}
		end2Position = {
			left: end2AspectRatio < 1 ? maxWidth / 2 + 20 - (100 - 100 * end2AspectRatio) / 2 : maxWidth / 2 + 20,
			top: 50 + topPadding,
			rotate: "-90deg",
			scale: 1,
			bgLeft: maxWidth / 2 + 20,
			bgTop: 60 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 - 140,
			top: 25 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 + 85,
			top: 25 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "90°") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 + 15 - (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 + 15,
			top: 10 + topPadding,
			rotate: "-90deg",
			scale: 1,
			bgLeft: maxWidth / 2 + 15,
			bgTop: 15 + topPadding,
		}
		end2Position = {
			left: maxWidth / 2 - 90,
			top: end2AspectRatio < 1 ? 120 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 120 + topPadding,
			rotate: "0deg",
			scale: 1,
			bgLeft: maxWidth / 2 - 90,
			bgTop: 120 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 + 85,
			top: 115 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 + 10,
			top: 190 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "45°") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 - (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 + -10,
			top: end1AspectRatio < 1 ? (100 - 100 * end1AspectRatio) / 2 - 25 + topPadding : topPadding - 20,
			rotate: "225deg",
			scale: 1,
			bgLeft: maxWidth / 2,
			bgTop: topPadding,
		}
		end2Position = {
			left: maxWidth / 2 - 70,
			top: end2AspectRatio < 1 ? 133 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 130 + topPadding,
			rotate: "0deg",
			scale: 1,
			bgLeft: maxWidth / 2 - 60,
			bgTop: 130 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 + 80,
			top: 65 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 + 30,
			top: 190 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Tee") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 - 137 + (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 - 137,
			top: 20 + topPadding,
			rotate: "90deg",
			scale: 0.9,
			bgLeft: maxWidth / 2 - 125,
			bgTop: 20 + topPadding,
		}
		end2Position = {
			left: end2AspectRatio < 1 ? maxWidth / 2 + 40 - (100 - 100 * end2AspectRatio) / 2 : maxWidth / 2 + 40,
			top: 20 + topPadding,
			rotate: "-90deg",
			scale: 0.9,
			bgLeft: maxWidth / 2 + 40,
			bgTop: 20 + topPadding,
		}
		end3Position = {
			left: maxWidth / 2 - 45,
			top: end3AspectRatio < 1 ? 110 + topPadding - (100 - 100 * end3AspectRatio) / 2 : 110 + topPadding,
			rotate: "0deg",
			scale: 0.9,
			bgLeft: maxWidth / 2 - 45,
			bgTop: 110 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 - 130,
			top: topPadding,
		}
		end2Label = {
			left: maxWidth / 2 + 80,
			top: 120 + topPadding,
		}
		end3Label = {
			left: maxWidth / 2 - 100,
			top: 170 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Cross") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 - 140 + (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 - 140,
			top: 50 + topPadding,
			rotate: "90deg",
			scale: 1,
			bgLeft: maxWidth / 2 - 125,
			bgTop: 50 + topPadding,
		}
		end2Position = {
			left: end2AspectRatio < 1 ? maxWidth / 2 + 43 - (100 - 100 * end2AspectRatio) / 2 : maxWidth / 2 + 43,
			top: 50 + topPadding,
			rotate: "-90deg",
			scale: 1,
			bgLeft: maxWidth / 2 + 40,
			bgTop: 50 + topPadding,
		}
		end3Position = {
			left: maxWidth / 2 - 50,
			top: end3AspectRatio < 1 ? topPadding - 40 + (100 - 100 * end3AspectRatio) / 2 : topPadding - 40,
			rotate: "180deg",
			scale: 1,
			bgLeft: maxWidth / 2 - 50,
			bgTop: topPadding - 25,
		}
		end4Position = {
			left: maxWidth / 2 - 50,
			top: end4AspectRatio < 1 ? 140 + topPadding - (100 - 100 * end4AspectRatio) / 2 : 140 + topPadding,
			rotate: "0deg",
			scale: 1,
			bgLeft: maxWidth / 2 - 50,
			bgTop: 140 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 - 130,
			top: 25 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 + 80,
			top: 155 + topPadding,
		}
		end3Label = {
			left: maxWidth / 2 + 55,
			top: 0 + topPadding,
		}
		end4Label = {
			left: maxWidth / 2 - 110,
			top: 180 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Y") {
		end1Position = {
			left: maxWidth / 2 - 80,
			top: end1AspectRatio < 1 ? 150 + topPadding - (100 - 100 * end1AspectRatio) / 2 : 150 + topPadding,
			rotate: "0deg",
			scale: 0.5,
			bgLeft: maxWidth / 2 - 80,
			bgTop: 175 + topPadding,
		}
		end2Position = {
			left: maxWidth / 2 - 20,
			top: end2AspectRatio < 1 ? 150 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 150 + topPadding,
			rotate: "0deg",
			scale: 0.5,
			bgLeft: maxWidth / 2,
			bgTop: 175 + topPadding,
		}
		end3Position = {
			left: maxWidth / 2 - 50,
			top: end3AspectRatio < 1 ? topPadding - 50 + (100 - 100 * end3AspectRatio) / 2 : topPadding - 50,
			rotate: "180deg",
			scale: 0.5,
			bgLeft: maxWidth / 2 - 50,
			bgTop: topPadding - 50,
		}
		end1Label = {
			left: maxWidth / 2 - 110,
			top: 180 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 + 60,
			top: 180 + topPadding,
		}
		end3Label = {
			left: maxWidth / 2 - 90,
			top: 0 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Compact") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 + 40 - (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 + 40,
			top: 45 + topPadding,
			rotate: "-90deg",
			scale: 0.7,
			bgLeft: maxWidth / 2 + 55,
			bgTop: 45 + topPadding,
		}
		end2Position = {
			left: maxWidth / 2 - 105,
			top: end2AspectRatio < 1 ? 150 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 150 + topPadding,
			rotate: "0deg",
			scale: 0.7,
			bgLeft: maxWidth / 2 - 105,
			bgTop: 170 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 + 85,
			top: 40 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 - 15,
			top: 180 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "In-Line") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 - 145 + (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 - 145,
			top: 80 + topPadding,
			rotate: "90deg",
			scale: 0.5,
			bgLeft: maxWidth / 2 - 155,
			bgTop: 80 + topPadding,
		}
		end2Position = {
			left: end2AspectRatio < 1 ? maxWidth / 2 + 45 - (100 - 100 * end2AspectRatio) / 2 : maxWidth / 2 + 45,
			top: 80 + topPadding,
			rotate: "-90deg",
			scale: 0.5,
			bgLeft: maxWidth / 2 + 70,
			bgTop: 80 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 - 140,
			top: 80 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 + 85,
			top: 80 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Knobless") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 + 45 - (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 + 45,
			top: 42 + topPadding,
			rotate: "-90deg",
			scale: 0.7,
			bgLeft: maxWidth / 2 + 62,
			bgTop: 45 + topPadding,
		}
		end2Position = {
			left: maxWidth / 2 - 115,
			top: end2AspectRatio < 1 ? 135 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 135 + topPadding,
			rotate: "0deg",
			scale: 0.7,
			bgLeft: maxWidth / 2 - 115,
			bgTop: 150 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 + 85,
			top: 35 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 - 25,
			top: 180 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Plug-In") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 + 20 - (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 + 20,
			top: 37 + topPadding,
			rotate: "-90deg",
			scale: 0.5,
			bgLeft: maxWidth / 2 + 45,
			bgTop: 40 + topPadding,
		}
		end2Position = {
			left: maxWidth / 2 - 95,
			top: end2AspectRatio < 1 ? 125 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 125 + topPadding,
			rotate: "0deg",
			scale: 0.5,
			bgLeft: maxWidth / 2 - 95,
			bgTop: 145 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 + 45,
			top: 40 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 - 5,
			top: 180 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Swivel Outlet") {
		end1Position = {
			left: maxWidth / 2 - 5,
			top: end1AspectRatio < 1 ? topPadding - 55 + (100 - 100 * end1AspectRatio) / 2 : topPadding - 55,
			rotate: "180deg",
			scale: 0.5,
			bgLeft: maxWidth / 2 - 5,
			bgTop: topPadding - 63,
		}
		end2Position = {
			left: maxWidth / 2 - 85,
			top: end2AspectRatio < 1 ? 140 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 140 + topPadding,
			rotate: "0deg",
			scale: 0.5,
			bgLeft: maxWidth / 2 - 85,
			bgTop: 165 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 + 70,
			top: 0 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2,
			top: 180 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Miniature") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 + 47 - (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 + 45,
			top: 65 + topPadding,
			rotate: "-90deg",
			scale: 0.7,
			bgLeft: maxWidth / 2 + 63,
			bgTop: 65 + topPadding,
		}
		end2Position = {
			left: maxWidth / 2 - 105,
			top: end2AspectRatio < 1 ? 150 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 150 + topPadding,
			rotate: "0deg",
			scale: 0.7,
			bgLeft: maxWidth / 2 - 105,
			bgTop: 165 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 + 60,
			top: 50 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 - 20,
			top: 180 + topPadding,
		}
	} else if (partStore.part?.shape?.name === "Single Banjo") {
		end1Position = {
			left: end1AspectRatio < 1 ? maxWidth / 2 - 135 + (100 - 100 * end1AspectRatio) / 2 : maxWidth / 2 - 135,
			top: 37 + topPadding,
			rotate: "90deg",
			scale: 0.6,
			bgLeft: maxWidth / 2 - 140,
			bgTop: 37 + topPadding,
		}
		end2Position = {
			left: maxWidth / 2 + 10,
			top: end2AspectRatio < 1 ? 100 + topPadding - (100 - 100 * end2AspectRatio) / 2 : 100 + topPadding,
			rotate: "0deg",
			scale: 0.6,
			bgLeft: maxWidth / 2 + 10,
			bgTop: 120 + topPadding,
		}
		end1Label = {
			left: maxWidth / 2 - 120,
			top: 30 + topPadding,
		}
		end2Label = {
			left: maxWidth / 2 + 100,
			top: 150 + topPadding,
		}
	}

	let end1URI = `${WEB_URL}/backend/static/img/config/${end1Path}`
	let end2URI = `${WEB_URL}/backend/static/img/config/${end2Path}`
	let end3URI = `${WEB_URL}/backend/static/img/config/${end3Path}`
	let end4URI = `${WEB_URL}/backend/static/img/config/${end4Path}`

	if (end1Path)
		Image.getSize(end1URI, (width, height) => {
			if (height > 0) setEnd1AspectRatio(height / width)
		})
	if (end2Path)
		Image.getSize(end2URI, (width, height) => {
			if (height > 0) setEnd2AspectRatio(height / width)
		})
	if (end3Path)
		Image.getSize(end3URI, (width, height) => {
			if (height > 0) setEnd3AspectRatio(height / width)
		})
	if (end4Path)
		Image.getSize(end4URI, (width, height) => {
			if (height > 0) setEnd4AspectRatio(height / width)
		})

	const xOffset = props.correctOffset ? 5 : 0

	if (partStore.part?.productType?.name === "Component" && end1Path)
		return (
			<Image
				source={{ uri: end1URI }}
				style={{
					height: 200,
					width: 200,
					alignSelf: "center",
					marginVertical: 60,
				}}
				resizeMode="contain"
			/>
		)

	return (
		<>
			<Image
				source={{
					uri: `${WEB_URL}/static/img/config/${partStore.part?.shape?.image?.path.replace(/\.png$/, '.jpg')}`,
				}}
				style={{
					height: 200,
					width: 200,
					alignSelf: "center",
					marginVertical: 60,
				}}
				resizeMode="contain"
			/>

			{end1Path && (
				<View
					style={{
						position: "absolute",
						width: 80,
						height: 85,
						backgroundColor: Colors.white,
						left: end1Position.bgLeft - 8,
						top: end1Position.bgTop,
						transform: [{ rotate: end1Position.rotate }],
					}}
				/>
			)}

			{end2Path && (
				<View
					style={{
						position: "absolute",
						width: 85,
						height: 85,
						backgroundColor: Colors.white,
						left: end2Position.bgLeft,
						top: end2Position.bgTop,
						transform: [{ rotate: end2Position.rotate }],
					}}
				/>
			)}

			{end3Path && (
				<View
					style={{
						position: "absolute",
						width: 85,
						height: 85,
						backgroundColor: Colors.white,
						left: end3Position.bgLeft,
						top: end3Position.bgTop,
						transform: [{ rotate: end3Position.rotate }],
					}}
				/>
			)}

			{end4Path && (
				<View
					style={{
						position: "absolute",
						width: 85,
						height: 85,
						backgroundColor: Colors.white,
						left: end4Position.bgLeft,
						top: end4Position.bgTop,
						transform: [{ rotate: end4Position.rotate }],
					}}
				/>
			)}

			{end1Label.left > 0 && (
				<Text
					style={{
						position: "absolute",
						left: end1Label.left,
						top: end1Label.top,
						fontSize: 11,
						fontWeight: "bold",
						borderWidth: 1,
						paddingHorizontal: 10,
						paddingVertical: 3,
						backgroundColor: Colors.white,
					}}
				>
					End 1
				</Text>
			)}

			{end2Label.left > 0 && (
				<Text
					style={{
						position: "absolute",
						left: end2Label.left,
						top: end2Label.top,
						fontSize: 11,
						fontWeight: "bold",
						borderWidth: 1,
						paddingHorizontal: 10,
						paddingVertical: 3,
						backgroundColor: Colors.white,
					}}
				>
					End 2
				</Text>
			)}

			{end3Label.left > 0 && (
				<Text
					style={{
						position: "absolute",
						left: end3Label.left,
						top: end3Label.top,
						fontSize: 11,
						fontWeight: "bold",
						borderWidth: 1,
						paddingHorizontal: 10,
						paddingVertical: 3,
						backgroundColor: Colors.white,
					}}
				>
					End 3
				</Text>
			)}

			{end4Label.left > 0 && (
				<Text
					style={{
						position: "absolute",
						left: end4Label.left,
						top: end4Label.top,
						fontSize: 11,
						fontWeight: "bold",
						borderWidth: 1,
						paddingHorizontal: 10,
						paddingVertical: 3,
						backgroundColor: Colors.white,
					}}
				>
					End 4
				</Text>
			)}
			{end1Path && (
				<Image
					source={{ uri: end1URI }}
					style={{
						position: "absolute",
						width: 100,
						height: 100,
						left: end1Position.left - xOffset,
						top: end1Position.top,
						transform: [{ rotate: end1Position.rotate }, { scale: end1Position.scale }],
					}}
					resizeMode="contain"
				/>
			)}

			{end2Path && (
				<Image
					source={{ uri: end2URI }}
					style={{
						position: "absolute",
						width: 100,
						height: 100,
						left: end2Position.left - xOffset -6,
						top: end2Position.top,
						transform: [{ rotate: end2Position.rotate }, { scale: end2Position.scale }],
					}}
					resizeMode="contain"
				/>
			)}

			{end3Path && (
				<Image
					source={{ uri: end3URI }}
					style={{
						position: "absolute",
						width: 100,
						height: 100,
						left: end3Position.left - xOffset -6,
						top: end3Position.top -3,
						transform: [{ rotate: end3Position.rotate }, { scale: end3Position.scale }],
					}}
					resizeMode="contain"
				/>
			)}

			{end4Path && (
				<Image
					source={{ uri: end4URI }}
					style={{
						position: "absolute",
						width: 100,
						height: 100,
						left: end4Position.left - xOffset - 4,
						top: end4Position.top + 3,
						transform: [{ rotate: end4Position.rotate }, { scale: end4Position.scale }],
					}}
					resizeMode="contain"
				/>
			)}
		</>
	)
}

export default FittingImage
