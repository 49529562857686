import React, { useRef } from "react"
import { Text, StyleSheet, View, Dimensions, Platform } from "react-native"
import * as Animatable from "react-native-animatable"
import Colors from "../utils/Colors"
import Button from "./Button"

const screenWidth = Dimensions.get("window").width
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 400) : Dimensions.get("window").width

const Popup = (props) => {
	const refBackground = useRef(null)
	const refContainer = useRef(null)

	return (
		<Animatable.View
			style={styles.background}
			useNativeDriver={true}
			animation="fadeIn"
			ref={refBackground}
			duration={300}
		>
			<View style={props.secondButtonTitle?styles.wrapper2:styles.wrapper}>
				<Animatable.View
					style={styles.container}
					useNativeDriver={true}
					animation="fadeInUpBig"
					ref={refContainer}
					duration={300}
				>
					<Text style={styles.title}>{props.title}</Text>
					<Text style={styles.body}>{props.body}</Text>
					<Button
						title={props.closeButtonTitle ? props.closeButtonTitle : "OK"}
						theme="yellow"
						onPress={() => {
							refBackground.current.fadeOut()
							refContainer.current.fadeOutDownBig().then(() => {
								props.closeAction()
							})
						}}
					/>
					{props.secondButtonTitle && (
						<Button
							style={{ marginTop: 20 }}
							title={props.secondButtonTitle}
							onPress={() => {
								refBackground.current.fadeOut()
								refContainer.current.fadeOutDownBig().then(() => {
									props.secondAction()
								})
							}}
						/>
					)}
					{props.cancelButtonTitle && (
						<Button
							style={{ marginTop: 20 }}
							title={props.cancelButtonTitle}
							buttonType="outline"
							onPress={() => {
								refBackground.current.fadeOut()
								refContainer.current.fadeOutDownBig().then(() => {
									props.cancelAction()
								})
							}}
						/>
					)}
				</Animatable.View>
			</View>
		</Animatable.View>
	)
}

export default Popup

const styles = StyleSheet.create({
	background: {
		backgroundColor: Colors.semiTransparent,
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		
	},
	wrapper: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},wrapper2: {
		//flex: 2,
		justifyContent: "center",
		alignItems: "center",
	},
	container: {
		backgroundColor: Colors.white,
		borderRadius: 10,
		margin: 20,
		padding: 30,
		width: screenWidth - 40,
		maxWidth,
	},
	title: {
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: 28,
		textAlign: "center",
	},
	body: {
		fontSize: 16,
		lineHeight: 24,
		textAlign: "center",
		marginVertical: 20,
	},
})
