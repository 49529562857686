// import { initializeApp } from "firebase/app"

// // Optionally import the services that you want to use
// //import {...} from "firebase/auth";
// //import {...} from "firebase/database";
// //import {...} from "firebase/firestore";
// //import {...} from "firebase/functions";
// //import {...} from "firebase/storage";

// // Initialize Firebase
// const firebaseConfig = {
// 	apiKey: "AIzaSyB09L6a7vQTVmHEQRo3v03iTLQ-NzkPK_0",
// 	authDomain: "parker-fitting-finder.firebaseapp.com",
// 	projectId: "parker-fitting-finder",
// 	storageBucket: "parker-fitting-finder.appspot.com",
// 	messagingSenderId: "303019755217",
// 	appId: "1:303019755217:web:6ac215ab9d348c2a15e7f0",
// 	measurementId: "G-K5JD0X9L00",
// }

// initializeApp(firebaseConfig)

// Sentry.init({
// 	dsn: "https://ab9351a8acfe40ce8c85961beb1b572c@o197293.ingest.sentry.io/6368851",
// 	enableInExpoDevelopment: true,
// 	debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
// })
// import * as Analytics from "expo-firebase-analytics"
//import * as Sentry from "sentry-expo"

//import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { StatusBar } from "expo-status-bar"
import React, { useRef } from "react"
import { StyleSheet, Text, View, Image, Pressable, Platform, Linking, Dimensions } from "react-native"
import Colors from "./utils/Colors"
import { NavigationContainer } from "@react-navigation/native"

import { createStackNavigator } from "@react-navigation/stack"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"



import { DefaultTheme, Provider as PaperProvider } from "react-native-paper"
import { authStore } from "./utils/AuthStore"
import { observer } from "mobx-react-lite"


import Launch from "./screens/Launch"
import Onboarding from "./screens/Onboarding"
import FittingSelection from "./screens/FittingSelection"
import FittingOverview from "./screens/FittingOverview"
import FittingDetails from "./screens/FittingDetails"
import FittingSearch from "./screens/FittingSearch"
import SelectCompetitor from "./screens/SelectCompetitor"
import ToolIntro from "./screens/ThreadID/ToolIntro"
import Tool1 from "./screens/ThreadID/Tool1"
import Tool2 from "./screens/ThreadID/Tool2"
import Tool3 from "./screens/ThreadID/Tool3"
import WhereToBuy from "./screens/WhereToBuy"
import DistributorDetails from "./screens/DistributorDetails"
import Account from "./screens/Account"
import Settings from "./screens/Settings"
import Contact from "./screens/Contact"
import SavedProducts from "./screens/SavedProducts"
import Privacy from "./screens/Privacy"
import Terms from "./screens/Terms"
import Safety from "./screens/Safety"
import Login from "./screens/Login"
import CreateAccount from "./screens/CreateAccount"
import ForgotPassword from "./screens/ForgotPassword"
import ResetPassword from "./screens/ResetPassword"
import About from "./screens/About"
import OktaAuth from "./components/OktaAuth"
import { analyticsStore } from "./utils/AnalyticsStore"
import GTMView from "./components/GTMView"
import CustomTabBar from "./components/CustomTabBar"
import WebNavBar from "./components/WebNavBar"
import WebHeader from "./components/WebHeader"
import CallbackScreen from './screens/OktaCallbackScreen'
import Resources from './screens/Resources'
import WebFooter from "./components/WebFooter"
import { LogBox } from "react-native"
LogBox?.ignoreAllLogs() //Ignore all log notifications
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 850) : Dimensions.get("window").width
console.log('maxWidth')
const shouldRenderCustomTabBar = (Platform.OS === 'web' && maxWidth > 600);
export const navigationRef = React.createRef()

const theme = {
	...DefaultTheme,
	colors: {
		...DefaultTheme.colors,
		primary: Colors.darkGrey,
		accent: Colors.black,
	},
}

function App() {
//  React.useLayoutEffect(() => {
// 	navigation.setOptions({
// 		headerRight: () => (
// 		  <Pressable onPress={() => navigation.goBack()}>
// 			<Icon style={{ marginRight: 200 }} name="ios-arrow-back" size={28} color={colors.black} />
// 		  </Pressable>
// 		),
// 	  });
//   }, [navigation]);
	const routeNameRef = useRef()
	const Stack = createStackNavigator()
	let Tab;
	// if(Platform.OS === "web")
	// 	Tab = createMaterialTopTabNavigator();
	// else
	Tab = createBottomTabNavigator()
	analyticsStore.logEvent('app_opened', {});

	const screenOptions = {
		headerStyle: {
			backgroundColor: Colors.backgroundGrey,
			elevation: 0, // remove shadow on Android
			shadowOpacity: 0, // remove shadow on iOS

		},
		headerLeftContainerStyle:{marginLeft:200},
		headerTintColor: Colors.black,
		headerTitleStyle: {
			fontWeight: "bold",
			fontSize: 22,
		},
		//headerBackVisible:Platform.OS==="web"?false:true,
		...(Platform.OS === "web" ? { headerLeft: () => null } : {}),
		headerBackTitleVisible: false,
		headerTitleAlign: "center",

		//header: (props) => <WebHeader {...props} />,
		//headerShown:Platform.OS==="web"?false:true

	}

	const tabOptions = ({ route }) => ({
		tabBarIcon: ({ focused, color, size }) => {
			let iconName

			if (route.name === "FinderStack") {
				iconName = focused ? require("./assets/icn_part_on.png") : require("./assets/icn_part.png")
				return <Image style={{ height: 39, width: 28 }} source={iconName} />
			} else if (route.name === "SearchStack") {
				iconName = focused ? require("./assets/icn_search_nav_on.png") : require("./assets/icn_search_nav.png")
				return <Image style={{ height: 34, width: 34 }} source={iconName} />
			} else if (route.name === "WhereToBuyStack") {
				iconName = focused ? require("./assets/icn_pin_on.png") : require("./assets/icn_pin.png")
				return <Image style={{ height: 34, width: 26 }} source={iconName} />
			} else if (route.name === "AccountStack") {
				iconName = focused ? require("./assets/icn_user_on.png") : require("./assets/icn_user.png")
				return <Image style={{ height: 34, width: 30 }} source={iconName} />
			} else if (route.name === "ResourceStack") {
				iconName = focused ? require("./assets/icn_wrench_on.png") : require("./assets/icn_wrench.png")
				return <Image style={{ height: 34, width: 30 }} source={iconName} />
			}

			// You can return any component that you like here!
			return <Image style={{ height: 39, width: 28 }} source={iconName} />
		},
		headerBackVisible:Platform.OS==="web"?false:true,
		headerShown: false,
		tabBarActiveTintColor: Colors.yellow,
		tabBarInactiveTintColor: Colors.white,
		tabBarStyle: { backgroundColor: Colors.black, height: 90 },
	})

	const SearchStack = () => (
		<Stack.Navigator screenOptions={screenOptions}>
			<Stack.Screen name="FittingSearch" component={FittingSearch} options={{ title: "Fitting Search" }} />
			<Stack.Screen name="FittingDetails" component={FittingDetails} options={{ title: "Part Details" }} />
			<Stack.Screen
				name="SelectCompetitor"
				component={SelectCompetitor}
				options={{ title: "Competitive Interchange" }}
			/>
			<Stack.Screen name="CreateAccount" component={CreateAccount} options={{ title: "Create Account" }} />
		</Stack.Navigator>
	)
	const FinderStack = () => (
		<Stack.Navigator screenOptions={screenOptions}>
			<Stack.Screen name="FittingSelection" component={FittingSelection} />
			<Stack.Screen
				name="FittingOverview"
				component={FittingOverview}
				options={{ title: "Select Ends, Types, & Sizes" }}
			/>
			<Stack.Screen name="FittingDetails" component={FittingDetails} options={{ title: "Part Details" }} />
			<Stack.Screen name="FittingSearch" component={FittingSearch} options={{ title: "Fitting Search" }} />
			<Stack.Screen
				name="SelectCompetitor"
				component={SelectCompetitor}
				options={{ title: "Competitive Interchange" }}
			/>
			<Stack.Screen name="CreateAccount" component={CreateAccount} options={{ title: "Create Account" }} />
			<Stack.Screen name="Callback" component={CallbackScreen} options={{ title: "Login" }} />
		</Stack.Navigator>
	)

	const WhereToBuyStack = () => (
		<Stack.Navigator screenOptions={screenOptions}>
			<Stack.Screen name="Where to Buy" component={WhereToBuy} />
			<Stack.Screen
				name="DistributorDetails"
				component={DistributorDetails}
				options={{ title: "Distributor Details" }}
			/>
		</Stack.Navigator>
	)

	const AccountStack = () => (
		<Stack.Navigator screenOptions={screenOptions}>
			<Stack.Screen name="Account" component={Account} options={{ title: "My Account" }} />
			<Stack.Screen name="Settings" component={Settings} />
			<Stack.Screen name="SavedProducts" component={SavedProducts} options={{ title: "Saved Products" }} />
			{/* <Stack.Screen name="FittingDetails" component={FittingDetails} options={{ title: "Part Details" }} /> */}
			<Stack.Screen name="Privacy" component={Privacy} />
			<Stack.Screen name="Terms" component={Terms} options={{ title: "T & C" }} />
			<Stack.Screen name="Safety" component={Safety} options={{ title: "Safety Guide" }} />
			<Stack.Screen name="CreateAccount" component={CreateAccount} options={{ title: "Create Account" }} />
			<Stack.Screen name="Login" component={Login} />
			<Stack.Screen name="ForgotPassword" component={ForgotPassword} options={{ title: "Forgot Password" }} />
			<Stack.Screen name="ResetPassword" component={ResetPassword} options={{ title: "Reset Password" }} />
			<Stack.Screen name="FittingDetails" component={FittingDetails} options={{ title: "Part Details" }} />

		</Stack.Navigator>
	)
	const ResourceStack = () => (
		<Stack.Navigator screenOptions={screenOptions}>
			<Stack.Screen name="Resources" component={Resources} options={{ title: "Resources" }} />
			<Stack.Screen name="Contact" component={Contact} />
			<Stack.Screen name="About" component={About} options={{ title: "About" }} />
		</Stack.Navigator>
	)

	const TabStack = () => (
		<Tab.Navigator
			screenOptions={tabOptions}
			tabBar={shouldRenderCustomTabBar ? (props) => <CustomTabBar {...props} /> : undefined}

			tabBarOptions={{
				keyboardHidesTabBar: true,

				style: {
					// Use CSS to move the tab bar to the top
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					// Add additional styling as needed
					backgroundColor: 'lightgray', // Example background color
					borderBottom: '1px solid gray', // Example border
				}
			}}

		>

			<Tab.Screen name="FinderStack" component={FinderStack} options={({ navigation }) => ({ 
    title: "Build Fitting", 
  })}  />
			{/* <Tab.Screen name="SearchStack" component={SearchStack} options={{ title: "Search" }} /> */}
			<Tab.Screen name="WhereToBuyStack" component={WhereToBuyStack} options={{ title: "Where to Buy" }} />
			<Tab.Screen name="ResourceStack" component={ResourceStack} options={{ title: "Resources" }} />
			<Tab.Screen name="AccountStack" component={AccountStack} options={{ title: "Account" }} />
		</Tab.Navigator>
	)
	const CustomHeader = () => (
		<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 16 }}>
			<Image source={require("./assets/intro-logo.png")} style={{ width: 100, height: 100 }} />
		</View>
	);


	const ThreadIDStack = () => (
		<Stack.Navigator screenOptions={screenOptions}>
			<Stack.Screen name="ToolIntro" component={ToolIntro} options={{ title: "Thread ID Tool" }} />
			<Stack.Screen name="Tool1" component={Tool1} options={{ title: "Thread ID Tool" }} />
			<Stack.Screen name="Tool2" component={Tool2} options={{ title: "Thread ID Tool" }} />
			<Stack.Screen name="Tool3" component={Tool3} options={{ title: "Thread ID Tool" }} />
		</Stack.Navigator>
	)

	const OnboardingStack = () => (
		<Stack.Navigator screenOptions={screenOptions}>
			<Stack.Screen name="Onboarding" component={Onboarding} />
		</Stack.Navigator>
	)

	const linking = {
		config: {
			screens: {
				TabStack: {
					screens: {
						FinderStack: {
							screens: {
								FittingSelection: "FittingSelection",
								FittingDetails: "part/:partName",
								Callback: "login/callback",
								FittingSearch: "FittingSearch",

							},
						},
						WhereToBuyStack: {
							screens: {
								WhereToBuyStack: 'Where%20to%20Buy',

							},
						},
						AccountStack: {
							screens: {
								Account: "Account",
								CreateAccount: "CreateAccount",
								Settings: "Settings",
								SavedProducts: "SavedProducts"
							}
						},
						ResourceStack: {
							screens: {
								Resources: 'Resources',

							},
						},
					},
				},
			},
		},
	}

	return (
		<PaperProvider theme={theme}>
			<GTMView />
			{shouldRenderCustomTabBar && (
				<>
					<WebNavBar />
					 {/* <WebHeader navigation={navigation}/>  */}
				</>
			)}
			{(!shouldRenderCustomTabBar && Platform.OS === "web") && (
				<View style={styles.logoContainer}>
					<Image style={styles.logo} resizeMode="contain" source={require("./assets/logo-black.png")} />
				</View>
			)}

			<StatusBar style="dark" />
			<NavigationContainer
				linking={linking}
				ref={navigationRef}
				onReady={() => {
					routeNameRef.current = navigationRef.current?.getCurrentRoute().name
				}}
				onStateChange={async () => {
					const previousRouteName = routeNameRef.current
					const currentRouteName = navigationRef.current?.getCurrentRoute().name
					if (previousRouteName !== currentRouteName) {
						//Analytics.setCurrentScreen(currentRouteName)
					}
					routeNameRef.current = currentRouteName
				}}>

				<Stack.Navigator>
					<Stack.Screen name="Launch" component={Launch} options={{ headerShown: false }} />
					<Stack.Screen name="Onboarding" component={Onboarding} options={{ headerShown: false }} />
					<Stack.Screen name="TabStack" component={TabStack} options={{ 
						headerShown: false,
						headerLeft: ({ navigation }) => (
						<Pressable onPress={() => navigation.goBack()}>
							<Icon style={{ marginLeft: 200 }} name="ios-arrow-back" size={28} color="black" />
						</Pressable>
						),
					}} />
					<Stack.Screen name="ThreadIDStack" component={ThreadIDStack} options={{ headerShown: false }} />
				</Stack.Navigator>
			</NavigationContainer>
			{Platform.OS !== "web" && (
				<OktaAuth></OktaAuth>
			)}
			{Platform.OS === "web" && (
				<WebFooter></WebFooter>
			)}

		</PaperProvider>

	)
}

export default observer(App)

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		alignItems: "center",
		justifyContent: "center"
	},
	logoContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		height: 75,
		backgroundColor: 'black',
		width: '100%',

		justifyContent: 'center', // Horizontally center its children
		alignItems: 'center',
	},
	logo: {
		alignSelf: "center",
		width: 100,
		height: 43,
	}, navItem: {
		color: 'white',
		fontSize: 16,
	},
})
