import React, { useState, useEffect, useRef } from "react"
import { StyleSheet, Text, View, Platform, Dimensions, Keyboard, Pressable, Linking } from "react-native"
import { Switch } from "react-native-paper"
import { Button } from "../components/Index"
import { authStore } from "../utils/AuthStore"
import { signIn, signOut, isAuthenticated } from '@okta/okta-react-native';
import { observer } from "mobx-react-lite"
import colors from "../utils/Colors"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { analyticsStore } from "../utils/AnalyticsStore"
import { navStore } from "../utils/NavStore"
import { partStore } from "../utils/PartStore"
import oktaAuth from '../auth.config';
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const maxButtonWidth =
	Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 400) : Dimensions.get("window").width

const CreateAccount = ({ navigation, route }) => {
	navStore.navigation = navigation
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [alertMsg, setAlertMsg] = useState(null)

	const [allowEmailUpdates, setEmailUpdates] = useState(false)
	const [allowAppNotifications, setNotifications] = useState(false)

	const [agreeToTerms, setAgreeToTerms] = useState(false)
	const [faceID, setFaceID] = useState(false)
	React.useLayoutEffect(() => {
		if (route.params?.showCancel)
			navigation.setOptions({
				headerRight: () => <Button buttonType="clear" onPress={() => navigation.replace("Account")} title="Cancel" />,
			})
	}, [navigation])

	useEffect(() => {
		analyticsStore.logEvent("sign-up_form", {})
		// Analytics.logEvent("sign-up_form")
	}, [])
	useEffect(() => {

		if (authStore.authenticated === true) {
			if (route.params?.goBack) {
				navigation.replace("FittingDetails", { fittingScreen: partStore.nextScreen['material'] })
			} else {
				navigation.popToTop()
				navigation.replace("Account", { accountCreated: true })
			}
			analyticsStore.logEvent("sign_up", {})
		}

		// Analytics.logEvent("sign-up_form")
	}, [authStore.authenticated])
	const submit = async () => {
		
		if (!agreeToTerms) {
			setAlertMsg("Agreement to the Terms and Conditions is required.")
			return
		} else setAlertMsg(null)


		//AsyncStorage.setItem("faceID", JSON.stringify({ faceID }))
		setAlertMsg(null)
		authStore.isCreateAccount = true
		authStore.allowAppNotifications = allowAppNotifications
		authStore.allowEmailUpdates = allowEmailUpdates
		if(Platform.OS === "web"){
			await AsyncStorage.setItem("createAccount", true)
			oktaAuth.oktaAuth.signInWithRedirect();
		}else{
			authStore.faceId = faceID
			await signIn()
		}
		
		// authStore.createAccount({ email, password, allowEmailUpdates, allowAppNotifications }).then((res) => {
		// 	if (!res.ok) {
		// 		setAlertMsg(res.data.errorMessage)

		// 	} else {
		// 		AsyncStorage.setItem("faceID", JSON.stringify({ faceID }))

		// 		if (route.params?.goBack) {
		// 			navigation.goBack()
		// 			return
		// 		} else {

		// 			navigation.popToTop()
		// 			navigation.replace("Account", { accountCreated: true })
		// 		}
		// 		// Analytics.logEvent("sign_up")
		// 	}
		// })
	}
	const handlePress = () => {
		if (Platform.OS !== 'web') {
			Keyboard.dismiss();
		}
	}
	const onPressProp = Platform.OS !== 'web' ? handlePress : undefined;
	return (
		<Pressable onPress={onPressProp} style={styles.mainContainer}>
			{alertMsg && <Text style={styles.alertMsg}>{alertMsg}</Text>}
			{/* <TextInput
				label="Email"
				value={email}
				onChangeText={(text) => setEmail(text.toLowerCase().trim())}
				textContentType="email"
				autoCompleteType="email"
				autoCapitalize="none"
				style={styles.textField}
			/>
			<TextInput
				label="Password"
				value={password}
				onChangeText={(text) => setPassword(text.trim())}
				secureTextEntry
				textContentType="password"
				autoCompleteType="password"
				onSubmitEditing={submit}
				clearTextOnFocus
				blurOnSubmit
				style={[styles.textField, { marginBottom: 30 }]}
			/> */}

			<View style={{ flex: 1 }}>
				{Platform.OS !== "web" && (
					<View style={styles.preferencesRow1}>
						<Text>{Platform.OS === "iOs"?"Use Face ID":"Use Face Unlock or Fingerprint"}</Text>
						<Switch value={faceID} onValueChange={() => setFaceID(!faceID)} />
					</View>
				)}

				<Text style={styles.preferencesText}>Would you like to receive new product updates from Parker?</Text>
				<View style={styles.preferencesRow}>
					<Text>Email Updates</Text>
					<Switch trackColor={{ false: colors.darkGrey, true: colors.grey }}
						thumbColor={allowEmailUpdates ? colors.darkGrey : 'white'}
						value={allowEmailUpdates} onValueChange={() => setEmailUpdates(!allowEmailUpdates)} />
				</View>
				{Platform.OS !== "web" &&(
					<View style={styles.preferencesRow}>
					<Text>Mobile Notifications</Text>
					<Switch trackColor={{ false: colors.darkGrey, true: colors.grey }}
						thumbColor={allowAppNotifications ? colors.darkGrey : 'white'}
						 value={allowAppNotifications} onValueChange={() => setNotifications(!allowAppNotifications)} />
				</View>
				)}
				

				<View style={styles.preferencesRow}>
					<Text>
						I agree to Parker’s{" "}
						<Text onPress={() => (Platform.OS === "web" ? Linking.openURL(
							"https://www.parker.com/us/en/terms-and-conditions.html"
						) : navigation.navigate("Terms"))} style={{ textDecorationLine: "underline" }}>
							Terms and {"\n"}Conditions
						</Text>{" "}
						and{" "}
						<Text onPress={() => (Platform.OS === "web" ? Linking.openURL(
							"https://www.parker.com/us/en/privacy-policies.html"
						) : navigation.navigate("Privacy"))} style={{ textDecorationLine: "underline" }}>
							Privacy Policy
						</Text>
						.
					</Text>
					<Switch
						trackColor={{ false: colors.darkGrey, true: colors.grey }}
						thumbColor={agreeToTerms ? colors.darkGrey : 'white'}
						value={agreeToTerms}
						onValueChange={() => {
							console.log("Setting ", !agreeToTerms)
							//setAgreeToTermsChanged(true)
							//setDisabled(!email || !password || val === false)
							setAgreeToTerms(!agreeToTerms)
						}}
					/>
				</View>
			</View>


			{/* <CreateAccountButton isCreateAccount={true} route={route} allowAppNotifications={allowAppNotifications} faceID={faceID} allowEmailUpdates={allowEmailUpdates} agreeToTerms={agreeToTerms} maxButtonWidth={maxButtonWidth} setAlertMsg={setAlertMsg}  /> */}
			<Button
				style={{ width: maxButtonWidth, alignSelf: "center", marginTop: 25 }}
				title="Create Account"
				theme="yellow"
				//disabled={disabled}
				onPress={() => submit()}
			/>
		</Pressable>
	)
}

export default observer(CreateAccount)

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: Platform.OS === "web" ? 0 : 1,
		paddingHorizontal: 30,
		marginTop: 20,
		marginBottom: 5,
		marginVertical: 20,
		backgroundColor: Platform.OS !== "web" ? colors.backgroundGrey : colors.transparent,
		width: maxWidth,
		alignSelf: "center",
	},
	alertMsg: {
		fontWeight: "bold",
		marginBottom: 10,
		color: colors.red,
	},
	textField: {
		backgroundColor: colors.backgroundGrey,
		fontWeight: "bold",
		marginBottom: 20,
	},
	preferencesText: {
		fontWeight: "bold",
	},
	preferencesRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottomColor: colors.mediumGrey,
		borderBottomWidth: 1,
		paddingVertical: 10,
	},
	preferencesRow1: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottomColor: colors.mediumGrey,
		borderBottomWidth: 1,
		paddingVertical: 10,
		marginBottom: 20
	},
})
