import React from "react"
import { StyleSheet, Text, ScrollView } from "react-native"
import { useWindowDimensions } from "react-native"
import RenderHtml from "react-native-render-html"
import { WebView } from "react-native-webview"
import { navStore } from "../utils/NavStore"
const Terms = ({ navigation }) => {
	navStore.navigation = navigation
	const { width } = useWindowDimensions()
	// const file = require("../assets/terms.pdf")
	return (
		<WebView style={styles.mainContainer} source={{ uri: "https://www.parker.com/us/en/terms-and-conditions.html" }} />
	)
}

export default Terms

const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
	},
})
