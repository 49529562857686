import React, { useEffect, useState } from "react"
import { View, StyleSheet, Text, ActivityIndicator, Image, Platform } from "react-native"
import Colors from "../utils/Colors"
import { observer } from "mobx-react-lite"
import { Button } from "../components/Index"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { SafeAreaView } from "react-native-safe-area-context"
import moment from "moment"
import { API } from "../utils/API"
import { authStore } from "../utils/AuthStore"
import { navStore } from "../utils/NavStore"
import { refreshTokens } from '@okta/okta-react-native';
import jwtDecode from 'jwt-decode';

const Launch = (props) => {
	navStore.navigation = props.navigation
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		async function checkAuth() {
			
			let tokenStore = await AsyncStorage.getItem("tokenObject")
			let onboarded =  await AsyncStorage.getItem("onboarded")
			console.log("onboarded", onboarded)
			if (!onboarded && Platform.OS !== 'web') props.navigation.replace("Onboarding")
			else {
				if (tokenStore) {
					console.log('hello tokenstore')
					let tokenObject = JSON.parse(tokenStore)

					const decodedToken = jwtDecode(tokenObject.access_token);
					const expirationTimeInSeconds = decodedToken.exp;

					// Convert expiration time to milliseconds
					const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000;

					// Create a Date object
					const expirationDate = new Date(expirationTimeInMilliseconds);
					if (moment().isAfter(moment(expirationDate))) {
						let _token = await refreshTokens();

						authStore.signInSuccess(-1, '', _token)
						props.navigation.replace("TabStack")
						
					} else {
						authStore.setToken(tokenObject.access_token)
						props.navigation.replace("TabStack")
					}
				} else {

					props.navigation.replace("TabStack")
				}
			}
		}
		checkAuth()
	}, [])

	return (
		<View style={styles.launchContainer}>
			{loading && <ActivityIndicator style={styles.spinner} size="small" color={Colors.yellow} />}

			{!loading && (
				<>
					<Image style={styles.logo} source={require("../assets/intro-logo.png")} />
					<Image style={styles.image} source={require("../assets/intro-save.png")} />
					<Text style={styles.title}>{"Save Your\nConfigurations!"}</Text>
					<Text style={styles.subtitle}>
						Create an account to save multiple part configurations so you can go back to them later to view or edit.
					</Text>
					<View style={{ flex: 1, justifyContent: "flex-end", marginBottom: 20 }}>
						<Button
							title="Create Account"
							theme="yellow"
							onPress={() =>
								props.navigation.replace("TabStack", {
									screen: "AccountStack",
									params: { screen: "CreateAccount", params: { showCancel: true } },
								})
							}
						/>
						<Button title="Continue As Guest" buttonType="clear" onPress={() => props.navigation.replace("TabStack")} />
					</View>
				</>
			)}
		</View>
	)
}

export default observer(Launch)

const styles = StyleSheet.create({
	launchContainer: {
		flex: 1,
		backgroundColor: Colors.backgroundGrey,
		width: "100%",
		height: "100%",
		padding: 30,
	},
	spinner: {
		marginTop: 40,
		marginBottom: 60,
	},
	logo: {
		alignSelf: "center",
		marginTop: 10,
	},
	image: {
		alignSelf: "center",
		marginTop: 50,
		marginLeft: 25,
		marginBottom: 20,
	},
	title: {
		fontWeight: "bold",
		fontSize: 28,
		textAlign: "center",
		lineHeight: 32,
	},
	subtitle: {
		fontWeight: "500",
		fontSize: 18,
		textAlign: "center",
		lineHeight: 28,
		marginTop: 20,
		paddingHorizontal: 20,
	},
})
