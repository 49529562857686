import React, { Component, Fragment } from 'react';
import { SafeAreaView,StyleSheet,Text,View, Platform,Dimensions} from 'react-native';
import { Button } from "./Index"
import {createConfig,signIn,signOut,isAuthenticated,getUser,getUserFromIdToken,  getAccessToken,EventEmitter, refreshTokens} from '@okta/okta-react-native';
import { API } from "../utils/API"
import configFile from '../auth.config';
import { authStore } from '../utils/AuthStore';
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const maxButtonWidth =Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 400) : Dimensions.get("window").width
export default class OktaAuth extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      context: null,
      newAccount: null
    };
    this.checkAuthentication = this.checkAuthentication.bind(this);
  }
  async componentDidDisappear(){
    console.log('disappeared')
  }
  async componentDidMount() {
    console.log('okta1 mount')
    await createConfig({
      clientId: configFile.oidc.clientId,
      redirectUri: configFile.oidc.redirectUri,
      endSessionRedirectUri: configFile.oidc.endSessionRedirectUri,
      discoveryUri: configFile.oidc.discoveryUri,
      scopes: configFile.oidc.scopes,
      requireHardwareBackedKeyStore:
        configFile.oidc.requireHardwareBackedKeyStore,
    });

    let that = this;
    EventEmitter.addListener('signInSuccess', async function (e: Event) {
      console.log('signinSuccess:' + authStore.isCreateAccount)
      let user = await getUserFromIdToken();
      let token = await getAccessToken();
      console.log(token)
      if(authStore.isCreateAccount === true){
        console.log('signinSuccess:' + authStore.isCreateAccount)
        await authStore.signInSuccessCreateAccount('', '', token,{ email:'', password:'', allowEmailUpdates:authStore.allowEmailUpdates, allowAppNotifications:authStore.allowAppNotifications })
        .then(async (res) => {
          if (!res.ok) {
            console.log(`auth error: ${res.originalError}`)
            //setAlertMsg(res.data.errorMessage)
    
          } else {
            
            // let faceID = authStore.faceId
            // console.log(`success:${faceID}`)
            // await AsyncStorage.setItem("faceID", JSON.stringify({ faceID }))
    
            // if (route.params?.goBack) {
            //   navigation.goBack()
            //   return
            // } else {
    
              //navigation.popToTop()
              //navigation.replace("Account", { accountCreated: true })
            // }
            // Analytics.logEvent("sign_up")
          }
        })
      }else{
        var t = await refreshTokens();
         authStore.signInSuccess(user.sub, user.preferred_username, token)
      }
      
      //console.log(user.sub)
      that.setState({ authenticated: true });
      that.setContext('Logged in!');
    });
    EventEmitter.addListener('signOutSuccess', function (e: Event) {
      
      authStore.logout();
      console.log('signout success')
      that.setState({ authenticated: false });
      that.setContext('Logged out!');
    });
    EventEmitter.addListener('onError', function (e: Event) {
      console.log('logout error')
      if(e.resolve_type !== 'cancelled')
        authStore.logout()
      console.warn(e);
      that.setContext(e.error_message);
    });
    EventEmitter.addListener('onCancelled', function (e: Event) {
      console.warn(e);
    });

    this.checkAuthentication();
  }
  
  
  componentWillUnmount() {
    console.log('unmount')
    EventEmitter.removeAllListeners('signInSuccess');
    EventEmitter.removeAllListeners('signOutSuccess');
    EventEmitter.removeAllListeners('onError');
    EventEmitter.removeAllListeners('onCancelled');
  }

  async componentDidUpdate() {
    await this.checkAuthentication();
  }

  async checkAuthentication() {
    const result = await isAuthenticated();
    if (result.authenticated !== this.state.authenticated) {
      API.setToken(await this.getAccessToken())
      console.log('authed')
      this.setState({ authenticated: result.authenticated });
    }
  }

  async login() {
    await signIn();
  }
  async createAccount() {
    if (this.props.agreeToTerms === true) {
      await signIn();
    }
    else {
      this.props.setAlertMsg('Agreement to the Terms and Conditions are required.')
    }
  }
  async logout() {
    await signOut();
  }

  async getUserIdToken() {
    let user = await getUserFromIdToken();
    this.setContext(JSON.stringify(user, null, 2));
  }

  async getMyUser() {
    let user = await getUser();
    this.setContext(JSON.stringify(user, null, 2));
  }

  setContext = message => {
    this.setState({
      context: message,
    });
  };
  navigateToCreateAccount(){
    let nav = this.props.navigation;
    nav.replace("CreateAccount")
  }
  // renderButtons() {
  //   if (this.state.authenticated) {
  //     return (
  //       <View style={styles.buttonContainer}>
  //         <View style={styles.button}>
  //           <Button
  //             onPress={async () => {
  //               await this.getUserIdToken();
  //             }}
  //             title="Get User From Id Token"
  //           />
  //         </View>
  //         <View style={styles.button}>
  //           <Button
  //             onPress={async () => {
  //               await this.getMyUser();
  //             }}
  //             title="Get User From Request"
  //           />
  //         </View>
  //       </View>
  //     );
  //   }
  // }

  render() {
    return (

      <Fragment>
        {/* <SafeAreaView style={styles.container}>
          {this.props.isCreateAccount === true && (

            <Button title="Create Account" style={{ alignSelf: "center", width: '100%', maxWidth: this.props.maxButtonWidth }} theme="yellow" onPress={() => { this.createAccount() }} />



          )}
          {!authStore.authenticated && this.props.isCreateAccount === false && (
            <View style={{ alignSelf: "center", maxWidth: maxButtonWidth }}>
              <Text style={styles.info}>
                You are currently using the app as a guest. If you would like to save parts, you will need to create an
                account.
              </Text>
              <Button title="Create Account" theme="yellow" onPress={() => this.navigateToCreateAccount()} />
              <Button title="Log In" style={{ marginTop: 20 }} onPress={() => this.login()} />

            </View>

          )}
          {authStore.authenticated && (
            <Button
              title="Log Out"
              style={{ marginTop: 20 }}
              onPress={() => {
                this.logout()
              }}
            />
          )}

        </SafeAreaView> */}
      </Fragment>
    );
  }
}
