import { toJS } from "mobx"
import React, { useEffect, useState, useRef } from "react"
import {
	StyleSheet,
	View,
	Pressable,
	TextInput,
	Image,
	ScrollView,
	Alert,
	FlatList,
	Platform,
	Dimensions, TouchableOpacity, Linking
} from "react-native"
import { Surface, Text } from "react-native-paper"

import { API, CADAPI } from "../utils/API"
import { partStore } from "../utils/PartStore"
import Colors from "../utils/Colors"
import Octicons from "react-native-vector-icons/Octicons"
import * as Animatable from "react-native-animatable"
import { analyticsStore } from "../utils/AnalyticsStore"
import { authStore } from "../utils/AuthStore"
import { Button, Popup, FittingImage } from "../components/Index"
import * as MailComposer from "expo-mail-composer"
import { navStore } from "../utils/NavStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import * as SecureStore from "expo-secure-store"
import { signIn, signOut } from '@okta/okta-react-native';
import oktaAuth from '../auth.config';
import { API_URL, WEB_URL, IMGSRV_URL } from '../utils/Configuration';
//import Share from "react-native-share"
//import { SafeAreaView } from "react-native-safe-area-context"
//import Icon from "react-native-vector-icons/Feather"
//import { useFocusEffect } from '@react-navigation/native';
//import renderHTML from 'react-native-render-html';
//import * as LocalAuthentication from "expo-local-authentication"
let Share;
if (Platform.OS === 'web') {
	// Share is not available on the web, so you can define a fallback behavior here.
	Share = {
		share: () => {
			console.warn('Sharing is not supported on the web.');
		},
	};
} else {
	// Import Share from "react-native-share" for mobile platforms.
	Share = require('react-native-share').default;
}
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const FittingDetails = ({ navigation, route }) => {
	navStore.navigation = navigation
	const [parts, setParts] = useState([])
	const [selectedPart, setSelectedPart] = useState(partStore.products[0])
	const [selectedPartTab, setSelectedPartTab] = useState(0)
	const [promptCreateAccount, setPromptCreateAccount] = useState(false)
	const [cadLinks, setCadLinks] = useState([])
	const [favoriteParts, setFavoriteParts] = useState([])
	const [savedPart, setSavedPart] = useState(null)
	const [savedVisible, setSavedVisible] = useState(false)
	const [savedTitle, setSavedTitle] = useState()
	const [savedNotes, setSavedNotes] = useState()
	const refContainer = useRef(null)
	const onShare = async (msg, url) => {
		try {
			const result = await Share.open({
				subject: `Part Specifications - ${selectedPart?.name}`,
				message: msg,
				//url: selectedPart?.isStandard ? `${WEB_URL}/static/img/config/${selectedPart?.image?.path}` : '',
				title: `Part Specifications - ${selectedPart?.name}`,
			});
			if (result.action === Share.sharedAction) {
				console.log('Content shared successfully');
			} else if (result.action === Share.dismissedAction) {
				console.log('Content sharing dismissed');
			}
		} catch (error) {
			console.log(error.message);
		}
	};
	const onCADShare = async (msg, url) => {
		try {
			const result = await Share.open({
				subject: `${selectedPart?.name}  - CAD MODEL`,
				message: msg,
				url: url,
				title: `${selectedPart?.name}  - CAD MODEL`,
			});
			if (result.action === Share.sharedAction) {
				console.log('Content shared successfully');
			} else if (result.action === Share.dismissedAction) {
				console.log('Content sharing dismissed');
			}
		} catch (error) {
			console.log(error.message);
		}
	};
	const checkFavoritePart = (part) => {
		API.getFavoriteParts(authStore.accountInfo.id).then((res) => {
			if (res.ok) {
				const favoritePart = res.data.collection.find((d) => d.name === selectedPart?.name)
				if (favoritePart) {
					setSavedVisible(true)
					setSavedPart(favoritePart)
				}else{
					
				}
				setFavoriteParts(res.data.collection)
			}
		})

	}
	useEffect(() => {
		const selectedPart = partStore.products[selectedPartTab]
		const favoritePart = favoriteParts.find((d) => d.name === selectedPart?.name)

		if (favoritePart) {

			setSavedTitle(favoritePart.name)
			setSavedNotes(favoritePart.description)
			setSavedVisible(true)
			setSavedPart(favoritePart)
		}
		setSelectedPart(partStore.products[selectedPartTab])
	}, [selectedPartTab])

	useEffect(() => {
		if (savedPart) {
			setSavedTitle(savedPart.nickName)
			setSavedNotes(savedPart.description)
		}
	}, [savedPart])

	useEffect(() => {
		if (selectedPart?.cadKey) {
			CADAPI.setAPIKey(selectedPart?.cadKey)
			console.log(selectedPart?.cadKey)
			CADAPI.getCadLinks(encodeURIComponent(selectedPart?.name)).then((res) => {
				if (res.ok) setCadLinks(res.data)
			})
		}
		setSavedVisible(false)
		setSavedPart(null)
		checkFavoritePart(selectedPart)
	}, [selectedPart])
	useEffect(() => {
		if (partStore.products?.length === 1) {
			if (partStore.products[0].source === "savedParts") {
				//alert(partStore.products[0].source)
				setSelectedPart(partStore.products[0])
				//checkFavoritePart(partStore.products[0])
			}

		}

	}, [partStore.products])
	useEffect(() => {
		console.log('Screen B mounted');
		
		return () => {
		  console.log('Screen B unmounted');
		};
	  }, []);
	useEffect(() => {
		//alert('4')
		API.getFavoriteParts(authStore.accountInfo.id).then((res) => {
			if (res.ok) {
				const favoritePart = res.data.collection.find((d) => d.name === selectedPart?.name)
				if (favoritePart) {
					setSavedVisible(true)
					setSavedPart(favoritePart)
				}
				setFavoriteParts(res.data.collection)
			}
		})
		analyticsStore.logEvent("part_details_view", {})
		if (route.params?.partName && !selectedPart) {
			console.log('got part name')
			API.searchPart("parts", route.params.partName, "").then((res) => {
				if (res.data.results.length > 0) {
					var part = res.data.results.find((item) => { return item.name === decodeURIComponent(route.params.partName) });
					//partStore.products = [res.data.results[0]]
					API.loadPartById(part.partId, true).then((result) => {

						let product = result.data.result?.product;
						if (!product) {

							product = { name: item.name, competitorNumber: item.competitorName }
						} else {

							partStore.setSavedPart(product, result.data.result.productConfig)
						}


						partStore.products = [product]

						setSelectedPart(partStore.products[0])
						// //partStore.clearPart()

					})
					// setSelectedPart(res.data.results[0])
					// partStore.setSavedPart(res.data.results[0],res.data.result[0].productConfig)
				}
			})
		}
	}, [])

	const switchToCreateAccount = () => {
		navigation.replace("CreateAccount", { goBack: true })
	}
	const switchToLogin = () => {
		setPromptCreateAccount(false)
		if (Platform.OS === "web") {

			oktaAuth.oktaAuth.signInWithRedirect();
		} else {
			AsyncStorage.getItem("faceID").then(async (faceIDStr) => {
				const token = await getSecureValueFor("savedToken")
				console.log(faceIDStr)
				let faceIDObj = JSON.parse(faceIDStr)
				let tokenObj = JSON.parse(token)
				if (faceIDObj?.faceID) {
					checkFaceID(tokenObj)
				} else {
					await signIn();
				}
			})
		}
	}
	const getSecureValueFor = async (key) => {
		return await SecureStore.getItemAsync(key)
	}
	const toggleSave = () => {
		console.log('clicked')
		if (!authStore.authenticated) {
			setPromptCreateAccount(true)
			console.log('not auth')
			return
		}
		if (savedVisible) {
			console.log('visible')
			refContainer.current.fadeOut().then(() => {
				API.deleteFavoritePart(authStore.accountInfo.id, { id: savedPart.id })
				setSavedPart(null)
				setSavedVisible(false)
			})
		}

		else {
			console.log('call api')
			let data = {
				name: selectedPart?.name,
				nickName: "",
				description: "",
			}

			if (partStore.part.material) {
				data = {
					...data,
					productTypeId: partStore.part.productType.id,
					shapeId: partStore.part.shape.id,
					materialId: partStore.part.material.id,
					end1: partStore.getParametersForEnd(1),
					end2: partStore.getParametersForEnd(2),
					end3: partStore.getParametersForEnd(3),
					end4: partStore.getParametersForEnd(4),
				}
			}
			API.createFavoritePart(authStore.accountInfo.id, data).then((res) => {
				if (res.ok) setSavedPart(res.data.model)
			})
			setSavedVisible(true)

			analyticsStore.logEvent("part_details_save", {})
		}
	}

	const endEditing = () => {

		API.updateFavoritePart(authStore.accountInfo.id, {
			id: savedPart.id,
			nickName: savedTitle,
			description: savedNotes,
		})
	}
	const onBlur = () => {
		if (Platform.OS !== "web")
			return
		API.updateFavoritePart(authStore.accountInfo.id, {
			id: savedPart.id,
			nickName: savedTitle,
			description: savedNotes,
		})
	}
	const showInchHex = (products) => {
		if (products.length === 1) {
			return false;
		} else if (products.some(x => x.isMetric === true) && products.some(x => x.isMetric === false)) {
			return true;
		}
		return false;
	}
	const showDivision = (products) => {
		if (products.length === 1) {
			return false;
		} else if (products.some(x => x.isFsc === true) && products.some(x => x.isFsc === false)) {
			return true;
		}
		return false;
	}
	const getEndId = (end) => {
		let id = partStore.part[end]?.endType?.id
		if (!id)
			id = 0;

		return id;
	}
	return (
		<ScrollView>
			<View style={styles.mainContainer}>

				<ScrollView contentContainerStyle={{ flexGrow: 1, marginHorizontal: 30, paddingBottom: 100, marginTop: 20 }}>
					{selectedPart?.image?.path && (
						<Surface style={styles.partImageContainer}>
							<Image
								source={{
									uri: `${IMGSRV_URL}/static/img/config/${selectedPart?.image?.path}`,
								}}
								style={styles.partImage}
								resizeMode="contain"
							/>
						</Surface>
					)}

					{!selectedPart?.image?.path && (
						<Surface style={styles.partImageContainer}>
							<Image source={{
								uri: `${IMGSRV_URL}/api/fitting/image/${partStore.part?.shape?.id}/${getEndId(`end1`)}/${getEndId(`end2`)}/${getEndId(`end3`)}/${getEndId(`end4`)}`
							}} style={styles.partImage}
								resizeMode="contain" />
						</Surface>
					)}
					<ScrollView horizontal contentContainerStyle={styles.partTabContainer}>
						<Text style={styles.partNameText}>Part Number: </Text>
						{partStore.products.map((part, i) => {
							return (
								<Pressable
									onPress={() => setSelectedPartTab(i)}
									style={[styles.partNameTab, selectedPartTab === i && styles.tabSelected]}
								>
									<Text style={styles.partNameText}>{part.name}</Text>
									{showInchHex(partStore.products) && (
										<Text style={styles.partNameSubtext}>{part.isMetric ? "Metric Hex" : "Inch Hex"}</Text>
									)}
									{showDivision(partStore.products) && (
										<Text style={styles.partNameSubtext}>{part.isFsc ? "FSC" : "TFD"}</Text>
									)}

								</Pressable>
							)
						})}
					</ScrollView>
					{selectedPart?.competitorNumber && (
						<Text style={styles.competitorName}>{`Competitor Part Number: ${selectedPart?.competitorNumber}`}</Text>
					)}
					<View style={styles.actionRow}>

						<View />
						<Pressable syle={{ width: 10 }}
							onPress={() => {
								analyticsStore.logEvent("part_details_find", {})
								navigation.navigate("WhereToBuyStack")
							}}
							style={styles.actionButton}
						>
							<Octicons name="location" size={18} color={Colors.yellow} />
							<Text style={styles.actionText}>FIND</Text>
						</Pressable>
						<Pressable syle={{ width: 20 }}
							style={styles.actionButtonLarge}
							onPress={() => {

								var isWeb = true// (Platform.OS === "web" ? true : false)
								let body = '';
								if (isWeb) {
									body += `FittingFinder™ Product Specification: ${selectedPart?.name} \n\r`
									if (selectedPart?.isStandard || selectedPart?.isMadeToOrder) {
										body += `${WEB_URL}/part/${encodeURIComponent(selectedPart?.name)} \r\n\r`
									}
									if (selectedPart?.image?.path || selectedPart?.isMadeToOrder) {
										body += `Product Specification Image: \n${WEB_URL}/backend/static/img/config/${selectedPart?.image?.path}\n\r`
									}

									body += `Where to Buy: \nhttps://www.parker.com/FittingFinderWTB \r\n`
								} else {
									body = `<a href="${WEB_URL}/fittingfinder/part/${encodeURIComponent(selectedPart?.name)}" target="_blank">${selectedPart?.name}</a>
											<br/><br/><a href="https://www.parker.com/FittingFinderWTB" target="_blank">Where to Buy</a><br/><br/>`

								}
								body += `\nFitting Attributes:\r\n`
								body += selectedPart?.isMadeToOrder ? `Made to Order${isWeb ? '\r\n' : '<br/>'}` :
									selectedPart?.isStandard ? `Standard Part${isWeb ? '\r\n' : '<br/>'}` : `Non-Standard Part Made to Order${isWeb ? '\r\n' : '<br/>'}`
								//body += selectedPart?.isStandard ? `Standard Part${isWeb ? '\r\n' : '<br/>'}` : `Non-Standard Part${isWeb ? '\r\n' : '<br/>'}`

								if (partStore.part.shape) {
									body += `Shape: ${partStore.part.shape.name}${isWeb ? '\r\n' : '<br/>'}`
									{
										partStore.part.end1?.endSize &&
											(body += `End Type 1: ${partStore.part.end1.endType.name} (${partStore.part.end1.endSize.dash})${isWeb ? '\n' : '<br/>'}`)
									}
									{
										partStore.part.end2?.endSize &&
											(body += `End Type 2: ${partStore.part.end2.endType.name} (${partStore.part.end2.endSize.dash})${isWeb ? '\n' : '<br/>'}`)
									}
									{
										partStore.part.end3?.endSize &&
											(body += `End Type 3: ${partStore.part.end3.endType.name} (${partStore.part.end3.endSize.dash})${isWeb ? '\n' : '<br/>'}`)
									}
									{
										partStore.part.end4?.endSize &&
											(body += `End Type 4: ${partStore.part.end4.endType.name} (${partStore.part.end4.endSize.dash})${isWeb ? '\n' : '<br/>'}`)
									}
								}

								body += selectedPart?.material ? `Material: ${selectedPart.material}${isWeb ? '\n' : '<br/>'}` :
									partStore.part.material ? `Material: ${partStore.part.material.name}${isWeb ? '\n' : '<br/>'}` : ''
								body += selectedPart?.productSpecification?.productSpecificationLabels.length > 0 &&
									selectedPart?.productSpecification?.productSpecificationLabels[0].sae.length > 0 ?
									`SAE: ${selectedPart?.productSpecification?.productSpecificationLabels[0].sae}${isWeb ? '\n' : '<br/>'}` :
									''

								body += selectedPart?.productSpecification?.productSpecificationLabels
									?.map((d) => `${d.label}: ${d.value}${isWeb ? '\n' : '<br/>'}`)
									.join("")

								if (cadLinks.length > 1) {
										
									const filteredCadLinks = cadLinks.filter(item => item.cadType === "STP");
									console.log("Filtered CAD Links:", filteredCadLinks); // Debug log
									body += `\rLinks to Download CAD Models:\n`
									// Map the filtered array to the desired format
									body += filteredCadLinks
										.map(d => isWeb ? `${d.fileLoc}\n` : `<a href='${d.fileLoc}'>CAD Models${d.cadtype !== 'Part Solutions' ? ' - ' + d.cadtype : ''}</a><br/>`)
										.join("");
										
								}else if (cadLinks.length > 0) {
									body += `\rLinks to Download CAD Models:\n`
									body += cadLinks.map((d) => isWeb ? `${d.fileLoc}\n` : `<a href='${d.fileLoc}'>CAD Models ${d.cadType !== 'Part Solutions' ? '-' + d.cadType : ''}</a><br/>`)
										.join("");

								}

								if (Platform.OS === "web") {
									
									MailComposer.composeAsync({
										subject: `Part Specifications - ${selectedPart?.name}`,
										body: body,
										isHtml: true,
									}).catch((err) => {
										//alert(err)
									})
								} else {
									
									onShare(body, `${WEB_URL}/fittingfinder/part/${encodeURIComponent(selectedPart?.name)} `)
								}


								analyticsStore.logEvent("part_details_email", {})
							}}
						>
							{Platform.OS === "ios" && (
								<Octicons name="share" size={18} color={Colors.yellow} />
							)}
							{Platform.OS === "android" && (
								<Octicons name="share-android" size={18} color={Colors.yellow} />
							)}
							{Platform.OS === "web" && (
								<Octicons name="mail" size={18} color={Colors.yellow} />
							)}

							<Text style={styles.actionText}>SPECS & CAD</Text>
						</Pressable>
						<View />
						<Pressable style={styles.actionButton} onPress={() => toggleSave()}>
							{Platform.OS === "web" ? (<img
								src={savedPart ? require("../assets/btn_icn_save_on.png") : require("../assets/btn_icn_save.png")}
							/>) : (<Image
								source={savedPart ? require("../assets/btn_icn_save_on.png") : require("../assets/btn_icn_save.png")}
							/>)}

							<Text style={styles.actionText}>{savedPart ? 'SAVED' : 'SAVE'}</Text>
						</Pressable>
					</View>

					{savedVisible && (
						<Animatable.View useNativeDriver={true} animation="fadeIn" duration={300} ref={refContainer}>
							<TextInput
								style={styles.input}
								placeholder="Add title here..."
								onChangeText={setSavedTitle}
								onEndEditing={endEditing}
								onBlur={onBlur}
								value={savedTitle}
								underlineColorAndroid={Colors.transparent}
							/>
							<TextInput
								style={[styles.input, { height: 150, paddingTop: 20 }]}
								placeholder="Add notes here..."
								onChangeText={setSavedNotes}
								onSubmitEditing={endEditing}
								onEndEditing={endEditing}
								onBlur={onBlur}
								value={savedNotes}
								underlineColorAndroid={Colors.transparent}
								multiline
							/>
						</Animatable.View>
					)}

					<Text style={styles.descTitle}>Description</Text>

					{!selectedPart?.image?.path && <Text style={{ lineHeight: 22 }}>Note: Detailed drawing not available</Text>}
					<Text style={{ lineHeight: 22 }}>{selectedPart?.isMadeToOrder ? "Made to Order " : selectedPart?.isStandard ? "Standard Part" : "Non-Standard Part Made to Order"}</Text>
					{(!selectedPart?.productSpecification?.productSpecificationLabels && selectedPart?.competitorNumber) && (
						<Text style={{ lineHeight: 22 }}>If addition information is required for this part, please contact us by selecting contact us on the account menu</Text>
					)}
					{partStore.part.shape && <Text style={{ lineHeight: 22 }}>{`Shape: ${partStore.part.shape.name}`}</Text>}

					{partStore.part.end1?.endSize && (
						<Text
							style={{ lineHeight: 22 }}
						>{`End Type 1: ${partStore.part.end1.endType.name} (${partStore.part.end1.endSize.dash})`}</Text>
					)}
					{partStore.part.end2?.endSize && (
						<Text
							style={{ lineHeight: 22 }}
						>{`End Type 2: ${partStore.part.end2.endType.name} (${partStore.part.end2.endSize.dash})`}</Text>
					)}
					{partStore.part.end3?.endSize && (
						<Text
							style={{ lineHeight: 22 }}
						>{`End Type 3: ${partStore.part.end3.endType.name} (${partStore.part.end3.endSize.dash})`}</Text>
					)}
					{partStore.part.end4?.endSize && (
						<Text
							style={{ lineHeight: 22 }}
						>{`End Type 4: ${partStore.part.end4.endType.name} (${partStore.part.end4.endSize.dash})`}</Text>
					)}
					{selectedPart?.material ? (<Text style={{ lineHeight: 22 }}>{`Material: ${selectedPart.material}`}</Text>) :
						partStore.part.material ? (<Text style={{ lineHeight: 22 }}>{`Material: ${partStore.part.material.name}`}</Text>) : ('')}
					{/* {partStore.part.material && (
						<Text style={{ lineHeight: 22 }}>{`Material: ${partStore.part.material.name}`}</Text>
					)} */}
					{selectedPart?.productSpecification?.productSpecificationLabels.length > 0 &&
						selectedPart?.productSpecification?.productSpecificationLabels[0].sae.length > 0 && (
							<Text
								style={{ lineHeight: 22 }}
							>{`SAE: ${selectedPart?.productSpecification?.productSpecificationLabels[0].sae}`}</Text>
						)}
					{selectedPart &&
						selectedPart?.productSpecification?.productSpecificationLabels?.map((d) => (
							<Text style={{ lineHeight: 22 }}>{`${d.label}: ${d.value}`}</Text>
						))}
					{cadLinks.length > 0 && (
						<Surface style={styles.results}>
							<FlatList
								contentContainerStyle={styles.listContainer}
								data={cadLinks}
								keyExtractor={(item) => item.fileLoc}
								renderItem={({ item }) => {
									return (
										<Pressable style={styles.resultRow}>
											<View style={{ flex: 1 }}>
												<Text style={styles.itemTitle}>{`CAD Models ${item.cadType !== 'Part Solutions' ? '-' + item.cadType : ''}`}</Text>
											</View>
											<TouchableOpacity style={styles.cadShareButton} onPress={() => {
												if (Platform.OS === "web") {
													analyticsStore.logEvent("cad_download", {})
													Linking.openURL(item.fileLoc)
												} else {
													analyticsStore.logEvent("cad_email", {})
													let body = `FittingFinder™ CAD Model: ${selectedPart?.name}`
													onCADShare(body, item.fileLoc)
												}
											}
											} >
												{Platform.OS === "ios" && (
													<>
														<Octicons name="share" size={18} color={Colors.yellow} />
														<Text style={styles.cadShareButtonText}>SHARE</Text>
													</>
												)}
												{Platform.OS === "android" && (
													<>
														<Octicons name="share-android" size={18} color={Colors.yellow} />
														<Text style={styles.cadShareButtonText}>SHARE</Text>
													</>
												)}
												{Platform.OS === "web" && (
													<>
														<Octicons name="download" size={18} color={Colors.yellow} />
														<Text style={styles.cadShareButtonText}>DOWNLOAD</Text>
													</>
												)}
											</TouchableOpacity>
											{/* <Button
												buttonType="clear"
												title="Email"
												onPress={() =>
													MailComposer.isAvailableAsync().then((res) => {
														if (res)
															MailComposer.composeAsync({
																subject: `${selectedPart?.name} - CAD MODEL`,
																body: `<a href="${item.fileLoc}">${item.fileLoc}</a>`,
																isHtml: true,
															})
														else
															Alert.alert(
																"Error",
																"Mail services are not available. Make sure you're signed into the Mail app"
															)
													})
												}
											/> */}
										</Pressable>
									)
								}}
							/>
						</Surface>
					)}
				</ScrollView>
				{promptCreateAccount && (
					<Popup
						title="Account Required"
						body="In order to save product configurations, you'll need to log in or create an account"
						closeAction={() => switchToCreateAccount()}
						cancelAction={() => setPromptCreateAccount(false)}
						secondAction={() => switchToLogin()}
						closeButtonTitle="Create an Account"
						secondButtonTitle="Log in"
						cancelButtonTitle="Cancel"
					/>
				)}
			</View>

		</ScrollView>
	)
}

export default FittingDetails

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		width: maxWidth,
		alignSelf: "center",
		backgroundColor: Colors.backgroundGrey,
	},
	partTabContainer: {
		marginTop: 20,
		marginLeft: 0,
		marginHorizontal: 30,
	},
	partNameTab: {
		paddingBottom: 10,
		borderBottomColor: Colors.backgroundGrey,
		borderBottomWidth: 2,
		marginRight: 20,
	},
	tabSelected: {
		borderBottomColor: Colors.yellow,
	},
	partNameText: {
		fontWeight: "bold",
		fontSize: 17,
	},
	partNameSubtext: {
		fontWeight: "bold",
		fontSize: 14,
	},
	competitorName: {
		fontWeight: "bold",
		fontSize: 14,
		paddingLeft: 30,
		paddingBottom: 20,
	},
	partImageContainer: {
		height: 300,
		backgroundColor: Colors.white,
		padding: 20,
	},
	partImage: {
		width: "100%",
		height: "100%",
	},
	actionRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: 20,

	},
	actionButton: {
		backgroundColor: Colors.darkGrey,
		flex: 1,
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 5,


	},
	actionButtonLarge: {
		backgroundColor: Colors.darkGrey,
		flex: 2,
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 5,
		marginLeft: 2.5,
		marginRight: 2.5
	},
	actionText: {
		color: Colors.white,
		marginLeft: 10,
		fontWeight: "500",
	},
	input: {
		color: Colors.darkGrey,
		backgroundColor: "#E1E2E3",
		padding: 15,
		borderRadius: 5,
		marginBottom: 20,
	},
	descTitle: {
		fontWeight: "bold",
		fontSize: 20,
		marginBottom: 10,
	},
	descLabel: {
		fontWeight: "bold",
		lineHeight: 26,
	},

	results: {
		marginTop: 25,
		paddingLeft: 10,
	},
	resultRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingVertical: 5,
		paddingRight: 10,
		borderBottomColor: Colors.mediumGrey,
		borderBottomWidth: 1,
	},
	cadShareButton: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: Colors.darkGrey, // Button background color
		padding: 10,
		borderRadius: 5,
	},
	cadShareButtonText: {
		color: 'white', // Text color
		marginLeft: 10, // Add spacing between the icon and text
	},
})
