import React, { useEffect, useState } from "react"
import { StyleSheet, FlatList, Dimensions, View, Pressable, Image, Platform } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { Surface, Text } from "react-native-paper"
import { partStore } from "../utils/PartStore"
import { authStore } from "../utils/AuthStore"
import { observer } from "mobx-react-lite"
import Icon from "react-native-vector-icons/Ionicons"
import { analyticsStore } from "../utils/AnalyticsStore"
import colors from "../utils/Colors"
import { toJS } from "mobx"
import { FittingImage, Button } from "../components/Index"
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { navStore } from "../utils/NavStore"
import { API_URL, WEB_URL,IMGSRV_URL } from '../utils/Configuration';
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const gridWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 900) : Dimensions.get("window").width
const FittingSelection = ({ navigation, route }) => {
	navStore.navigation = navigation

	let data = []
	let gridFormat = false
	let screen = route.params?.fittingScreen || "shape"
	let title = ""
	let col = Platform.OS === "web" && gridWidth > 600 ? 3 : 2
	let mainContainerStyle = styles.mainContainer
	let isComponentScreen = false
	const { addListener } = useNavigation();
	React.useLayoutEffect(() => {
		
		let screen = route.params?.fittingScreen || "shape"
		console.log(screen)
		console.log(`screen:${screen}`)
		if (screen === "shape") {
			if (Platform.OS !== "web" || gridWidth < 600)
				
				navigation.setOptions({

					headerRight: () => (
						<Pressable onPress={() => navigation.navigate("FittingSearch")}>
							<Icon style={{ marginRight: 20 }} name="ios-search-outline" size={28} color={colors.black} />
						</Pressable>
					),
				})
			
		}else if(screen==="component"){
			console.log('hello component')
		}
		const handleBackButton = (e) => {
			
			if (route.params?.fittingScreen === 'component') {
				partStore.getShapes(1)
			//   e.preventDefault();
			//   e.returnValue = ''; // This triggers the confirmation dialog
			//   handleBeforeRemove(e);
			}
		  };
	  
		  if (Platform.OS === 'web' && route.params?.fittingScreen === 'component') {
			window.addEventListener('beforeunload', handleBackButton);
			window.history.pushState(null, null, window.location.href); // Ensure state is pushed to handle back
			window.addEventListener('popstate', handleBackButton); // Listen for back navigation
		  }
	
		 
		const unsubscribe = addListener('beforeRemove', (e) => {
			// Prevent going back to Screen1 if a condition is met
			console.log('before')
			if (route.params?.fittingScreen === 'component') {
				partStore.getShapes(1)
			}
		});
		return () => {
			// Clean up the subscription when the component unmounts
			unsubscribe();
			if (Platform.OS === 'web') {
				window.removeEventListener('beforeunload', handleBackButton);
				window.removeEventListener('popstate', handleBackButton);
			  }
		};

	}, [navigation,route])
	
	const selectItem = (item) => {
		isComponentScreen = false
		let nextScreen = "FittingSelection"
		console.log(`selectItem: ${item}`)
		switch (screen) {
			case "productType":
				partStore.getShapes(1)
				analyticsStore.logEvent("part_select_type", {})
				break
			case "shape":
				nextScreen = "FittingOverview"
				analyticsStore.logEvent("part_select_shape", {})
				break
			// case "component":
			// 	nextScreen = "FittingOverview"
			// 	analyticsStore.logEvent("part_select_component", {})
			// 	break
			case "endSize":
				analyticsStore.logEvent("part_select_final", {})
				break
			case "material":
				nextScreen = "FittingDetails"
				break
		}
		partStore.setPartObj(screen === 'component' ? 'shape' : screen, item)

		if (screen === "shape" && item.endCount === 1) {
			console.log('shape default')
			// skip overview screen, goto select end
			partStore.setEnd(1, false)
			navigation.push("FittingSelection", { fittingScreen: "endType" })
		} else if (screen === "component" && item.endCount === 1) {
			console.log('component default')
			// skip overview screen, goto select end
			partStore.setEnd(1, false)
			navigation.push("FittingSelection", { fittingScreen: "endType" })
		} else if (screen === "shape" && item.id === -5) {
			//we will need to go to shape screen again for components
			partStore.getShapes(item.productType.id)
			//partStore.setEnd(1, false)
			navigation.push("FittingSelection", { fittingScreen: "component", isComponentScreen: true })
		} else if (screen === "endGender" && item.subGenders?.length === 0) {
			navigation.push("FittingSelection", { fittingScreen: "endType" })
		} else if (screen === "endSize" && partStore.part.productType.name === "Component") {
			partStore.getMaterials().then(() => {
				let data = partStore.materials.slice()
				if (data.length === 0) {
					partStore
						.getProducts()
						.then(() => navigation.push("FittingDetails", { fittingScreen: partStore.nextScreen[screen] }))
				} else if (data.length === 1) {
					partStore.setPartObj("material", data[0])
					partStore
						.getProducts()
						.then(() => navigation.push("FittingDetails", { fittingScreen: partStore.nextScreen[screen] }))
				} else navigation.push("FittingSelection", { fittingScreen: "material" })
			})
		} else if (screen === "endSize" && partStore.allEndsFilledOut()) {
			partStore.getMaterials().then(() => {
				let data = partStore.materials.slice()
				if (data.length === 1) {
					partStore.setPartObj("material", data[0])
					partStore
						.getProducts()
						.then(() => navigation.push("FittingDetails", { fittingScreen: partStore.nextScreen[screen] }))
				} else navigation.push("FittingSelection", { fittingScreen: "material" })
			})
		} else if (screen === "endSize") {
			navigation.navigate("FittingOverview", { fittingScreen: partStore.nextScreen[screen], key: `endselection-${partStore.selectedEndNumber}` })//, 
		} else if (screen === "material") {
			partStore.getProducts().then(() =>
				navigation.push(nextScreen, { fittingScreen: partStore.nextScreen[screen] }))
		} else {
			console.log('screen:' + screen)
			navigation.push(nextScreen, { fittingScreen: partStore.nextScreen[screen] })
		}
	}

	switch (screen) {
		case "productType":
			data = partStore.productTypes.slice()
			gridFormat = true
			title = "Select Part Type"
			break
		case "shape":
			data = partStore.shapes.slice()
			gridFormat = true
			title = "Select Fitting Shape"

			mainContainerStyle = styles.mainContainer2
			break
		case "component":
			data = partStore.shapes.slice()
			gridFormat = true
			title = "Select Component Type"

			mainContainerStyle = styles.mainContainer2
			break
		case "endGender":
			data = partStore.endGenders?.slice()
			mainContainerStyle = styles.mainContainer
			title = "Select End Type"
			break
		case "subGender":
			mainContainerStyle = styles.mainContainer
			{
				let endNumber = partStore.selectedEndNumber === 0 ? 1 : partStore.selectedEndNumber
				data = partStore.part[`end${endNumber}`]?.endGender?.subGenders?.slice()
				title = "Select End Type"
			}
			break
		case "endType":
			data = partStore.endTypes.slice().sort((a, b) => a.priority - b.priority)
			mainContainerStyle = data.length >= 3 ? styles.mainContainer2 : styles.mainContainer
			title = "Select End Type"
			gridFormat = true
			break
		case "endSize":
			mainContainerStyle = styles.mainContainer
			data = partStore.endSizes.slice().sort((a, b) => {
				let aDash = parseInt(a.dash.replace(/[MLSU]/gi, ""))
				let bDash = parseInt(b.dash.replace(/[MLSU]/gi, ""))
				return aDash - bDash
			})
			title = "Select End Size"
			break
		case "material":
			mainContainerStyle = styles.mainContainer
			data = partStore.materials.slice()
			title = "Select Material"
			break
	}

	navigation.setOptions({ title })

	const getEndId = (end) => {
		let id = partStore.part[end]?.endType?.id
		if (!id)
			id = 0;

		return id;
	}

	return (
		<View style={mainContainerStyle}>
			{gridFormat && (
				<FlatList
					contentContainerStyle={styles.gridContainer}
					data={data}
					keyExtractor={(item) => item.id}
					numColumns={col}
					renderItem={({ item }) => (
						<Pressable onPress={() => selectItem(item)}>
							<Surface style={styles.surface}>
								<Image
									source={{
										uri: `${IMGSRV_URL}/static/img/config/${item.image?.path}`,
									}}
									style={styles.partImage}
									resizeMode="contain"
								/>
							</Surface>
							<Text style={styles.gridItemTitle}>{item.name}</Text>
						</Pressable>
					)}
				/>
			)}

			{screen === "material" && (
				<Surface>
					{
						partStore.part?.productType?.id === 5 ? (
							<FittingImage />
						) : (
							<Image source={{
								uri: `${IMGSRV_URL}/api/fitting/image/${partStore.part?.shape?.id}/${getEndId(`end1`)}/${getEndId(`end2`)}/${getEndId(`end3`)}/${getEndId(`end4`)}`
							}}
								style={{
									maxHeight: 362,
									maxWidth: 347,
									width: 347,
									height: 362,
									alignSelf: "center",
								}} resizeMode="contain" />
						)
					}
					
				</Surface>
			)}

			{!gridFormat && (
				<View style={{ flex: 1 }}>
					{/* {screen === "endSize" && partStore.showThreadIDTool && (
						<View style={styles.threadIDRow}>
							<Text style={styles.threadIDTitle}>Need Help Sizing?</Text>
							<Pressable onPress={() => navigation.navigate("ThreadIDStack")} style={styles.actionButton}>
								<Image source={require("../assets/btn_icn_search.png")} />
								<Text style={styles.actionText}>THREAD ID TOOL</Text>
							</Pressable>
						</View>
					)} */}
					<Surface style={styles.listContainer}>
						{screen === "endSize" && (
							<>
								<View style={styles.sizeRowHeader}>
									<Text style={styles.itemSizeHeader}>FITTING SIZE</Text>
									<Text style={styles.itemSizeHeader}>TUBE SIZE</Text>
									<Text style={styles.itemSizeHeader}>THREAD SIZE</Text>
								</View>
							</>
						)}
						<FlatList
							data={data}
							keyExtractor={(item) => item.id}
							renderItem={({ item }) => {
								return screen === "endSize" ? (
									<Pressable onPress={() => selectItem(item)} style={styles.sizeRow}>
										<Text style={styles.itemSize}>{item.dash}</Text>
										<Text style={styles.itemSize}>
											{item.outsideDiameterInches ? item.outsideDiameterInches : item.outsideDiameterMillimeters}
										</Text>
										<Text style={styles.itemSize}>{item.thread}</Text>
									</Pressable>
								) : (
									<Pressable onPress={() => selectItem(item)} style={styles.row}>
										<Text style={styles.itemTitle}>{item.name}</Text>
										<Image source={require("../assets/icn_more.png")} />
									</Pressable>
								)
							}}
						/>
					</Surface>
				</View>
			)}
		</View>
	)
}

export default observer(FittingSelection)


const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		padding: 20,
		width: maxWidth,
		alignSelf: "center",
	},
	mainContainer2: {
		flex: 1,
		padding: 20,
		width: gridWidth,
		alignSelf: "center",
	},
	gridContainer: {
		paddingTop: 20,
	},

	listContainer: {
		flex: 1,
		backgroundColor: colors.white,
		paddingBottom: 40,
	},
	threadIDRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 35,
	},
	threadIDTitle: {
		fontSize: 18,
		fontWeight: "bold",
		alignSelf: "center",
		flex: 1,
	},
	gridItemTitle: {
		fontSize: 16,
		fontWeight: "bold",
		margin: 10,
		marginTop: 5,
		marginBottom: 25,
		width: maxWidth / 2 - 60,
	},
	itemTitle: {
		fontSize: 18,
		fontWeight: "600",
	},
	itemSize: {
		fontSize: 18,
		fontWeight: "600",
		width: 100,
	},
	itemSizeHeader: {
		fontSize: 12,
		fontWeight: "600",
		color: colors.titleGrey,
		width: 100,
	},
	surface: {
		padding: 8,
		height: Platform.OS === "web" ? maxWidth / 2 - 40 : Dimensions.get("window").width / 2 - 40,
		width: maxWidth / 2 - 40,
		marginHorizontal: 10,
		alignItems: "center",
		justifyContent: "center",
		elevation: 4,
	},
	partImage: {
		height: Platform.OS === "web" ? maxWidth / 2 - 40 : Dimensions.get("window").width / 2 - 60,
		width: maxWidth / 2 - 60,
	},
	sizeRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: colors.white,
		borderBottomColor: colors.grey,
		borderBottomWidth: 1,
		paddingLeft: 10,
		paddingVertical: 17,
	},
	sizeRowHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: colors.white,
		borderBottomColor: colors.grey,
		borderBottomWidth: 1,
		paddingLeft: 10,
		paddingVertical: 17,
	},
	row: {
		flexDirection: "row",
		borderBottomColor: colors.grey,
		borderBottomWidth: 1,
		justifyContent: "space-between",
		alignItems: "center",
		marginLeft: 20,
		paddingVertical: 17,
		paddingRight: 10,
	},
	actionButton: {
		backgroundColor: colors.darkGrey,
		flex: 1,
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 5,
	},
	actionText: {
		color: colors.white,
		fontSize: 14,
		fontWeight: "500",
		marginLeft: 10,
	},
})
