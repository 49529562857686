import { observable, action, computed, makeAutoObservable } from "mobx"
import AsyncStorage from "@react-native-async-storage/async-storage"
import jwt_decode from "jwt-decode"
import * as SecureStore from "expo-secure-store"
import { Platform } from "react-native"
import {WEB_URL} from '../utils/Configuration';
class AnalyticsStore {
  
	constructor() {
		makeAutoObservable(this)
	}

  eventUrl =`${WEB_URL}/backend/api/gtm/event/part_details_email`
  // Register the handle function from App.js
  // registerHandleLogEvent(handleLogEvent) {
  //   this.handleLogEvent = handleLogEvent;
  // }
  logEvent(eventName, params){
    if (Platform.OS === 'web' && window.dataLayer) {
      window.dataLayer.push({
        event: eventName, // The event name you configured in GTM
        customData: params, // Any additional data you want to pass
      });
      console.log('GTM event triggered');
    }else{
      this.eventUrl =`${WEB_URL}/backend/api/gtm/event/${eventName}`
    }
    //this.eventUrl =`${WEB_URL}/backend/api/gtm/event/part_select_shape`
    //this.eventUrl =`${WEB_URL}/backend/api/gtm/event/${eventName}`
    //console.log(`log event:${eventName}`)
    //call handleShowWebView
    // if (this.handleLogEvent) {
    //   this.handleLogEvent(eventName, params); // Call the handle function in App.js
    // }
  }

}

export const analyticsStore = new AnalyticsStore()