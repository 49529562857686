import React, { useEffect, useState } from "react"
import {
	StyleSheet,
	Text,
	View,
	TextInput,
	FlatList,
	Pressable,
	ActivityIndicator,
	Image,
	Platform,
	Dimensions, ScrollView
} from "react-native"
import Icon from "react-native-vector-icons/Ionicons"
import { API } from "../utils/API"
import Colors from "../utils/Colors"
import { Surface } from "react-native-paper"
import { partStore } from "../utils/PartStore"
import { SafeAreaView } from "react-native-safe-area-context"
import { Button, Popup } from "../components/Index"
import { navStore } from "../utils/NavStore"
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const FittingSearch = ({ navigation, route }) => {
	navStore.navigation = navigation
	const [searchText, setSearchText] = useState("")
	const [results, setResults] = useState([])
	const [selectedToggle, setSelectedToggle] = useState(0)
	const [selectedManufacturer, setSelectedManufacturer] = useState({ id: 0, name: "All" })
	const [loading, setLoading] = useState(false)
	const [searchFocused, setSearchFocused] = useState(false)
	const [otherManufacturerMatchFound, setOtherManufacturerMatchFound] = useState(false)

	useEffect(() => {
		if (searchText?.length < 1) {
			setResults([])
			return
		}
		performSearch()
	}, [selectedToggle, selectedManufacturer])

	useEffect(() => {
		const unsubscribe = navigation.addListener("focus", () => {
			if (searchText?.length >= 1) performSearch()
		})
		return unsubscribe
	}, [navigation])

	useEffect(() => partStore.clearPart(), [])

	const performSearch = () => {
		if (searchText === "" || searchText === null) {


			return
		}
		setLoading(true)
		setOtherManufacturerMatchFound(false)
		let endpoint = selectedToggle === 0 ? "parts" : "competitorParts"
		let competitorId =
			selectedManufacturer.id > 0 && selectedToggle === 1 ? `&competitorId=${selectedManufacturer.id}` : ""
		API.searchPart(endpoint, encodeURIComponent(searchText), competitorId).then((res) => {
			if (competitorId && res.data.results.length === 0) {
				API.searchPart(endpoint, encodeURIComponent(searchText), "").then((res) => {
					if (res.data.results.length > 0) setOtherManufacturerMatchFound(true)
					setResults(res.data.results)
					setLoading(false)
				})
			}
			else {
				setResults(res.data.results)
				setLoading(false)
			}
		})
	}

	return (
		<View style={styles.mainContainer}>
			<View style={styles.toggleContainer}>
				<Pressable
					onPress={() => setSelectedToggle(0)}
					style={[styles.toggle, selectedToggle === 0 && styles.selected]}
				>
					<Text style={[styles.toggleText, selectedToggle === 0 && styles.selectedText]}>Parker</Text>
				</Pressable>
				<Pressable
					onPress={() => setSelectedToggle(1)}
					style={[styles.toggle, selectedToggle === 1 && styles.selected]}
				>
					<Text style={[styles.toggleText, selectedToggle === 1 && styles.selectedText]}>Competitive Interchange</Text>
				</Pressable>
			</View>
			<View style={styles.searchBar}>
				<Icon name="ios-search-outline" size={28} color={Colors.black} />
				<TextInput
					style={styles.input}
					placeholder="Part Number"
					onChangeText={setSearchText}
					value={searchText}
					clearButtonMode="while-editing"
					clearTextOnFocus
					autoFocus
					autoCorrect={false}
					returnKeyType="search"
					underlineColorAndroid={Colors.transparent}
					onEndEditing={performSearch}
					onFocus={() => setSearchFocused(true)}
					onBlur={() => {
						if (Platform.OS === "web") performSearch()
						setSearchFocused(false)
					}}
				/>

			</View>

			{selectedToggle === 1 && (
				<>
					<Pressable
						onPress={() => {
							navigation.navigate("SelectCompetitor", {
								selectedManufacturer,
								onManufacturerSelected: (manufacturer) => setSelectedManufacturer(manufacturer),
							})
						}}
					>
						<Surface style={styles.manufacturerContainer}>
							<Text style={styles.manufacturerText}>{`Manufacturer: ${selectedManufacturer.name}`}</Text>
							<Image source={require("../assets/icn_more.png")} />
						</Surface>

					</Pressable>

					{searchText?.length < 3 && (
						<Text style={styles.instructionsText}>
							Search the Competitor Interchange above by entering a part number or by selecting a manufacturer.
						</Text>
					)}
				</>
			)}
			{Platform.OS === "web" &&(
				<Button title="Search" style={{ width: '70%', alignSelf: 'center', marginBottom:20 }} theme="yellow" onPress={() => performSearch()} />
			)}
			
			{(!searchFocused && searchText !== "") && (
				<>
					<View style={styles.resultsRow}>
						{loading && <ActivityIndicator size="small" color={Colors.yellow} />}
						{(!loading) && <Text style={styles.resultsText}>{`${results?.length} RESULTS FOR "${searchText}"`}</Text>}
					</View>

					{otherManufacturerMatchFound && (
						<View style={styles.noResultsRow}>
							<Text style={styles.resultsText}>
								Your search did not return results for the chosen manufacturer. The following results are matches from
								other manufacturers.
							</Text>
						</View>
					)}
					{results.length === 0 && selectedToggle === 1 && (
						<View style={styles.noResultsRow}>
							<Text style={styles.resultsText}>
								This manufacturer part number does not convert to a Parker part number. Please contact us by using the
								Contact Us options available under the My Account section.
							</Text>
						</View>
					)}
					<View style={styles.listContainer}>
						<FlatList
							data={results}
							keyExtractor={(item) => item.competitorPartId + item.name}
							renderItem={({ item }) => (
								<Pressable
									onPress={() => {
										let isTFD = (item.partId ? true : false);
										API.loadPartById(isTFD ? item.partId : item.fluidSystemConnectorPartId, isTFD).then((res) => {
											
											let product = res.data.result?.product;
											if(!product){
												
												product = {name:item.name,competitorNumber:item.competitorName}
											}else{
												if(item.competitorName){
													product.competitorNumber =item.competitorName;
												}
												partStore.setSavedPart(product,res.data.result.productConfig)
											}
											
											
											partStore.products = [product]
											
												
											// //partStore.clearPart()
											navigation.navigate("FittingDetails", { product })
										})

									}}
									style={styles.row}
								>
									<Text style={styles.itemTitle}>{selectedToggle === 0 ? item.name : item.competitorName}</Text>
								</Pressable>
							)}
						/>
					</View>
				</>
			)}
		</View>
	)
}

export default FittingSearch

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		flex: 1,
		paddingTop: 20,
		backgroundColor: Platform.OS !== "web" ? Colors.backgroundGrey : Colors.transparent,
		width: maxWidth,
		alignSelf: "center",
	},
	searchBar: {
		backgroundColor: Colors.white,
		flexDirection: "row",
		borderRadius: 30,
		padding: 10,
		marginBottom: 20,
		color: Colors.black,
		marginHorizontal: 20,
	},
	input: {
		flex: 1,
		paddingLeft: 15,
	},
	toggleContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 20,
		marginHorizontal: 20,
	},
	toggle: {
		paddingBottom: 10,
		paddingHorizontal: 20,
		alignItems: "center",
	},
	toggleText: {
		color: Colors.darkGrey,
		fontWeight: "bold",
		fontSize: 16,
	},
	selected: {
		color: Colors.black,
		borderBottomColor: Colors.yellow,
		borderBottomWidth: 3,
	},
	selectedText: {
		color: Colors.black,
	},
	instructionsText: {
		marginHorizontal: 20,
		fontSize: 16,
		fontWeight: "500",
		textAlign: "center",
	},
	manufacturerContainer: {
		marginHorizontal: 20,
		marginBottom: 20,
		padding: 15,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	manufacturerText: {
		fontSize: 18,
		fontWeight: "bold",
	},
	listContainer: {
		backgroundColor: Colors.white,
		flex: 1,
	},
	resultsRow: {
		backgroundColor: Colors.darkGrey,
		paddingLeft: 20,
		paddingVertical: 10,
	},
	noResultsRow: {
		backgroundColor: Colors.mediumGrey,
		paddingLeft: 20,
		paddingVertical: 10,
	},
	resultsText: {
		color: Colors.white,
		fontSize: 14,
		fontWeight: "600",
	},
	row: {
		borderBottomColor: Colors.grey,
		borderBottomWidth: 1,
		justifyContent: "center",
		marginLeft: 20,
		paddingVertical: 12,
	},
	itemTitle: {
		fontSize: 18,
		fontWeight: "600",
	},
})
