import React, { useState } from "react"
import { Pressable, StyleSheet, View, Image, Platform, Dimensions, ScrollView } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { Colors, Surface, Text } from "react-native-paper"
import { FittingImage } from "../components/Index"
import { partStore } from "../utils/PartStore"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import { useFocusEffect } from '@react-navigation/native';
import {API_URL,IMGSRV_URL} from '../utils/Configuration';

import colors from "../utils/Colors"
import { PropTypes } from "mobx-react"

import Icon from "react-native-vector-icons/Ionicons"
import { HeaderBackButton } from '@react-navigation/elements'
import { navStore } from "../utils/NavStore"
const maxWidth =
	Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width - 40
const flexDirection = 'column'//Platform.OS === "web" && maxWidth>600?'row':'column'
console.log(maxWidth)
const FittingOverview = ({ navigation, route }) => {
	navStore.navigation = navigation
	const shape = partStore.part?.shape;
  const endCount = shape ? shape.endCount : null;
	let colContainerStyle = Platform.OS === "web" && maxWidth > 600 ? styles.columnContainer : null

	useFocusEffect(
		React.useCallback(() => {
			console.log(route.params)
			if (route.params?.key?.startsWith('endselection')) {

				var keys = route.params.key.split('-')

				navigation.setOptions({
					headerLeft: ({ canGoBack, props }) =>
						canGoBack && (
							<HeaderBackButton {...props} onPress={() => {
								partStore.setEnd(keys[1], false)
								navigation.push("FittingSelection", { fittingScreen: "endGender" })
								//navigation.push("FittingSelection", { fittingScreen: partStore.nextScreen["overview"] })
							}}
							/>
						)
				});
			}


		}, [route])
	);
	const getEndId = (end) => {
		let id = partStore.part[end]?.endType?.id
		if (!id)
			id = 0;

		return id;
	}
	return (
		<ScrollView contentContainerStyle={styles.mainContainer}>
			<View style={colContainerStyle}>
				<Surface>
					{/* <FittingImage /> */}
					
					<Image source={{
						uri: `${IMGSRV_URL}/api/fitting/image/${partStore.part?.shape?.id}/${getEndId(`end1`)}/${getEndId(`end2`)}/${getEndId(`end3`)}/${getEndId(`end4`)}`
					}}
					style={{
						maxHeight: 362,
						maxWidth: 347,
						width: 347,
						height: 362, 
						alignSelf:"center"
					}}  resizeMode="contain" />

					{Array(endCount + 1)
						.fill()
						.map((d, i) => {
							let prevEnd = partStore.part[`end${i}`]
							let end = partStore.part[`end${i + 1}`]

							if (i >= endCount) {
								if (partStore.part.productType?.id === 1 || partStore.part.productType?.id === 3)
									return (
										<Pressable
											style={styles.endRow}
											onPress={() => {
												partStore.allEndsMatch = true
												
												for (var j = 0; j < endCount; j++) {
													partStore.setEnd(j + 1, true)
												}

												navigation.push("FittingSelection", { fittingScreen: partStore.nextScreen["overview"] })
											}}
										>
											<Text style={styles.itemTitle}>All Ends the Same</Text>
											{Platform.OS === "web" && (
												<Icon style={{ marginRight: 20 }} name="arrow-forward" size={28} color={colors.black} />
											)}
											{Platform.OS !== "web" && (
												<Image style={{ width: 10 }} source={require("../assets/icn_more.png")} />
											)}
										</Pressable>
									)
							} else if (JSON.stringify(end) === JSON.stringify({}) || end === undefined) {
								return (
									<Pressable
										style={styles.endRow}
										onPress={() => {
											if (prevEnd === undefined && i > 0) return
											//console.log('hello:' + i )
											partStore.allEndsMatch = false
											partStore.setEnd(i + 1, false)

											//navigation.push("FittingSelection", { fittingScreen: "endGender", key:`end-${i+1}` })
											navigation.push("FittingSelection", { fittingScreen: partStore.nextScreen["overview"], key: `end-${i + 1}` })
										}}
									>
										<Text style={[styles.itemTitle, prevEnd === undefined && i > 0 && { color: colors.grey }]}>
											Select End {i + 1}
										</Text>
										{Platform.OS === "web" && (
											<Icon style={{ marginRight: 20 }} name="arrow-forward" size={28} color={colors.black} />
										)}
										{Platform.OS !== "web" && (
											<Image style={{ width: 10 }} source={require("../assets/icn_more.png")} />
										)}
									</Pressable>
								)
							} else
								return (
									<Pressable
										style={styles.endRow}
										onPress={() => {
											partStore.setEnd(i + 1, false)
											//console.log('hallo:' + i )
											//navigation.push("FittingSelection", { fittingScreen: partStore.nextScreen["overview"], key: `end-${i + 1}` })
											navigation.push("FittingSelection", { fittingScreen: partStore.nextScreen["overview"] })
										}}
									>
										<View style={{ flex: 1 }}>
											<Text style={styles.endName}>{end.endType?.name}</Text>
											<View style={styles.sizeRow}>
												<View>
													<Text style={styles.endLabel}>DASH SIZE</Text>
													<Text style={styles.endSize}>{end.endSize?.dash}</Text>
												</View>
												<View>
													<Text style={styles.endLabel}>TUBE OD</Text>
													<Text style={styles.endSize}>{end.endSize?.outsideDiameterInches}</Text>
												</View>
												<View>
													<Text style={styles.endLabel}>THREAD SIZE</Text>
													<Text style={styles.endSize}>{end.endSize?.thread}</Text>
												</View>
											</View>
										</View>
									</Pressable>
								)
						})}
				</Surface>
			</View>
			<View style={colContainerStyle === styles.columnContainer ? styles.columnContainerRight : null}>
				<View style={styles.descTitleContainer}>
					<Text style={styles.descTitle}>Description</Text>
				</View>

				{partStore.part.shape && (
					<Text>
						<Text style={styles.descLabel}>Shape:</Text>
						{` ${partStore.part.shape.name}`}
					</Text>
				)}
				{partStore.part.end1?.endSize && (
					<Text>
						<Text style={styles.descLabel}>End Type 1:</Text>
						{` ${partStore.part.end1.endType.name} (${partStore.part.end1.endSize.dash})`}
					</Text>
				)}
				{partStore.part.end2?.endSize && (
					<Text>
						<Text style={styles.descLabel}>End Type 2:</Text>
						{` ${partStore.part.end2.endType.name} (${partStore.part.end2.endSize.dash})`}
					</Text>
				)}
				{partStore.part.end3?.endSize && (
					<Text>
						<Text style={styles.descLabel}>End Type 3:</Text>
						{` ${partStore.part.end3.endType.name} (${partStore.part.end3.endSize.dash})`}
					</Text>
				)}
				{partStore.part.end4?.endSize && (
					<Text>
						<Text style={styles.descLabel}>End Type 4:</Text>
						{` ${partStore.part.end4.endType.name} (${partStore.part.end4.endSize.dash})`}
					</Text>
				)}
			</View>

		</ScrollView>
	)
}

export default observer(FittingOverview)

const styles = StyleSheet.create({
	mainContainer: {
		// flex: 1,
		// flexDirection: flexDirection, // Arrange children horizontally
		// flexGrow: 1,
		marginHorizontal: 20,
		marginTop: 20,
		width: maxWidth,
		alignSelf: "center",
		paddingLeft: Platform.OS === "web" && maxWidth < 600 ? 20 : 0,
		paddingRight: Platform.OS === "web" && maxWidth < 600 ? 20 : 0,
		paddingBottom: 20
	},
	columnContainer: {
		maxWidth: maxWidth / 2,
		flex: 1, // Equal width for both columns
		//height:'auto'
		//alignItems: 'flex-start',
	},
	columnContainerRight: {
		maxWidth: maxWidth / 2,
		flex: 1, // Equal width for both columns
		//height:'auto'
		//alignItems: 'flex-start',
		marginLeft: 50
	},
	endRow: {
		marginLeft: 10,
		paddingRight: 10,
		paddingVertical: 20,
		flexDirection: "row",
		borderBottomWidth: 1,
		borderBottomColor: colors.grey,
		justifyContent: "space-between",
	},
	sizeRow: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	endName: {
		fontWeight: "bold",
		fontSize: 18,
		marginBottom: 12,
	},
	endLabel: {
		fontSize: 12,
		fontWeight: "500",
		color: colors.mediumGrey,
		marginBottom: 5,
	},
	endSize: {
		fontSize: 18,
		fontWeight: "600",
	},
	descTitle: {
		fontSize: 18,
		fontWeight: "bold",
	},
	descTitleContainer: {
		borderBottomColor: colors.mediumGrey,
		borderBottomWidth: 1,
		marginTop: 20,
		marginBottom: 10,
		paddingBottom: 10,
	},
	descLabel: {
		fontWeight: "bold",
		lineHeight: 26,
	},
	itemTitle: {
		fontSize: 18,
		fontWeight: "600",
	},
	partImage: {
		height: 200,
		width: 200,
		alignSelf: "center",
		marginVertical: 60,
	},
})
