import React, { useEffect, useState } from "react"
import { Pressable, StyleSheet, Text, View, Image, Platform, Dimensions, Linking } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { authStore } from "../utils/AuthStore"
import { observer } from "mobx-react-lite"
import { Button, Popup } from "../components/Index"
import Colors from "../utils/Colors"
import Constants from "expo-constants"
import { signIn, signOut,getAccessToken, refreshTokens } from '@okta/okta-react-native';
import AsyncStorage from "@react-native-async-storage/async-storage"
import * as LocalAuthentication from "expo-local-authentication"
import * as SecureStore from "expo-secure-store"
import { navStore } from "../utils/NavStore"
import oktaAuth from '../auth.config';
import jwtDecode from 'jwt-decode';
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const maxButtonWidth =
	Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 400) : Dimensions.get("window").width

const Account = ({ navigation, route }) => {
	navStore.navigation = navigation
	const [accountCreated, setAccountCreated] = useState(route.params?.accountCreated)
	const [accountDeleted, setAccountDeleted] = useState(route.params?.accountDeleted)
	const [showNoAccount, setShowNoAccount] = useState(false)
	const [faceID, setFaceID] = useState(true)
	const [faceIDSuccess, setFaceIDSuccess] = useState(false)
    const [loginAttempts, setLoginAttempts] = useState(0)
	authStore.isCreateAccount = false;
	const navigate = (screen) => {
		navigation.navigate(screen)
		navigation.setParams({ accountCreated: false, accountDeleted: false })
		setAccountCreated(false)
		setAccountDeleted(false)
	}
	useEffect(() => {
		if (authStore.noAccount === true) {
			setShowNoAccount(true)

		}
	}, [authStore.noAccount])
	useEffect(() => {
		//if (faceIDSuccess)
	}, [faceIDSuccess])

	useEffect(() => {

		async function fetchData() {
			try {
				const token = await getSecureValueFor("savedToken")
				AsyncStorage.getItem("faceID").then((faceIDStr) => {
					console.log(faceIDStr)
					let faceIDObj = JSON.parse(faceIDStr)
					let tokenObj = JSON.parse(token)
					if (faceIDObj?.faceID) {
						checkFaceID(tokenObj)
					}
				})
			} catch (error) {
				// Handle any errors that occur during the getItemAsync call
				console.log('Error retrieving data from SecureStore:', error);
			}

		}
		if (Platform.OS !== "web" && !authStore.authenticated) fetchData()
	}, [])
	const getSecureValueFor = async (key) => {
		return await SecureStore.getItemAsync(key)
	}
	const checkFaceID = async (token) => {
		try {
			// Checking if device is compatible
			const isCompatible = await LocalAuthentication.hasHardwareAsync()

			if (!isCompatible) {
				throw new Error("Your device isn't compatible.")
			}

			// Checking if device has biometrics records
			const isEnrolled = await LocalAuthentication.isEnrolledAsync()

			if (!isEnrolled) {
				throw new Error("No Faces / Fingers found.")
			}

			// Authenticate user
			const result = await LocalAuthentication.authenticateAsync({ disableDeviceFallback: false })

			//console.log(result.success)
			if (result.success) {
				let _token = JSON.parse(token);
				//var refreshToken = await refreshTokens();
				const decodedToken = jwtDecode(_token.access_token);
				const expirationTimeInSeconds = decodedToken.exp;

				// Convert expiration time to milliseconds
				const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000;

				// Create a Date object
				const expirationDate = new Date(expirationTimeInMilliseconds);
				if(expirationDate< new Date()){
					_token = await refreshTokens();
					
				}
				authStore.signInSuccess(-1, '', _token)
				setFaceIDSuccess(true)
				authStore.syncLocalData(authStore.accountInfo.id)



			}

		} catch (error) {
			// Error
			console.log("error", error)
		}
	}
	const submitSignin = async () => {
		
		if(Platform.OS === "web"){
			
			oktaAuth.oktaAuth.signInWithRedirect();
		}else{
			AsyncStorage.getItem("faceID").then(async (faceIDStr) => {
				const token = await getSecureValueFor("savedToken")
				console.log(faceIDStr)
				let faceIDObj = JSON.parse(faceIDStr)
				let tokenObj = JSON.parse(token)
				if (faceIDObj?.faceID) {
					checkFaceID(tokenObj)
				} else {
					await signIn();
				}
			})
		}
		
	}
	return (
		<View style={styles.mainContainer}>
			{accountCreated && (
				<View style={styles.createdContainer}>
					<Image style={styles.logo} source={require("../assets/icn_check.png")} />
					<Text style={styles.createdText}>Your account has been created!</Text>
				</View>
			)}
			{accountDeleted && (
				<View style={styles.createdContainer}>
					<Image style={styles.logo} source={require("../assets/icn_check.png")} />
					<Text style={styles.createdText}>Your account has been deleted</Text>
				</View>
			)}
			<View style={{ flex: 1 }}>
				{authStore.authenticated && (
					<>
						<Pressable onPress={() => navigate("Settings")}>
							<Text style={styles.menuItem}>Settings</Text>
						</Pressable>
						<Pressable onPress={() => navigate("SavedProducts")}>
							<Text style={styles.menuItem}>Saved Products</Text>
						</Pressable>
					</>
				)}
				{/* <Pressable onPress={() => navigate("Contact")}>
					<Text style={styles.menuItem}>Contact Us</Text>
				</Pressable> */}
				<Pressable
					onPress={() => {
						if (Platform.OS === "web")
							Linking.openURL(
								"https://www.parker.com/us/en/privacy-policies.html"
							)
						else
							navigate("Privacy")
					}}
				>
					<Text style={styles.menuItem}>Privacy</Text>
				</Pressable>
				<Pressable
					onPress={() => {
						if (Platform.OS === "web")
							Linking.openURL(
								"https://www.parker.com/us/en/terms-and-conditions.html"
							)
						else
							navigate("Terms")
					}}
				>
					<Text style={styles.menuItem}>Terms & Conditions</Text>
				</Pressable>
				<Pressable onPress={() => {
					if (Platform.OS === "web")
						Linking.openURL(
							"https://www.parker.com/us/en/safety.html"
						)
					else
						navigate("Safety")

				}}>

					<Text style={styles.menuItem}>Safety Guide</Text>
				</Pressable>
				{/* <Pressable onPress={() => navigate("About")}>
					<Text style={styles.menuItem}>About</Text>
				</Pressable> */}
			</View>
			{/* <Auth navigation={navigation} navigate={navigate} isCreateAccount={false} /> */}
			{!authStore.authenticated && (
				<View style={{ alignSelf: "center", maxWidth: maxButtonWidth, marginTop:Platform.OS==="web"? 50:0}}>
					<Text style={styles.info}>
						You are currently using the app as a guest. If you would like to save parts, you will need to log in or create an
						account.
					</Text>
					<Button title="Create Account" theme="yellow" onPress={() => navigate("CreateAccount")} />
					<Button title="Log In" style={{ marginTop: 20 }} onPress={async () => await submitSignin()} />
				</View>
			)}
			{authStore.authenticated && (
				<Button
					title="Log Out"
					style={{ marginTop: 20 }}
					onPress={async () => {
						authStore.logout()
						setAccountCreated(false)
					}}
				/>
			)}
			<Text style={{ alignSelf: "center", marginTop: 10 }}>Version: {Constants.manifest.version} ({(Platform.OS == "ios" ? Constants.manifest.ios.buildNumber : Platform.OS == "android" ? Constants.manifest.android.versionCode : Constants.manifest.buildNumber)})</Text>
			{showNoAccount && (
				<Popup
					title="No Fitting Finder Account"
					body="No account found for your user, would you like to create one?"
					closeAction={() => { setShowNoAccount(false); authStore.noAccount = false; navigate("CreateAccount") }}
					cancelAction={() => { setShowNoAccount(false); authStore.noAccount = false }}
					closeButtonTitle="Yes, Create Account"
					cancelButtonTitle="Cancel"
				/>
			)}
		</View>
	)
}

export default observer(Account)

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: Platform.OS === "web"?0:1,
		paddingHorizontal: 30,
		paddingBottom: 20,
		backgroundColor: Platform.OS !== "web" ? Colors.backgroundGrey : Colors.transparent,
		width: maxWidth,
		alignSelf: "center",
	},
	menuItem: {
		fontWeight: "600",
		fontSize: 22,
		marginVertical: 15,
	},
	createdContainer: {
		backgroundColor: "#E6EEDD",
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#92CB51",
		borderWidth: 1,
	},
	createdText: {
		color: "#729E3E",
		fontWeight: "bold",
		marginLeft: 10,
	},
	info: {
		textAlign: "center",
		marginBottom: 10,
	},
})
