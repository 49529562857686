import { observable, action, computed, makeAutoObservable } from "mobx"
import AsyncStorage from "@react-native-async-storage/async-storage"
import jwt_decode from "jwt-decode"
import { API } from "./API"
import * as SecureStore from "expo-secure-store"
import { Platform } from "react-native"

class NavStore {
  
	constructor() {
		makeAutoObservable(this)
	}

    navigation = null;
    navigate(stack,screen){
        if(this.navigation!==null){
           
            this.navigation.navigate('TabStack', {
                screen: stack,
                params: { screen: screen },
              });
        }
    }

}

export const navStore = new NavStore()