import React, { useState, useRef, useEffect } from 'react';
import { Platform, View, StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';
import { analyticsStore } from '../utils/AnalyticsStore';
import { observer, autorun } from 'mobx';

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    opacity: 1, // Set opacity to 1 to make the WebView visible
  },
});

export function useMobxObserver(getter) {
  const [value, setValue] = useState(getter());

  useEffect(() => {
    const disposer = autorun(() => {
      setValue(getter());
    });
    return () => disposer();
  }, [getter]);

  return value;
}

const GTMView = ({ eventName, eventValue }) => {
  const webViewRef = useRef(null);
  const eventUrl = useMobxObserver(() => analyticsStore.eventUrl);
  const [webViewSource, setWebViewSource] = useState({ uri: eventUrl });
  const [htmlContent, setHtmlContent] = useState('');
  const reloadWebViewWithEvent = () => {
    if (webViewRef?.current) {
      console.log('Reloading WebView');
      webViewRef.current.reload();
    } else {
      console.log('WebView ref is null');
    }
  };

  useEffect(() => {
    console.log(`Event URL changed: ${eventUrl}`);
    if (Platform.OS === 'web') {
      fetch(eventUrl)
        .then(response => response.text())
        .then(html => setHtmlContent(html))
        .catch(error => console.error('Error fetching HTML:', error));
    }
    setWebViewSource({ uri: eventUrl });
    //reloadWebViewWithEvent();
  }, [eventUrl]);

  useEffect(() => {
    // Log when the WebView is mounted
    if (webViewRef?.current) {
      console.log('WebView is mounted');
    } else {
      console.log('WebView is not mounted yet');
    }
  }, [webViewRef.current]);

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
        htmlContent ? (
          <div
            style={{ width: '100%', height: '100%' }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        ) : (
          <div>Loading...</div> // Display a loading message or spinner while content is being fetched
        ) ): (
      <WebView
        
        originWhitelist={['*']}
        source={webViewSource}
        style={{ flex: 1 }}
      />) }
    </View>
  );
};

export default GTMView;
