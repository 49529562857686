import { observable, action, computed, makeAutoObservable, toJS } from "mobx"
import { API } from "./API"

class PartStore {
	constructor() {
		makeAutoObservable(this)
		this.getProductTypes()
		//this.productType.id = 1
		this.getShapes(1)
	}

	productTypes = []
	shapes = []
	endGenders = []
	subGenders = []
	endTypes = []
	endSizes = []
	materials = []
	products = []
	threadIdentification = []

	showThreadIDTool = false

	searchResults = []

	selectedEndNumber = -1
	allEndsMatch = false
    
	part = {}

	nextScreen = {
		productType: "shape",
		shape: "overview",
		overview: "endGender",
		endGender: "subGender",
		subGender: "endType",
		endType: "endSize",
		endSize: "overview",
		material: "details",
	}
    setPartConfiguration(config, product){
		this.clearPart();
		this.part.productType = config.productType
		this.part.shape = config.shape
		this.part.gender = config.gender
		this.part.subGender = config.subGender
		this.part.material = config.material
		config.ends.forEach((end)=>{
			this.part[`end${end.endNumber}`] = {}
			this.part[`end${end.endNumber}`].endType = end.endType
			this.part[`end${end.endNumber}`].endSize = end.endTypeSize
			
		})
		this.products = [product]
	}
	setCompetitorPartNumber(partNumber){
		this.part.competitorNumber=partNumber
	}
	setSavedPart(product, config){
		this.clearPart();
		this.part.productType = config.productType
		this.part.shape = config.shape
		this.part.gender = config.gender
		this.part.subGender = config.subGender
		this.part.material = config.material
		config.ends.forEach((end)=>{
			this.part[`end${end.endNumber}`] = {}
			this.part[`end${end.endNumber}`].endType = end.endType
			this.part[`end${end.endNumber}`].endSize = end.endTypeSize
			
		})
		product.source = 'savedParts'
		this.products = [product]
	}
	clearPart() {
		this.part = {}
		this.selectedEndNumber = -1
		this.allEndsMatch = false
	}

	clearEnds() {
		this.selectedEndNumber = -1
		this.allEndsMatch = false
		delete this.part.end0
		delete this.part.end1
		delete this.part.end2
		delete this.part.end3
		delete this.part.end4
	}

	callAPI(method, data = {}) {
		this.inProgress = true
		this.error = undefined

		return method(data)
			.then((response) => {
				if (response.ok) {
					console.log(response.data)
					return response.data
				} else {
					this.error = response.data.error
				}
			})
			.finally(
				action(() => {
					this.inProgress = false
				})
			)
	}

	getProductTypes() {
		this.callAPI(API.getProductTypes).then((res) => (this.productTypes = res.models))
	}

	getShapes(id) {
		this.callAPI(API.getShapes, id).then((res) => (this.shapes = res.models))
	}

	getEndGenders(productId, shapeId, endNumber) {
		this.callAPI(API.getEndGenders, { productId, shapeId, endNumber, params: this.getEndParameters() }).then(
			(res) => (this.endGenders = res.models)
		)
	}

	getEndTypes(productId, shapeId, endNumber, genderId, subGenderId) {
		this.callAPI(API.getEndTypes, {
			productId,
			shapeId,
			endNumber,
			genderId,
			subGenderId,
			params: this.getEndParameters(true, false),
		}).then((res) => (this.endTypes = res.models))
	}

	getEndTypeSizes(productId, shapeId, endNumber, genderId, subGenderId, endTypeId) {
		let id = genderId ? genderId : 0
		this.callAPI(API.getEndTypeSizes, {
			productId,
			shapeId,
			endNumber,
			genderId: id,
			subGenderId,
			endTypeId,
			params: this.getEndParameters(false),
		}).then((res) => (this.endSizes = res.models))
	}

	getThreadIdentification() {
		let end = this.selectedEndNumber === 0 ? 1 : this.selectedEndNumber
		let subGenderId = this.part[`end${end}`]?.subGender?.id

		let productId = this.part.productType.id
		let shapeId = this.part.shape.id
		let endNumber = this.selectedEndNumber
		let genderId = this.part[`end${end}`]?.endType.genderId

		if (!subGenderId) subGenderId = 0

		this.callAPI(API.getThreadIdentification, {
			productId,
			shapeId,
			endNumber,
			genderId,
			subGenderId,
			params: this.getEndParameters(),
		}).then((res) => (this.threadIdentification = res.pitchSet))
	}

	getMaterials() {
		const productId = this.part.productType.id
		const shapeId = this.part.shape.id

		return this.callAPI(API.getMaterials, {
			productId,
			shapeId,
			params: this.getEndParameters(),
		}).then((res) => {
			this.materials = res.models
		})
	}

	getProducts() {
		const productId = this.part.productType.id
		const shapeId = this.part.shape.id
		const materialId = this.part.material?.id ? this.part.material?.id : 0

		return this.callAPI(API.getProducts, {
			productId,
			shapeId,
			materialId,
			params: this.getEndParameters(),
		}).then((res) => {
			if (res.product.material) this.part.material = res.product.material
			this.products = res.models
		})
	}
	clearEndParams(end, currentKey){
		switch(currentKey){
			case "endGender":
				this.part[end]["subGender"]=null;
				this.part[end]["endType"]=null;
				this.part[end]["endSize"]=null;
				break;
		}
	}
	setPartObj(key, val) {
		console.log(`setpartobj: ${key}`)
		if (key === "productType") this.clearPart()
		if (this.selectedEndNumber >= 0 && key !== "material" && key !== "shape") {
			if (this.allEndsMatch) {
				Array(this.part.shape?.endCount)
					.fill()
					.forEach((d, i) => {
						console.log(`partConfig:${this.part[`end${i + 1}`]}`)
						this.part[`end${i + 1}`][key] = val
						this.clearEndParams(`end${i + 1}`,key)
					})
			} else {
				console.log(`partConfig:${this.part[`end${this.selectedEndNumber}`]}`)
				this.part[`end${this.selectedEndNumber}`][key] = val
			}
		}else if(key==='shape'){
			console.log(val.productType)
			this.clearPart()
			this.part['productType'] = val.productType
			this.part['shape'] = val
		} else {
			console.log(`partConfig:${this.part}`)
			this.part[key] = val
		}

		// Special Cases for screens
		switch (key) {
			case "productType":
				this.getShapes(val.id)
				break
			case "shape":
				// nextScreen = "FittingOverview"
				this.clearEnds()
				break
			case "endGender":
				this.getEndTypes(this.part.productType.id, this.part.shape.id, this.selectedEndNumber, val.id, 0)
				break
			case "subGender":
				this.setSubGender(val)
				break
			case "endType":
				this.showThreadIDTool = val.gender?.name !== "Other" && val.gender?.division?.abbreviation !== "FSC"
				this.setEndType(val)
				break
		}
		if (this.part.shape?.endCount === 1 && key === "shape")
			this.getEndTypes(this.part.productType.id, this.part.shape.id, 1, 0, 0)
	}

	setEnd(endNumber, allEndsSame) {
		this.endGenders = []
		this.subGenders = []
		this.endTypes = []
		this.endSizes = []
		this.materials = []
		this.threadIdentification = []

		this.selectedEndNumber = endNumber
		this.part[`end${endNumber}`] = {}

		if (allEndsSame) this.selectedEndNumber = 0
		this.getEndGenders(this.part.productType.id, this.part.shape.id, this.selectedEndNumber)
	}

	setSubGender(subGender) {
		this.getEndTypes(
			this.part.productType.id,
			this.part.shape.id,
			this.selectedEndNumber,
			subGender.genderId,
			subGender.id
		)
	}

	setEndType(endType) {
		let endNumber = this.selectedEndNumber === 0 ? 1 : this.selectedEndNumber
		let subGenderID = this.part[`end${endNumber}`].subGender?.id
		console.log(toJS(this.part))
		let id = endType.genderId ? endType.genderId : 0
		this.getEndTypeSizes(
			this.part.productType.id,
			this.part.shape.id,
			this.selectedEndNumber,
			id,
			subGenderID ? subGenderID : 0,
			endType.id
		)
	}

	allEndsFilledOut() {
		let filled = true
		Array(this.part.shape?.endCount)
			.fill()
			.forEach((d, i) => {
				if (!this.part[`end${i + 1}`]?.endSize) filled = false
			})
		return filled
	}

	searchForPart(str) {
		this.callAPI(API.searchPart, encodeURIComponent(str)).then((res) => (this.searchResults = res.results))
	}

	getEndParameters(includeSize = true, includeAll = true) {
		let params = ""
		if (this.part.end1?.endType && (includeAll || this.selectedEndNumber > 1)) {
			params += `?end1=1-${this.part.end1.endType.id}`
		}
		if (this.part.end1?.endType && this.part.end1?.endSize && (includeSize || this.selectedEndNumber > 1)) {
			params += `-${this.part.end1.endSize.id}`
		}
		console.log(`end type 2: ${includeAll}`)
		if (this.part.end2?.endType && (includeAll || this.selectedEndNumber > 2)) {
			params += `${params ===""?"?":"&"}end2=2-${this.part.end2.endType.id}`
		}
		if (this.part.end2?.endSize && ((includeAll && includeSize) || this.selectedEndNumber > 2)) {
			params += `-${this.part.end2.endSize.id}`
		}
		if (this.part.end3?.endType && (includeAll || this.selectedEndNumber > 3)) {
			params += `&end3=3-${this.part.end3.endType.id}`
		}
		if (this.part.end3?.endSize && (includeSize || this.selectedEndNumber > 3)) {
			params += `-${this.part.end3.endSize.id}`
		}
		if (this.part.end4?.endType && (includeAll || this.selectedEndNumber > 4)) {
			params += `&end4=4-${this.part.end4.endType.id}`
		}
		if (this.part.end4?.endSize && (includeSize || this.selectedEndNumber > 4)) {
			params += `-${this.part.end4.endSize.id}`
		}
		return params
	}

	getParametersForEnd(endNumber) {
		let params = ""
		if (endNumber === 1) {
			if (this.part.end1?.endType) {
				params += `0-${this.part.end1.endType.id}`
			}
			if (this.part.end1?.endSize) {
				params += `-${this.part.end1.endSize.id}`
			}
		}
		if (endNumber === 2) {
			if (this.part.end2?.endType) {
				params += `1-${this.part.end2.endType.id}`
			}
			if (this.part.end2?.endSize) {
				params += `-${this.part.end2.endSize.id}`
			}
		}
		if (endNumber === 3) {
			if (this.part.end3?.endType) {
				params += `2-${this.part.end3.endType.id}`
			}
			if (this.part.end3?.endSize) {
				params += `-${this.part.end3.endSize.id}`
			}
		}
		if (endNumber === 4) {
			if (this.part.end4?.endType) {
				params += `3-${this.part.end4.endType.id}`
			}
			if (this.part.end4?.endSize) {
				params += `-${this.part.end4.endSize.id}`
			}
		}
		return params
	}
}

export const partStore = new PartStore()
