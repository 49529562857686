import React, { useState } from "react"
import { Image, Pressable, StyleSheet, View, FlatList, Platform, Dimensions } from "react-native"
import Colors from "../../utils/Colors"
import { Surface, Text } from "react-native-paper"
import { Button, Popup } from "../../components/Index"
import { toJS } from "mobx"
import { partStore } from "../../utils/PartStore"

const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const Tool2 = ({ navigation }) => {
	const [selectedItem, setSelectedItem] = useState()
	const [showConfirmNoMatch, setShowConfirmNoMatch] = useState(false)

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
				<Pressable onPress={() => navigation.navigate("TabStack")} style={{ marginRight: 10 }}>
					<Image source={require("../../assets/icn_close.png")} />
				</Pressable>
			),
		})
	}, [navigation])

	return (
		<View style={styles.mainContainer}>
			<View>
				<Text style={styles.step}>Step 2 of 3 </Text>
				<Text style={styles.title}>Determine Thread Pitch</Text>
				<Text style={styles.subtitle}>Thread pitch stamped on gauge.</Text>
			</View>
			<Image
				style={{ width: "100%", height: 150 }}
				resizeMode="contain"
				source={require("../../assets/determine_thread_pitch.png")}
			/>
			<Surface style={styles.listContainer}>
				<FlatList
					data={[...partStore.threadIdentification, "No Match"]}
					keyExtractor={(item) => item.pitch}
					renderItem={({ item }) => (
						<Pressable onPress={() => setSelectedItem(item)} style={styles.row}>
							<Text style={styles.itemTitle}>{item.pitch ? item.pitch : item}</Text>

							{selectedItem?.pitch === item.pitch && <Image source={require("../../assets/icn_selected.png")} />}
						</Pressable>
					)}
				/>
			</Surface>
			<Button
				style={{ marginBottom: 20 }}
				disabled={!selectedItem}
				title="Next"
				theme="yellow"
				onPress={() => {
					if (selectedItem === "No Match") {
						setShowConfirmNoMatch(true)
					} else navigation.navigate("Tool3", { selectedItem })
				}}
			/>
			{showConfirmNoMatch && (
				<Popup
					title="No Match"
					body="Select Ok to choose a new end."
					closeAction={() => navigation.navigate("TabStack")}
					cancelAction={() => setShowConfirmNoMatch(false)}
					closeButtonTitle="Ok"
					cancelButtonTitle="Cancel"
				/>
			)}
		</View>
	)
}

export default Tool2

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		padding: 20,
		width: maxWidth,
		alignSelf: "center",
	},
	title: {
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: 24,
	},
	subtitle: {
		fontSize: 16,
		lineHeight: 24,
		marginBottom: 20,
	},
	body: {
		fontSize: 18,
		lineHeight: 24,
		marginVertical: 20,
	},
	step: {
		fontSize: 18,
		fontWeight: "600",
		marginBottom: 7,
	},
	row: {
		borderBottomColor: Colors.grey,
		borderBottomWidth: 1,
		justifyContent: "space-between",
		alignItems: "center",
		marginLeft: 20,
		paddingVertical: 17,
		flexDirection: "row",
		paddingRight: 15,
	},
	itemTitle: {
		fontSize: 18,
		fontWeight: "600",
	},
	listContainer: {
		flex: 1,
		backgroundColor: Colors.white,
		marginBottom: 20,
		marginTop: 20,
	},
})
