import React, { useState, useEffect } from "react"
import { StyleSheet, Text, View, ScrollView, Pressable, Image, Platform, Dimensions, Linking } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { TextInput, Switch } from "react-native-paper"
import { Button, Popup } from "../components/Index"
import { authStore } from "../utils/AuthStore"
import { observer } from "mobx-react-lite"
import Colors from "../utils/Colors"
import { API } from "../utils/API"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { navStore } from "../utils/NavStore"

const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const Settings = ({ navigation }) => {
	navStore.navigation = navigation
	//const [email, setEmail] = useState(authStore.accountInfo.email)
	const [password, setPassword] = useState()
	const [confirmPassword, setConfirmPassword] = useState()
	const [disabled, setDisabled] = useState(false)
	const [changesSaved, setChangesSaved] = useState(false)
	const [showConfirmDelete, setShowConfirmDelete] = useState(false)

	const [faceID, setFaceID] = useState(false)

	const [allowEmailUpdates, setEmailUpdates] = useState(authStore.accountInfo.allowEmailUpdates)
	const [allowAppNotifications, setNotifications] = useState(authStore.accountInfo.allowAppNotifications)

	const [error, setError] = useState(null)

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
				<Button buttonType="clear" onPress={() => submit()} title="Save" style={{ paddingBottom: 10 }} />
			),
		})
	}, [navigation,  allowEmailUpdates, allowAppNotifications, faceID, confirmPassword])

	// useEffect(() => {
	// 	setDisabled(!email || !password)
	// }, [email, password])

	useEffect(() => {
		AsyncStorage.getItem("faceID").then((faceIDStr) => {
			let faceIDObj = JSON.parse(faceIDStr)
			if (faceIDObj.faceID) setFaceID(true)
		})
	}, [])

	const deleteAccount = () => {
		setShowConfirmDelete(false)
		API.deleteAccount({ accountId: authStore.accountInfo.id }).then((res) => {
			
			AsyncStorage.removeItem("tokenObject")
			AsyncStorage.removeItem("faceID")
			
			authStore.logout()
			navigation.popToTop()
			navigation.replace("Account", { accountDeleted: true })
		})
	}

	const submit = () => {
		setChangesSaved(false)
		let data = {  allowEmailUpdates, allowAppNotifications, id: authStore.accountInfo.id }
		// let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		// if (!re.test(email)) {
		// 	setError({ title: "Sorry,", body: "The email address you entered is not a valid email address" })
		// 	return;
		// }
		
		//setConfirmPassword(confirmPassword)
		// if (password && password !== confirmPassword) {
		// 	setError({ title: "Sorry,", body: "The passwords you entered do not match." })
		// 	return;
		// } else if (password && password === confirmPassword) {
		// 	data.password = password
		// }

		API.updateAccount(data).then((res) => {
			if (res.ok) {
				setChangesSaved(true)
				authStore.accountInfo = res.data.model
			}else{
				setError({ title: "Sorry,", body:"There was a issue updating your account settings" })
				//setError({ title: "Sorry,", body: res.data?.errorMessage })
			}
		})

		AsyncStorage.setItem("faceID", JSON.stringify({ faceID }))
	}
	const onConfirmPasswordChange = (text) => {
		console.log(text)
		setConfirmPassword(text)
	};
	return (
		<View style={{ flex: 1, width: maxWidth, alignSelf: "center" }}>
			<ScrollView style={styles.mainContainer}>
				{changesSaved && (
					<View style={styles.alertContainer}>
						<Image style={styles.logo} source={require("../assets/icn_check.png")} />
						<Text style={styles.alertText}>Your changes have been saved</Text>
					</View>
				)}
				{/* <TextInput
					label="Email"
					value={email}
					onChangeText={(text) => setEmail(text.toLowerCase().trim())}
					textContentType="emailAddress"
					autoCompleteType="email"
					autoCapitalize="none"
					style={styles.textField}
				/>
				<TextInput
					label="New Password"
					//value={password}
					onChangeText={(text) => { {setPassword(text); }}}
				    secureTextEntry
					textContentType="password"
					autoCompleteType="password"
					clearTextOnFocus
					style={styles.textField}
				/>
				<TextInput
					label="Confirm Password"
					value={confirmPassword}
					onChangeText={(text) => { setConfirmPassword(text);}}
					secureTextEntry
					textContentType="password"
					autoCompleteType="password"
					clearTextOnFocus
					style={[styles.textField]}
				/> */}
				{Platform.OS !== "web" && (
					<View style={[styles.preferencesRow, { marginBottom: 30 }]}>
					<Text>{Platform.OS === "iOs"?"Use Face ID":"Use Face Unlock or Fingerprint"}</Text>
					<Switch value={faceID} onValueChange={() => setFaceID(!faceID)} />
				</View>
				)}
				

				<View style={{ flex: 1 }}>
					<Text style={styles.preferencesText}>Would you like to receive new product updates from Parker?</Text>
					<View style={styles.preferencesRow}>
						<Text>Email Updates</Text>
						<Switch value={allowEmailUpdates} onValueChange={() => setEmailUpdates(!allowEmailUpdates)} />
					</View>
					<View style={styles.preferencesRow}>
						<Text>App Notifications</Text>
						<Switch value={allowAppNotifications} onValueChange={() => setNotifications(!allowAppNotifications)} />
					</View>
					<Pressable onPress={() => Linking.openURL(
						"https://parker.com/us/en/sign-in-help.html"
					)}>
						<Text style={styles.delete}>Manage Account</Text>
					</Pressable>
					<Pressable onPress={() => setShowConfirmDelete(true)}>
						<Text style={styles.delete}>Delete Account</Text>
					</Pressable>
				</View>
				{error && <Popup title={error.title} body={error.body} closeAction={() => {setError(null);setError(null)} } />}
			</ScrollView>
			{showConfirmDelete && (
				<Popup
					title="Delete Account?"
					body="All of your account and product configuration information will be removed and no longer accessible."
					closeAction={() => deleteAccount()}
					cancelAction={() => setShowConfirmDelete(false)}
					closeButtonTitle="Yes, Delete Account"
					cancelButtonTitle="Cancel"
				/>
			)}
		</View>
	)
}

export default observer(Settings)

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		paddingHorizontal: 30,
		backgroundColor: Colors.backgroundGrey,
	},
	alertContainer: {
		backgroundColor: "#E6EEDD",
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#92CB51",
		borderWidth: 1,
		
	},
	alertText: {
		color: "#729E3E",
		fontWeight: "bold",
		marginLeft: 10,
	},
	textField: {
		backgroundColor: Colors.backgroundGrey,
		fontWeight: "bold",
		marginBottom: 20,
	},
	preferencesText: {
		fontWeight: "bold",
	},
	preferencesRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottomColor: Colors.mediumGrey,
		borderBottomWidth: 1,
		paddingVertical: 10,
	},
	delete: {
		color: "#1D59F5",
		marginTop: 30,
		fontWeight: "bold",
	},
})
