import React from 'react';
import { BottomTabBar } from '@react-navigation/bottom-tabs';

const CustomTabBar = ({ state, descriptors, navigation }) => {
  const currentRoute = state.routes[state.index];
  const hideTabBar = false;// currentRoute?.params?.hideTabBar; // Check if the current route should hide the tab bar

  if (hideTabBar) {
    return null; // Hide the tab bar
  }

  return null;
};

export default CustomTabBar;
