import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform, Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 950) : Dimensions.get("window").width

const WebHeader = ({ navigation,previous, route }) => {
  //const navigation = useNavigation();
  console.log('web header')
  const handleGoBack = () => {
    navigation.goBack();
  };

  // Check if there is a previous route in the navigation stack
  const showBackButton=true;
  //const showBackButton = navigation?.canGoBack();
  console.log(route?.params)
  return (
    <View style={styles.header}>
      <View style={styles.headerCenter}>
        {showBackButton && (
        <TouchableOpacity onPress={handleGoBack} style={styles.backButton}>
          <Text style={styles.backButtonText}>&lt; Back</Text>
        </TouchableOpacity>
      )}
      <Text style={styles.headerTitle}>H {previous} {route?.params?.title}</Text>
        
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    width: '100%',
   
    
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    
  },
  headerCenter: {
    width:'100%',
    maxWidth:maxWidth,height:60,
    backgroundColor: 'lightblue',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    marginLeft: 100,
  },
  backButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
});

export default WebHeader;
