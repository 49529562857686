import React from "react"
import { StyleSheet } from "react-native"
import { useWindowDimensions } from "react-native"
import { WebView } from "react-native-webview"
import { navStore } from "../utils/NavStore"
const Privacy = ({ navigation }) => {
	navStore.navigation = navigation
	const { width } = useWindowDimensions()
	return (
		<WebView
			style={styles.mainContainer}
			source={{
				uri: "https://www.parker.com/us/en/privacy-policies.html",
			}}
		/>
	)
}

export default Privacy

const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
	},
})
//"https://www.parker.com/us/en/privacy-policies.html"