import React from "react"
import { StyleSheet, Text, Pressable } from "react-native"
import Colors from "../utils/Colors"

const Button = (props) => {
	let theme = props.theme ? props.theme : "black"
	let buttonStyle = theme === "black" ? styles.button : styles.yellowButton
	let textStyle = styles.buttonText
	switch (props.buttonType) {
		case "outline":
			buttonStyle = styles.outlineButton
			textStyle = styles.outlineButtonText
			break
		case "clear":
			buttonStyle = styles.clearButton
			textStyle = theme === "black" ? styles.clearButtonText : styles.clearButtonTextWhite
			break
	}

	return (
		<Pressable
			style={[buttonStyle, props.disabled && styles.disabled, props.style]}
			onPress={() => !props.disabled && props.onPress()}
		>
			<Text style={textStyle} numberOfLines={1}>
				{props.title}
			</Text>
		</Pressable>
	)
}

export default Button

const styles = StyleSheet.create({
	button: {
		backgroundColor: Colors.black,
		padding: 14,
		paddingHorizontal: 30,
	},
	yellowButton: {
		backgroundColor: Colors.yellow,
		padding: 14,
		paddingHorizontal: 30,
	},
	outlineButton: {
		backgroundColor: Colors.white,
		padding: 13,
		paddingHorizontal: 30,
		borderColor: Colors.black,
		borderWidth: 4,
	},
	clearButton: {
		backgroundColor: Colors.transparent,
		padding: 13,
		alignItems: "center",
	},
	buttonText: {
		color: Colors.white,
		fontSize: 17,
		fontWeight: "bold",
		textAlign: "center",
	},
	outlineButtonText: {
		color: Colors.black,
		fontSize: 17,
		fontWeight: "bold",
		textAlign: "center",
	},
	clearButtonText: {
		color: Colors.black,
		fontSize: 17,
		fontWeight: "bold",
		textAlign: "center",
	},
	clearButtonTextWhite: {
		color: Colors.white,
		fontSize: 17,
		fontWeight: "bold",
		textAlign: "center",
	},
	disabled: {
		opacity: 0.7,
	},
})
