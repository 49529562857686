import React, { useEffect, useState } from "react"
import { StyleSheet, Text, View, FlatList, Image, Pressable, Platform, Dimensions, ScrollView } from "react-native"
import { Surface } from "react-native-paper"
import { API } from "../utils/API"
import Colors from "../utils/Colors"
import { navStore } from "../utils/NavStore"
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const SelectCompetitor = ({ navigation, route }) => {
	navStore.navigation = navigation
	const [competitors, setCompetitors] = useState([])
	const [selectedManufacturer] = useState(route.params.selectedManufacturer)

	useEffect(() => {
		API.getCompetitors().then((res) => {
			let data = res.data.collection.sort((a, b) => (a.name > b.name ? 1 : -1))
			data.unshift({ id: 0, name: "All" })
			setCompetitors(data)
		})
	}, [])

	return (
		// <ScrollView contentContainerStyle={styles.mainContainer}>
		<View style={styles.mainContainer}>

			<ScrollView contentContainerStyle={styles.scrollViewContainer}>
				<View style={styles.listContainer}>
					<Surface style={styles.listContainer}>
						<FlatList
							contentContainerStyle={styles.listContainer}
							data={competitors}
							keyExtractor={(item) => item.id}
							renderItem={({ item }) => {
								return (
									<Pressable
										onPress={() => {
											route.params.onManufacturerSelected(item)
											navigation.goBack()
										}}
										style={styles.row}
									>
										<Text style={styles.itemTitle}>{item.name}</Text>
										{selectedManufacturer.name === item.name && (
											<Image source={require("../assets/icn_option_selected.png")} />
										)}
									</Pressable>
								)
							}}
						/>
					</Surface>
				</View>
			</ScrollView>

		</View>
		// </ScrollView>
	)
}

export default SelectCompetitor

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		flex: 1,
		padding: 20,
		width: maxWidth,
		alignSelf: "center",
	},
	scrollViewContainer: {
		flexGrow: 1,
	},
	listContainer: {
		flexGrow: 1,
		backgroundColor: Colors.white,
	},
	itemTitle: {
		fontSize: 18,
		fontWeight: "600",
	},
	row: {
		flexDirection: "row",
		borderBottomColor: Colors.grey,
		borderBottomWidth: 1,
		justifyContent: "space-between",
		alignItems: "center",
		marginLeft: 20,
		paddingVertical: 17,
		paddingRight: 10,
	},
})
