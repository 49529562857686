import apisauce from "apisauce"
import {API_URL, WEB_URL,GATEWAY_KEY,API_BASE_URL,IMGSRV_URL} from '../utils/Configuration';
import { refreshTokens } from '@okta/okta-react-native';
import { authStore } from "../utils/AuthStore"
//const baseURL = `http://127.0.0.1:5000/`
const baseURL = API_URL
const apiURL = baseURL // + "api/"
console.log(apiURL)
const RestAPI = () => {
	const api = apisauce.create({
		//baseURL: `https://cors-anywhere.herokuapp.com/${apiURL}`,
		baseURL: apiURL,
		headers: {
			"Cache-Control": "no-cache",
			"Content-Type": "application/json",
			"Ocp-Apim-Subscription-Key":GATEWAY_KEY
		},
		timeout: 30000,
	})
	// Create a response transformer to handle token expiration
	const tokenRefreshResponseTransformer = async (response) => {
		if (response.status === 401) {
		// Token expired or invalid; refresh it
		const newToken = await refreshTokens();
		if (newToken) {
			setToken(newToken.access_token)
			authStore.signInSuccess(-1, '', newToken)
			// Update the headers with the new token
			//api.setHeader('Authorization', `Bearer ${newToken}`);
			// Reattempt the original request
			return api.axiosInstance(response.config);
		}
		}
		return response;
	};
  	// Add the response transformer to the `apisauce` instance
	api.addResponseTransform(tokenRefreshResponseTransformer);	
	
	const setToken = (token) => {
		console.log(`Bearer ${token}`)
		api.setHeader("Authorization", `Bearer ${token}`)
	}

	const login = (data) => api.post(`api/auth/token`, data)
	const forgotPassword = (data) => api.post(`api/account/forgotPassword`, data)
	const createAccount = (data) => api.post(`api/account`, data)
	const updateAccount = (data) => api.put(`api/account`, data)
	const getAccount = () => api.get(`api/account`)
	const deleteAccount = (data) => api.delete(`api/account`, {}, { data })

	const createFavoritePart = (id, data) => api.post(`api/account/${id}/favorite/part`, data)
	const updateFavoritePart = (id, data) => api.put(`api/account/${id}/favorite/part`, data)
	const deleteFavoritePart = (id, data) => api.delete(`api/account/${id}/favorite/part`, {}, { data })
	const getFavoriteParts = (id) => api.get(`api/account/${id}/favorite/parts`)

	const createFavoriteDistributor = (id, data) => api.post(`api/account/${id}/favorite/distributor`, data)
	const updateFavoriteDistributor = (id, data) => api.put(`api/account/${id}/favorite/distributor`, data)
	const deleteFavoriteDistributor = (id, data) => api.delete(`api/account/${id}/favorite/distributor`, {}, { data })
	const getFavoriteDistributors = (id) => api.get(`api/account/${id}/favorite/distributors`)

	const refreshToken = (data) => api.post(`api/auth/refreshToken`, data)
	const getProductTypes = () => api.get(`api/fitting/productTypes`)
	const getShapes = (id) => api.get(`api/fitting/productType/${id}/shapes`)
	const getEndGenders = (data) =>
		api.get(
			`api/fitting/productType/${data.productId}/shape/${data.shapeId}/end/${data.endNumber}/genders${data.params}`
		)
	const getEndTypes = (data) =>
		api.get(
			`api/fitting/productType/${data.productId}/shape/${data.shapeId}/end/${data.endNumber}/gender/${data.genderId}/subGender/${data.subGenderId}/endTypes${data.params}`
		)
	const getEndTypeSizes = (data) =>
		api.get(
			`api/fitting/productType/${data.productId}/shape/${data.shapeId}/end/${data.endNumber}/gender/${data.genderId}/subGender/${data.subGenderId}/endTypeSizes${data.params}`
		)
	const getThreadIdentification = (data) =>
		api.get(
			`productType/${data.productId}/shape/${data.shapeId}/end/${data.endNumber}/gender/${data.genderId}/subGender/${data.subGenderId}/threadIdentification${data.params}`
		)
	const getMaterials = (data) =>
		api.get(`api/fitting/productType/${data.productId}/shape/${data.shapeId}/materials${data.params}`)
	const getProducts = (data) =>
		api.get(
			`api/fitting/productType/${data.productId}/shape/${data.shapeId}/material/${data.materialId}/products${data.params}`
		)
	const getSpecifications = (data) => api.post(`api/fitting/productSpecifications`, data)
	const searchPart = (endpoint, str, competitorId) => api.get(`api/search/${endpoint}?part=${str}${competitorId}`)
	const loadPartById = (id, isTFD) => api.get(`api/search/part?id=${id}&isTFD=${isTFD}`)
	const getCompetitors = () => api.get("/api/dashboard/competitors")
	const getImageURL = (img) => `${baseURL}static/img/config/${img}`

	return {
		setToken,
		login,
		forgotPassword,
		createAccount,
		updateAccount,
		getAccount,
		deleteAccount,
		createFavoritePart,
		updateFavoritePart,
		deleteFavoritePart,
		getFavoriteParts,
		createFavoriteDistributor,
		updateFavoriteDistributor,
		deleteFavoriteDistributor,
		getFavoriteDistributors,
		refreshToken,
		getProductTypes,
		getShapes,
		getEndGenders,
		getEndTypes,
		getEndTypeSizes,
		getThreadIdentification,
		getMaterials,
		getProducts,
		getSpecifications,
		searchPart,
		loadPartById,
		getCompetitors,
		getImageURL,
	}
}

export const API = RestAPI()

const parkerURL = `http://corpapps.parker.com/`
const GoogleGeoAPI = () =>{
	const api = apisauce.create({
		baseURL: IMGSRV_URL,
		headers: {
			"Cache-Control": "no-cache",
			"Content-Type": "application/json",
			
		},
		timeout: 30000,
	})
	const naviMonitor = (response) => console.log("========== API Response ==========", response)
	api.addMonitor(naviMonitor)
	const getGeoCodePoint = (zip) =>
		
		api.get(
			`api/geocode/${zip}`
			
		)

	return {
		getGeoCodePoint,
	}
}
export const GoogleAPI = GoogleGeoAPI()
const ParkerRestAPI = () => {
	const api = apisauce.create({
		baseURL: API_BASE_URL,
		headers: {
			"Cache-Control": "no-cache",
			"Content-Type": "application/json",
			"Ocp-Apim-Subscription-Key":GATEWAY_KEY
		},
		timeout: 30000,
	})
	const naviMonitor = (response) => console.log("========== API Response ==========", response)
	api.addMonitor(naviMonitor)

	const searchForDistributor = (point, zip) =>
		
		api.get(
			`wheretobuy/getDistributor?countryCode=USA&countryId=687PDC&point=${point}&searchType=D&postalCode=${zip}&productId=1428283&distance=50&uom=m`
			//`/wheretobuy/getDistributor?countryCode=USA&countryId=687PDC&point=${point}&searchType=D&postalCode=${zip}&productId=3501263&distance=60&uom=m`
		)

	return {
		searchForDistributor,
	}
}

export const ParkerAPI = ParkerRestAPI()

const CADRestAPI = () => {
	const api = apisauce.create({
		baseURL:`${API_BASE_URL}/cad/cad/list/`,// "https://api.parker.com/prod/cad/cad/list/",
		headers: {
			"Cache-Control": "no-cache",
			//"Ocp-Apim-Subscription-Key": GATEWAY_KEY,//"a3556221bf5f453cad470ce8d57f552b",
		},
		timeout: 30000,
	})
	const naviMonitor = (response) => console.log("========== API Response ==========", response)
	api.addMonitor(naviMonitor)
	
	const setAPIKey = (apiKey) => api.setHeader("Ocp-Apim-Subscription-Key", apiKey)
	//const setAPIKey = (apiKey) => api.setHeader("Ocp-Apim-Subscription-Key", "22368906ea8b40aa8c36c74957ad7a30")

	const getCadLinks = (partNumber) => api.get(`byPartNumber/${partNumber}`)
	return {
		setAPIKey,
		getCadLinks,
	}
}

export const CADAPI = CADRestAPI()
