import React, { useEffect, useState } from "react"
import { StyleSheet, Text, View, FlatList, Pressable, Platform,ScrollView,Dimensions } from "react-native"
import { API } from "../utils/API"
import { authStore } from "../utils/AuthStore"
import { partStore } from "../utils/PartStore"
import { Surface } from "react-native-paper"
import Colors from "../utils/Colors"
import { navStore } from "../utils/NavStore"
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width
const SavedProducts = ({ navigation }) => {
	navStore.navigation = navigation
	const [favoriteParts, setFavoriteParts] = useState([])

	useEffect(() => {
		const unsubscribe = navigation.addListener("focus", () => {
			API.getFavoriteParts(authStore.accountInfo.id).then((res) => {
				if (res.ok) setFavoriteParts(res.data.collection)
			})
		})
		return unsubscribe
	}, [navigation])

	return (
		<ScrollView contentContainerStyle={styles.mainContainer}>
		<View >
			<Surface>
				<FlatList
					contentContainerStyle={styles.listContainer}
					data={favoriteParts}
					keyExtractor={(item) => item.id}
					renderItem={({ item }) => (
						<Pressable
							onPress={() => {
								//navigation.navigate("FinderStack")
								//navigation.popToTop()
								setTimeout(() => {
									// partStore.clearPart()
									// partStore.products = [item.product]
									partStore.setSavedPart(item.product,item.configuration)
									navigation.navigate("FittingDetails", { item })
								}, 500)
							}}
							style={styles.row}
						>
							<Text style={styles.itemTitle}>{item.name}</Text>
							{/* <Text style={styles.itemTitle}>{item.nickName ? item.nickName : item.name}</Text> */}
						</Pressable>
					)}
				/>
			</Surface>
		</View>
		</ScrollView>
	)
}

export default SavedProducts

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		margin: 20,
		width: maxWidth,
		alignSelf: "center",
	},
	listContainer: {
		backgroundColor: Colors.white,
	},
	row: {
		borderBottomColor: Colors.grey,
		borderBottomWidth: 1,
		justifyContent: "center",
		marginLeft: 20,
		paddingVertical: 12,
	},
	itemTitle: {
		fontSize: 18,
		fontWeight: "600",
	},
})
