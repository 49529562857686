import React from "react"
import { StyleSheet, Text, View, Pressable, Linking, Dimensions, Platform } from "react-native"
import Icon from "react-native-vector-icons/Feather"
import Icon2 from "react-native-vector-icons/Foundation"
import SimpleIcon from "react-native-vector-icons/SimpleLineIcons"
import Colors from "../utils/Colors"
import { navStore } from "../utils/NavStore"
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const Contact = ({ navigation }) => {
	navStore.navigation = navigation
	return (
		<View style={styles.mainContainer}>
			<Text style={styles.title}>Tube Fitting Division</Text>

			<View style={styles.actionRow}>
				<Pressable
					style={styles.actionButton}
					onPress={() =>
						Linking.openURL(`https://parker.com/tfd`)
					}
				>
					<Icon2 name="web" size={18} color={Colors.yellow} />
					<Text style={styles.actionText}>DIVISION WEBSITE</Text>
				</Pressable>
				<Pressable 
					style={styles.actionButtonCenter}
					onPress={() =>
						Linking.openURL(`mailto:tfd.support@support.parker.com?subject=${encodeURIComponent("Inquiry from Parker Fitting Finder App")}`)
					}
				>
					<Icon name="mail" size={18} color={Colors.yellow} />
					<Text style={styles.actionText}>EMAIL</Text>
				</Pressable>
				<View  />
				<Pressable style={styles.actionButton} onPress={() => {if(Platform.OS==="ios" || Platform.OS === "android") Linking.openURL("tel:6142797070")}}>
					<SimpleIcon name="phone" size={18} color={Colors.yellow} />
					<Text style={styles.actionText}>{Platform.OS==="web"?"(614) 279-7070":"CALL"}</Text>
				</Pressable>
			</View>

			<Text style={styles.title}>Corporate</Text>

			<View style={styles.actionRow}>
			<Pressable
					style={styles.actionButton}
					onPress={() =>
						Linking.openURL(`https://parker.com`)
					}
				>
					<Icon2 name="web" size={18} color={Colors.yellow} />
					<Text style={styles.actionText}>Parker.com</Text>
				</Pressable>
			</View>
		</View>
	)
}

export default Contact

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		padding: 30,
		backgroundColor: Colors.backgroundGrey,
		width: maxWidth,
		alignSelf: "center",
	},
	actionRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: 20,
		marginBottom: 60,
	},
	actionButton: {
		backgroundColor: Colors.darkGrey,
		flex: 1,
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 5,
	},actionButtonCenter: {
		backgroundColor: Colors.darkGrey,
		flex: 1,
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 5,
		marginLeft:5,marginRight:5
	},
	actionText: {
		color: Colors.white,
		marginLeft: 10,
		fontWeight: "500",
	},
	title: {
		fontWeight: "bold",
		fontSize: 26,
	},
})
