import { observable, action, computed, makeAutoObservable } from "mobx"
import AsyncStorage from "@react-native-async-storage/async-storage"
import jwt_decode from "jwt-decode"
import { API } from "./API"
import * as SecureStore from "expo-secure-store"
import { analyticsStore } from "../utils/AnalyticsStore"
import { Platform } from "react-native"

class AuthStore {
	constructor() {
		makeAutoObservable(this)
	}

	loading = false
	error = undefined
	authenticated = false
	showHomeStack = false
	accountInfo = {}
	isCreateAccount = false;
	allowEmailUpdates = null
	allowAppNotifications = null
	faceId = null
	noAccount = false
	navLink = null
	async save(key, value) {
		console.log(`setting key ${key}: ${value}`)
		await SecureStore.setItemAsync(key, value)
	}
	async getSecureKey(key) {
		return await SecureStore.getItemAsync(key)
	}
	async signInSuccess(id, email, token) {
		console.log('signInSuccess')
		API.setToken(token.access_token)
		

		AsyncStorage.setItem("tokenObject", JSON.stringify(token))
		analyticsStore.logEvent("login", {})
		this.authenticated = true
		this.showHomeStack = true

		API.getAccount().then((res) => {
			if (!res.ok) {
				this.noAccount = true;
				this.logout()
			} else {
				this.accountInfo = res.data.model
				this.syncLocalData(res.data.model.id)
			}

		})
		//this.createAccount({ email:'', password:'', allowEmailUpdates:true, allowAppNotifications:true })
		//this.syncLocalData(id)
		console.log('authstore_signInSuccess')
	}
	async signInSuccessCreateAccount(id, email, token, data) {
		console.log('signInSuccessCreateAccount')

		API.setToken(token.access_token)
		let values = { allowAppNotifications: true, allowEmailUpdates: true }
		//let values = { allowAppNotifications: this.allowAppNotifications, allowEmailUpdates: this.allowEmailUpdates }
		return API.createAccount(values).then((res) => {
			if (res.ok) {
				//console.log('account created')
				//AsyncStorage.setItem("faceID", JSON.stringify({ faceID: true }))
				API.getAccount().then(async (res) => {
					console.log('loaded account')
					this.accountInfo = res.data.model
					var faceID = this.faceId;
					await AsyncStorage.setItem("faceID", JSON.stringify({ faceID }))
					await AsyncStorage.setItem("tokenObject", JSON.stringify(token))
					analyticsStore.logEvent("login", {})
					this.authenticated = true
					this.showHomeStack = true
					this.syncLocalData(res.data.model.id)
					// API.getAccount().then((res) => {
					// 	this.accountInfo = res.data.model
					// })
				})
				//this.authenticated = true
				//this.showHomeStack = true
				//if there are any save distrubutors send them to the server


			} else {
				if (res.data?.errorMessage?.includes('is already taken'))
					API.getAccount().then(async (res) => {
						console.log('loaded account')
						this.accountInfo = res.data.model
						var faceID = this.faceId;
						await AsyncStorage.setItem("faceID", JSON.stringify({ faceID }))
						await AsyncStorage.setItem("tokenObject", JSON.stringify(token))
						analyticsStore.logEvent("login", {})
						this.authenticated = true
						this.showHomeStack = true
						this.syncLocalData(res.data.model.id)
						// API.getAccount().then((res) => {
						// 	this.accountInfo = res.data.model
						// })
					})
				console.log(res.data)
			}
			return res
		})

	}
	login(email, password) {
		this.loading = true
		this.error = undefined

		return API.login({ username: email, password }).then((res) => {
			if (!res.ok) {
				//Alert.alert("Error", res.data.error.message)
				alert('no account')
				this.logout()
				return res
			} else {
				API.setToken(res.data.token)
				const id = jwt_decode(res.data.token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]
				API.getAccount(id).then((res) => {
					if (res.data) {
						//alert('no account')
					}

				}).error((err) => {
					this.noAccount = true;

				})
				this.accountInfo = res.data.model
				AsyncStorage.setItem("tokenObject", JSON.stringify(res.data))
				// Analytics.logEvent("login")
				this.authenticated = true
				this.showHomeStack = true
				this.syncLocalData(id)

			}
			this.loading = false
			return res
		})
	}

	syncLocalData(userId) {
		AsyncStorage.getItem("favorites").then((favoritesData) => {
			if (favoritesData) {
				const data = JSON.parse(favoritesData)
				Promise.all(data.filter(a => a.synced === false).map((item) => {
					API.createFavoriteDistributor(userId, item).then((res) => {

					})
					return { ...item, synced: true };
				}))

			}
		})
		AsyncStorage.getItem("deletedFavorites").then((deletedFavorites) => {
			const data = JSON.parse(deletedFavorites)
			data.forEach((item) => {
				API.deleteFavoriteDistributor(userId, { id: item[0].id })
			})



			AsyncStorage.setItem("deletedFavorites", "")
		})
	}
	refreshToken(token) {
		this.loading = true
		this.error = undefined

		return API.refreshToken({ token }).then((res) => {
			if (res.status !== 200) {
				//this.logout()
			} else {
				AsyncStorage.setItem("tokenObject", JSON.stringify(res.data))
				this.authenticated = true
				this.showHomeStack = true
			}
			this.loading = false
			return res
		})
	}

	setToken(token) {
		API.setToken(token)
		const id = jwt_decode(token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]
		API.getAccount(id).then((res) => {
			this.accountInfo = res.data.model
		})
		this.authenticated = true
	}

	async logout() {
		this.authenticated = false
		this.accountInfo = {}
		if(Platform.OS!=="web"){
			let faceIDObj = JSON.parse(await AsyncStorage.getItem("faceID"))

			if (faceIDObj?.faceID) {
				console.log('true')
				let tokenStore = await AsyncStorage.getItem("tokenObject")
				console.log(`logout:${tokenStore}`)
				await this.save('savedToken', JSON.stringify(tokenStore))
			} else {
				console.log('false')
				AsyncStorage.removeItem("tokenObject")
				this.authenticated = false
			}
	
			
		}
		AsyncStorage.removeItem("tokenObject")

		
	}

	createAccount(data) {
		return API.createAccount(data).then((res) => {
			if (res.ok) {
				//AsyncStorage.setItem("faceID", JSON.stringify({ faceID: true }))
				this.login(data.email, data.password)

				//if there are any save distrubutors send them to the server


			}
			return res
		})
	}
}

export const authStore = new AuthStore()
