import React from "react"
import { Image, Pressable, StyleSheet, Text, View, ScrollView, Platform, Dimensions } from "react-native"
import Colors from "../../utils/Colors"
import { Button } from "../../components/Index"
import { partStore } from "../../utils/PartStore"

const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const ToolIntro = ({ navigation }) => {
	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
				<Pressable onPress={() => navigation.navigate("TabStack")} style={{ marginRight: 10 }}>
					<Image source={require("../../assets/icn_close.png")} />
				</Pressable>
			),
		})
	}, [navigation])

	React.useEffect(() => partStore.getThreadIdentification(), [])

	return (
		<ScrollView style={styles.mainContainer}>
			<Text style={styles.title}>
				Parker MIK-1 kit is a simple and excellent tool for identification of fitting threads.
			</Text>
			<Text style={styles.body}>
				At minimum, the caliper and thread gauges are required to successfully identify threads.
			</Text>
			<Text style={styles.title}>The following thread identification process pertains to male ends only.</Text>
			<Text style={styles.body}>
				If a female end requires thread identification, it is recommended that the mating male, if available, be
				identified using this process.
			</Text>
			<View>
				<Image
					source={require("../../assets/MIK-1_kit.png")}
					style={{ height: 200, alignSelf: "center" }}
					resizeMode="contain"
				/>

				<Pressable
					style={styles.actionButton}
					onPress={() => {
						navigation.navigate("WhereToBuyStack")
					}}
				>
					<Text style={styles.actionText}>PURCHASE</Text>
				</Pressable>
			</View>
			<View style={{ paddingLeft: 10 }}>
				<Text style={styles.listTitle}>MIK-1 kit includes</Text>
				<Text style={styles.listItem}>- Caliper</Text>
				<Text style={styles.listItem}>- Thread gauges</Text>
				<Text style={styles.listItem}>- Thread profiles</Text>
				<Text style={styles.listItem}>- Instruction booklet</Text>
				<Text style={styles.listItem}>- Nose angle indicator</Text>
			</View>
			<Button style={{ marginBottom: 20 }} title="Next" theme="yellow" onPress={() => navigation.navigate("Tool1")} />
		</ScrollView>
	)
}

export default ToolIntro

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		padding: 20,
		marginBottom: 20,
		width: maxWidth,
		alignSelf: "center",
	},
	title: {
		fontSize: 18,
		fontWeight: "bold",
		lineHeight: 24,
	},
	body: {
		fontSize: 18,
		lineHeight: 24,
		marginVertical: 20,
	},
	listTitle: {
		fontSize: 16,
		fontWeight: "bold",
		marginBottom: 7,
	},
	listItem: {
		fontSize: 16,
		marginBottom: 7,
	},
	actionButton: {
		backgroundColor: Colors.darkGrey,
		padding: 10,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 5,
		marginVertical: 20,
	},
	actionText: {
		color: Colors.white,
		marginLeft: 10,
		fontWeight: "500",
	},
})
