import React, { useEffect, useState } from "react"
import {
	StyleSheet,
	TextInput,
	View,
	FlatList,
	Pressable,
	ActivityIndicator,
	ScrollView,
	Image,
	Platform,
	Dimensions, Keyboard
} from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { Surface, Text } from "react-native-paper"
import Icon from "react-native-vector-icons/Ionicons"
import { authStore } from "../utils/AuthStore"
import AsyncStorage from "@react-native-async-storage/async-storage"
import Colors from "../utils/Colors"
import { API, ParkerAPI,GoogleAPI } from "../utils/API"
import { Button, Popup } from "../components/Index"
import { navStore } from "../utils/NavStore"
import icnFavorite from '../assets/icn_more.png'; // Importing the image
const maxWidth = Platform.OS === "web" ? Math.min(Dimensions.get("window").width, 600) : Dimensions.get("window").width

const WhereToBuy = ({ navigation }) => {
	navStore.navigation = navigation
	const [zipCode, setZipCode] = useState("")
	const [data, setData] = useState([])
	const [favorites, setFavorites] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (zipCode.length === 5) {
			Keyboard.dismiss(); // Force the text input to lose focus and hide the keyboard
			setLoading(true)
			var parseString = require("xml2js").parseString
			GoogleAPI.getGeoCodePoint(zipCode).then((response)=>{
				let point = `${response.data?.results[0]?.geometry?.location?.lat},${response.data?.results[0]?.geometry?.location?.lng}`
				ParkerAPI.searchForDistributor(point,zipCode).then((res) => {
					let results = res.data.response.premier;
					if(res.data.response.resultType_s !== "NotFoundDistributor" && res.data.response.standard){
						results = [...results, ...res.data.response.standard];
					}
					let uniqueResults = [];
					let uniqueSet = new Set();

					results.forEach(result => {
						const uniqueKey = `${result.Name}-${result.Address}`; // Adjust according to the actual structure
						if (!uniqueSet.has(uniqueKey)) {
							uniqueSet.add(uniqueKey);
							uniqueResults.push(result);
						}
					});
					setData(uniqueResults)
					setLoading(false)
					// (res.data.response.premier, function (err, result) {
					// 	if (result) {
					// 		const distributorData = result.DistributorsList.Distributor.filter((d) => {
					// 			const favObj = favorites.find((fav) => fav.distributorNumber === d.DistributorNo[0])
					// 			return !favObj
					// 		})
					// 		setData(distributorData)
					// 	}
					// 	setLoading(false)
					// })
				})
			})
			// ParkerAPI.searchForDistributor(zipCode).then((res) => {
			// 	parseString(res.data, function (err, result) {
			// 		if (result) {
			// 			const distributorData = result.DistributorsList.Distributor.filter((d) => {
			// 				const favObj = favorites.find((fav) => fav.distributorNumber === d.DistributorNo[0])
			// 				return !favObj
			// 			})
			// 			setData(distributorData)
			// 		}
			// 		setLoading(false)
			// 	})
			// })
		} else if (zipCode.length === 0) setData([])
	}, [zipCode, favorites])

	useEffect(() => {
		const unsubscribe = navigation.addListener("focus", () => {
			if (authStore.authenticated)
				API.getFavoriteDistributors(authStore.accountInfo.id).then((res) => {
					if (res.ok) {
						setFavorites(res.data.collection)
						AsyncStorage.setItem("favorites", JSON.stringify(res.data.collection))
					}
				})
			else 
				AsyncStorage.getItem("favorites").then((favoritesData) => {
					if (favoritesData) {
						const data = JSON.parse(favoritesData)
						setFavorites(data)
						// if (authStore.authenticated) 
						// 	{API.getFavoriteParts(authStore.accountInfo.id).then((res) => {
						// 	if (res.ok) {
						// 		const favoritePart = res.data.collection.find((d) => d.name === selectedPart?.name)
						// 		if (favoritePart) {
						// 			setSavedVisible(true)
						// 			setSavedPart(favoritePart)
						// 		}
						// 		setFavoriteParts(res.data.collection)
						// 	}
						// })
							// data.forEach((distributor) => {
							// 	let data = {
							// 		distributorNumber: distributor.DistributorNo[0],
							// 		name: distributor.Name[0],
							// 		address: distributor.Address1[0],
							// 		city: distributor.City[0],
							// 		state: distributor.State[0],
							// 		zip: distributor.Zip[0],
							// 		phone: distributor.Phone[0],
							// 		fax: distributor.Fax[0],
							// 		email: distributor.Email[0],
							// 		notes: "",
							// 	}
							// 	API.createFavoriteDistributor(authStore.accountInfo.id, data)
							// })
							// AsyncStorage.setItem("favorites", "")
						//}
					}else{
						
						setFavorites([])
					}
				})
		})
		return unsubscribe
	}, [navigation])
	const handleScroll = () => {
		Keyboard.dismiss(); // Force the text input to lose focus and hide the keyboard
	  };
	return (
		<ScrollView contentContainerStyle={styles.mainContainer} keyboardShouldPersistTaps="handled" onScroll={handleScroll}>
			<View style={styles.searchBar}>
				<Icon name="ios-search-outline" size={28} color={Colors.black} />
				<TextInput
					style={styles.input}
					placeholder="Postal Code"
					onChangeText={setZipCode}
					value={zipCode}
					keyboardType="numeric"
					autoComplete="postal-code"
					textContentType="postalCode"
					clearButtonMode="while-editing"
					clearTextOnFocus
					autoFocus
					maxLength={5}
					returnKeyType="search"
					underlineColorAndroid={Colors.transparent}
				/>
			</View>
			{Platform.OS === "web" &&(
				<Button title="Search" style={{ width: '70%', alignSelf: 'center',marginTop:25, marginBottom:20 }} theme="yellow" onPress={() => performSearch()} />
			)}
			{loading && <ActivityIndicator style={{ marginVertical: 60 }} size="small" color={Colors.black} />}

			<Surface style={styles.results}>
				<FlatList
					contentContainerStyle={styles.listContainer}
					data={[...favorites, ...data]}
					keyExtractor={(item) => item.id}
					renderItem={({ item }) => {
						const isFavorite = favorites.find((d) => d.distributorNumber === item.distributorNumber)

						return (
							<Pressable onPress={() => navigation.navigate("DistributorDetails", { item })} style={styles.resultRow}>
								
								{(item.id || isFavorite) && (
									<Image style={{ marginRight: 10, width:20, height:20 }} source={require("../assets/btn_icn_favorite_on.png")} />
								)}
								<View style={{ flex: 1 }}>
									<Text style={styles.itemTitle}>{item.id || isFavorite ? item.name : item.Name}</Text>
									<Text style={styles.itemSubtitle}>
										{item.id || isFavorite ? `${item.city}, ${item.state}` : `${item.City}, ${item.State}`}
									</Text>
								</View>
								<Image style={{ width:10, height:20 }} source={require("../assets/icn_more.png")} />
							</Pressable>
						)
					}}
				/>
			</Surface>
		</ScrollView>
	)
}

export default WhereToBuy

const styles = StyleSheet.create({
	mainContainer: {
		flexGrow: 1,
		paddingHorizontal: 30,
		paddingVertical: 20,
		backgroundColor: Platform.OS !== "web" ? Colors.backgroundGrey : Colors.transparent,
		width: maxWidth,
		alignSelf: "center",
	},
	searchBar: {
		backgroundColor: Colors.white,
		flexDirection: "row",
		borderRadius: 30,
		padding: 10,
		color: Colors.black,
	},
	input: {
		flex: 1,
		paddingLeft: 15,
	},
	results: {
		marginTop: 25,
		paddingLeft: 10,
	},
	resultRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingVertical: 20,
		paddingRight: 10,
		borderBottomColor: Colors.mediumGrey,
		borderBottomWidth: 1,
	},
	itemTitle: {
		fontWeight: "bold",
		fontSize: 18,
	},
	itemSubtitle: {
		fontSize: 12,
		fontWeight: "500",
		color: Colors.mediumGrey,
	},
})
