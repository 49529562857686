import React from 'react';
import { View, Text, StyleSheet, Linking, TouchableOpacity } from 'react-native';


export default function WebFooter() {
    const currentYear = new Date().getFullYear();
    return (
        <View style={styles.footer}>
            <View style={styles.menuBar}>
                <Text style={styles.leftText}>Copyright &copy; {currentYear} Parker Hannifin Corporation</Text>
                <View style={styles.rightTextContainer}>
                    <TouchableOpacity onPress={() => Linking.openURL(
                        "https://www.parker.com/us/en/safety.html"
                    )}>
                        <Text style={styles.link} >Safety</Text>
                    </TouchableOpacity>
                    <Text> | </Text>
                    <TouchableOpacity onPress={() => Linking.openURL(
                        "https://www.parker.com/us/en/terms-and-conditions.html"
                    )}>
                        <Text style={styles.link} >Terms & Conditions</Text>
                    </TouchableOpacity>
                    <Text> | </Text>
                    <TouchableOpacity onPress={() => Linking.openURL(
                        "https://www.parker.com/us/en/privacy-policies.html"
                    )}>
                        <Text style={styles.link} >Privacy</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    footer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: '#ccc',
        justifyContent: 'center', // Horizontally center its children
        alignItems: 'center',

    },
    menuBar: {
        flexDirection: 'row',
        alignItems: 'center',

        backgroundColor: 'white',
        width: 1000,
        //alignSelf: 'center', // Horizontally center the menuBar within its parent

    },
    leftText: {
        flex: 1,
        textAlign: 'left',
        color: '#7a7a7a'
    },
    rightTextContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    link: {

        textDecorationLine: 'underline',
    },
});